import { Notifications, Person, Edit } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { HeaderSettings } from "../../constants/HeaderMenu";
import AuthContext from "../../contexts/AuthContext";
import { UIContext } from "../../contexts/UIProvider";
import Notification from "../Notification";

import "./Header.css";
import VideoActivityLogs from "../PlayVideo/VideoActivityLogs";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { FirestoreDB } from "../../utils/firebase/firebase";
import { fetchUserNotificationMessages } from "../../api";
import SaasAgrement from "../../pages/Updated_UI/SaasAgreement";
import TermConditionLandingpage from "../../pages/Updated_UI/TermconditionLandingpage";
import PrivacyLandingpage from "../../pages/Updated_UI/PrivacyLandingpage";
import UpdatedAcceptPolicies from "./UpdatedAcceptPolicies";
import UnreadMessageCounts from "../../pages/Messaging/UnreadCount";
import { DataContext } from "../../contexts/DataProvider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Header = ({ onclick }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [notificationCount, setnotificationCount] = useState(0);

  const [notifications, setNotifications] = useState([]);

  const [title, setTitle] = useState("");

  const [open, setOpen] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);

  const {
    user,
    typeOfUser,
    logout,
    setOpenRightPanel,
    setPanelContent,
    setIsNotificationPanel,
  } = useContext(AuthContext);
  const {
    updateSelectedMenu,
    setSelectedMenuKey,
    selectedMenuKey,
    isDrawerOpen,
    setIsDrawerOpen,
    isSmallScreen,
    selectPatient,
  } = useContext(UIContext);
  const { patientData } = useContext(DataContext);
  const [patientsLoaded, setPatientsLoaded] = useState(false);
  const [isUserExists, setIsUserExists] = useState(false);

  const location = useLocation();

  const formatName = (name) => {
    return name
      .split(/\s+/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  React.useEffect(() => {
    if (selectedMenuKey === "Home") {
      const formattedUserName = user?.fullname || user?.displayName || "User";
      const formattedTitle = formatName(formattedUserName);

      setTitle(`Welcome, ${formattedTitle}`);
    } else if (selectedMenuKey === "Appointments") {
      setTitle("Appointments");
    } else if (selectedMenuKey === "Statistics") {
      setTitle("Statistics");
    } else if (selectedMenuKey === "HistoricalData") {
      setTitle("Historical Data");
    }else if (selectedMenuKey === "Settings") {
      setTitle(`Settings`);
    } else if (selectedMenuKey === "Privacy") {
      setTitle("Privacy Statement");
    } else if (selectedMenuKey === "Termcondition") {
      setTitle("Term and Conditions");
    } else if (selectedMenuKey === "Contact") {
      setTitle("Contact Information");
    } else if (selectedMenuKey === "Userguide") {
      setTitle("User Guide");
    } else if (selectedMenuKey === "Messaging") {
      setTitle("Messaging");
    }
    else if (selectedMenuKey === "Messaging") {
      setTitle("Messaging");
    }
    else if (selectedMenuKey === "Messaging") {
      setTitle("Messaging");
    }
  }, [selectedMenuKey, user]);

  useEffect(() => {
    if (user) {
      fetchNotifications();
      setIsUserExists(true);
    }
  }, [user]);

  useEffect(() => {
    patientData?.length > 0 && setPatientsLoaded(true);
  }, [patientData]);

  useEffect(() => {
    if (location.pathname === "/") {
      updateSelectedMenu("Home");
    }
  }, [location]);

  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settingClickHandler = (setting) => {
    handleCloseUserMenu();
    if (setting.key === "profile") {
      navigate("/settings");
      updateSelectedMenu("Settings");
    }
    if (setting.key === "dashboard") {
      navigate("/");
      updateSelectedMenu("Home");
    }
    if (setting.key === "logout") {
      setSelectedMenuKey("Home");
      logout();
      navigate("/main");
    }
  };

  const fetchNotifications = async () => {
    const messages = await fetchUserNotificationMessages(user?.uid);

    const filteredMessages = messages.filter(
      (message) =>
        message.title !== "Patient has sent you a message" &&
        message.title !== "Healthcare Professional has sent you a message"
    );

    const unreadMessages = filteredMessages.filter(
      (message) => !message.readStatus && message.title
    );

    setNotifications(messages);

    setnotificationCount(unreadMessages?.length);
  };

  const handleShowNotifications = () => {
    setPanelContent(
      <Notification
        setnotificationCount={setnotificationCount}
        notifications={notifications}
        fetchNotifications={fetchNotifications}
        setOpenRightPanel={setOpenRightPanel}
      />
    );
    setOpenRightPanel(true);
    setIsNotificationPanel(true);
  };

  const renderUnreadMessageCounts = () => {
    if (typeOfUser === "DOCTOR") {
      if (patientsLoaded && isUserExists) {
        return (
          <UnreadMessageCounts
            unreadCounts={unreadCounts}
            setUnreadCounts={setUnreadCounts}
            user={user}
            typeOfUser={typeOfUser}
            patientData={patientData}
          />
        );
      }
    } else if (typeOfUser === "PATIENT") {
      if (isUserExists) {
        <UnreadMessageCounts
          unreadCounts={unreadCounts}
          setUnreadCounts={setUnreadCounts}
          user={user}
          typeOfUser={typeOfUser}
        />;
      }
    }
    return null;
  };

  return (
    <>
      <UpdatedAcceptPolicies />
      <Toolbar disableGutters className="header">
        {isSmallScreen && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Menu">
              <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              ml: 2,
              flexGrow: 1,
              fontFamily: "Lato",
              color: "white",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box className="flexBox" sx={{ flexGrow: 0 }}>
          <Tooltip title="Edit your profile">
            <IconButton
              component={Link}
              to="/settings"
              onClick={() => {
                updateSelectedMenu("Settings");
              }}
            >
              <Edit sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Notifications">
            <IconButton onClick={handleShowNotifications} sx={{ mr: 2 }}>
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={notificationCount > 0 ? notificationCount : null}
              >
                <Notifications sx={{ color: "white" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar src={user?.photoURL}>
                <Person />
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key={"welcome"} onClick={handleCloseUserMenu} disabled>
              <Typography textAlign="center">
                Welcome,{" "}
                {formatName(user?.fullname || user?.displayName || "User")}
              </Typography>
            </MenuItem>

            {HeaderSettings.map((setting) => (
              <MenuItem
                key={setting.key}
                onClick={() => settingClickHandler(setting)}
              >
                <Typography textAlign="center">{setting.text}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      {renderUnreadMessageCounts()}

      {/* <UnreadMessageCounts
        unreadCounts={unreadCounts}
        setUnreadCounts={setUnreadCounts}
        user={user}
        typeOfUser={typeOfUser}
        patientData={patients}
      /> */}
    </>
  );
};

export default Header;