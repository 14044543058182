import {
  AddAPhoto,
  Email,
  Image,
  Phone,
  AccountCircle,
  LocalHospital,
  Badge,
  ContentCopyRounded,
} from "@mui/icons-material";

import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { AppRegistration } from "@mui/icons-material";

import React, { useContext, useState } from "react";

import { useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { FirebaseStorage, FirestoreDB } from "../../utils/firebase/firebase";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomButton from "../../components/CustomButton";

import "./DoctorEditInformation.css";
import { ref } from "firebase/storage";
import { UploadFileToStorage } from "../../utils/firebase/helper";
import { updateDoctorProfileDetails } from "../../api";
import AuthContext from "../../contexts/AuthContext";
import Progress from "../Progress";

const DoctorEditInformation = ({ userDetails }) => {
  const [doctor, setDoctor] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [profilePicURL, setProfilePicUrl] = useState(null);
  const [copiedText, setCopiedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [npi, setNpi] = useState("");

  const { user, updateGlobalUser } = useContext(AuthContext);

  useEffect(() => {
    setDoctor(user);
  }, []);

  console.log("User===============================================t:", user);
  const copyToClipboard = () => {
    const textToCopy = validateString(doctor?.authorisationCode);

    // Copy the text to the clipboard
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    // Set the copied text in the state and provide feedback
    setCopiedText(textToCopy);

    toast.success("Authorization code copied successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file?.size <= 10048576) {
      // Greater than 10MB
      setProfilePicFile(file);
    }
    let url = "";
    if (file) {
      let blob = new Blob([file], {
        type: file.type,
      });
      const profileStorageRef = ref(
        FirebaseStorage,
        `profiles/${user?.uid}/${file.name}`
      );

      const result = await UploadFileToStorage(profileStorageRef, blob);

      if (result.status) {
        url = result.data;

        url && setDoctor({ ...doctor, photoURL: url });
        setProfilePicUrl(url);
      }
    }
  };

  const showToastMessage = () => {
    toast.success("Profile updated successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const validateString = (str) => {
    return str?.toString().trim() || "-";
  };

  const validateInputs = () => {
    let isValid = true;

    if (!doctor?.fullname.trim()) {
      setName("Full name is required.");
      isValid = false;
    } else if (!/^[A-Za-z ]+$/.test(doctor.fullname.trim())) {
      setName("Full name must be only alphabets.");
      isValid = false;
    } else {
      setName("");
    }

    if (!doctor?.email.trim()) {
      setEmail("Email is required");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(doctor.email.trim())) {
      setEmail("Invalid email format.");
      isValid = false;
    } else {
      setEmail("");
    }

    const zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
    if (!zipRegex.test(doctor?.address?.pincode)) {
      setZipCode("Invalid zip code, It should have 5-digits.");
      isValid = false;
    } else {
      setZipCode("");
    }

    const areaCodeRegex = /^\d{1,3}$/;
    const numberRegex = /^\d{10}$/;
    if (!areaCodeRegex.test(doctor?.contactNo?.areaCode)) {
      setContact("Area code max length is 3-digits.");
      isValid = false;
    } else if (!numberRegex.test(doctor?.contactNo?.number)) {
      setContact("Contact number must have 10-digits.");
      isValid = false;
    } else if (
      !areaCodeRegex.test(doctor?.contactNo?.areaCode) &&
      !numberRegex.test(doctor?.contactNo?.number)
    ) {
      setContact("Invalid contact number format.");
      isValid = false;
    } else {
      setContact("");
    }

    const npiRegex = /^\d{10}$/;
    if (!npiRegex.test(doctor?.provider?.npi)) {
      setNpi("NPI must have 10-digits.");
      isValid = false;
    } else {
      setNpi("");
    }
    return isValid;
  };

  const UpdateDoctorEditChanges = async () => {
    if (!validateInputs()) {
      return;
    }

    setIsLoading(true);
    // const doctorRef = doc(FirestoreDB, "doctorsList", userDetails.uid);

    const params = {
      fullname: doctor?.fullname || "",
      // email: doctor?.email || "",
      contactNo: doctor?.contactNo || { areaCode: "00", number: "000000000" },
      photoURL: doctor?.photoURL,
      address: doctor?.address,
      provider: doctor?.provider,
      // experience: doctor?.experience || 0,}
    };
    const result = await updateDoctorProfileDetails(
      btoa(JSON.stringify(params)),
      user.uid
    );
    if (result) {
      updateGlobalUser({ ...user, ...doctor });
    }
    setIsLoading(false);
    setEditOpen(false);
    showToastMessage();
  };

  const ClearChanges = () => {
    setDoctor(user);
    setProfilePicFile(null);
    setEditOpen(false);
  };

  const ChangeHandler = () => {
    setEditOpen(true);
  };

  return (
    <Box>
      {editOpen && (
        <Box
          sx={{
            paddingLeft: { xs: "10px", sm: "40px", md: "25px" },
            paddingRight: { xs: "10px", sm: "40px", md: "25px" },
            paddingBottom: "25px",
          }}
        >
          <Stack alignItems="center" sx={{ position: "relative", pb: "25px" }}>
            <Box
              sx={{
                border: 3,
                borderColor: "var(--clr-theme-purple)",
                borderRadius: 20,
              }}
            >
              <Avatar
                className="profile-avatar"
                alt="upload picture"
                sx={{ width: 150, height: 150 }}
                src={profilePicURL ? profilePicURL : doctor?.photoURL}
              >
                <Image />
              </Avatar>
            </Box>

            <Tooltip title="Max size of 10MB">
              <IconButton
                className="profile-upload-icon"
                color="secondary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}
                  disabled={isLoading}
                />
                <AddAPhoto />
              </IconButton>
            </Tooltip>

            <TextField
              label="Full name"
              value={doctor?.fullname}
              error={!!name}
              helperText={name}
              onChange={(e) => {
                setDoctor({
                  ...doctor,
                  fullname: e.target.value,
                });
              }}
              disabled={isLoading}
            />
          </Stack>
          <Grid container spacing={2} sx={{ textTransform: "initial" }}>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                {/* <Typography variant="h5" gutterBottom>
              Locations
            </Typography> */}
                <Typography gutterBottom>street:</Typography>
                <TextField
                  sx={{ m: 1, width: "100%" }}
                  value={doctor?.address?.street}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      address: {
                        ...doctor.address,
                        street: e.target.value.trim(),
                      },
                    });
                  }}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Line 1:</Typography>
                <TextField
                  sx={{ m: 1, width: "100%" }}
                  value={doctor?.address?.line1}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      address: {
                        ...doctor.address,
                        line1: e.target.value.trim(),
                      },
                    });
                  }}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Line 2:</Typography>
                <TextField
                  sx={{ m: 1, width: "100%" }}
                  value={doctor?.address?.line2}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      address: {
                        ...doctor.address,
                        line2: e.target.value.trim(),
                      },
                    });
                  }}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Zip code:</Typography>
                <TextField
                  sx={{ m: 1, width: "100%" }}
                  value={doctor?.address?.pincode}
                  error={!!zipCode}
                  helperText={zipCode}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      address: {
                        ...doctor.address,
                        pincode: e.target.value.trim().slice(0, 5),
                      },
                    });
                  }}
                  disabled={isLoading}
                />
              </Box>
            </Grid>            
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              sx={{ flexWrap: "wrap", width: "100%" }}
            >
              <Typography gutterBottom>Contact number:</Typography>
              <Box display="flex" width="100%">
                <TextField
                  fullWidth
                  type={"phone"}
                  sx={{ m: 1, width: "30%" }}
                  value={doctor?.contactNo?.areaCode}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      contactNo: {
                        ...doctor.contactNo,
                        areaCode: e.target.value.trim().slice(0, 3),
                      },
                    });
                  }}
                  disabled={isLoading}
                />

                <TextField
                  fullWidth
                  type={"phone"}
                  sx={{ m: 1, width: "70%" }}
                  value={doctor?.contactNo?.number}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      contactNo: {
                        ...doctor.contactNo,
                        number: e.target.value.trim().slice(0, 10),
                      },
                    });
                  }}
                  disabled={isLoading}
                />
              </Box>
              <Typography
                className="errorMessage"
                variant="caption"
                sx={{
                  paddingLeft: "18px",
                  textTransform: "none",
                  color: "#d32f2f",
                }}
              >
                {contact}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap"}}>
              <Box>
                <Typography gutterBottom>Gender:</Typography>
                <FormControl disabled={isLoading} 
                  sx={{ width: "100%" ,mt:1}}>
                  <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    value={doctor?.provider?.gender}
                    onChange={(e) => {
                      setDoctor({
                        ...doctor,
                        provider: {
                          ...doctor.provider,
                          gender: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Type:</Typography>
                <TextField
                  sx={{ m: 1, width: "100%" }}
                  value={doctor?.provider?.type}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      provider: {
                        ...doctor.provider,
                        type: e.target.value,
                      },
                    });
                  }}
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>
                  National Provider Indentifier (NPI):
                </Typography>
                <TextField
                  sx={{ m: 1, width: "100%" }}
                  value={doctor?.provider?.npi}
                  error={!!npi}
                  helperText={npi}
                  onChange={(e) => {
                    setDoctor({
                      ...doctor,
                      provider: {
                        ...doctor.provider,
                        npi: e.target.value,
                      },
                    });
                  }}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
            {/* {profileError.show && (
                <Typography className="errorMessage" variant="caption">
                  {profileError.message}
                </Typography>
              )} */}
          </Grid>
        </Box>
      )}

      {!editOpen && (
        <Box>
          <Stack alignItems="center" sx={{ position: "relative" }}>
            <Box
              sx={{
                textAlign: "center",
                border: 3,
                borderColor: "var(--clr-theme-purple)",
                borderRadius: 20,
              }}
            >
              <Avatar
                alt="Remy Sharp"
                src={doctor?.photoURL}
                sx={{
                  width: 150,
                  height: 150,
                }}
              />
            </Box>
            <Typography
              variant="h4"
              sx={{
                color: "var(--clr-theme-purple)",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {validateString(doctor?.fullname)?.charAt(0).toUpperCase() +
                validateString(doctor?.fullname)?.slice(1).toLowerCase()}
            </Typography>
            <Typography variant="h6" sx={{ color: "black" }}>
              {user && user.specialization && user.specialization.length > 0
                ? validateString(user.specialization[0])
                : "No Specialization"}
            </Typography>
          </Stack>

          <Box
            className="flexBox"
            sx={{
              ml: 5,
              mt: 10,
              justifyContent: "space-between",
              flexWrap: "wrap",
              width: "90%",
              display: "flex",
              gap: 2,
            }}
          >
            <Box className="addressBox flexBox flexColumn">
              <Typography variant="h5" gutterBottom sx={{ fontFamily: "Lato" }}>
                Locations
              </Typography>
              <Box>
                <Typography variant="body1">
                  {[
                    doctor?.address?.street,
                    doctor?.address?.line1,
                    doctor?.address?.line2,
                    doctor?.address?.pincode,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </Typography>
              </Box>
            </Box>
            <Box className="flexBox flexColumn flexCenter">
              <Typography variant="h5" gutterBottom sx={{ fontFamily: "Lato" }}>
                Authorization Code
              </Typography>
              <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
                <Box className="flexBox flexRow flexCenter">
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ color: "var(--clr-theme-gray)", mr: 1 }}
                  >
                    {validateString(doctor?.authorisationCode)}
                  </Typography>
                  <Tooltip
                    title={copiedText ? "Copied" : "Copy"}
                    placement="right"
                  >
                    <IconButton onClick={copyToClipboard}>
                      <ContentCopyRounded
                        sx={{ color: copiedText ? "green" : "inherit" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ ml: 5, mt: 5 }}>
            <Typography variant="h5">Provider Details</Typography>
            <Box sx={{ display: "flex" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ flexWrap: "wrap" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ wordBreak: "break-word" }}
                      secondary="Email"
                      primary={validateString(doctor?.email)}
                    />
                  </ListItem>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ flexWrap: "wrap" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <Phone />
                    </ListItemIcon>
                    <ListItemText
                      primary={`+${doctor?.contactNo?.areaCode || "00"}-${
                        doctor?.contactNo?.number || "00000000"
                      }`}
                      secondary="Contact no."
                    />
                  </ListItem>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ flexWrap: "wrap" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <AppRegistration />
                    </ListItemIcon>
                    <ListItemText
                      primary={validateString(doctor?.registrationID)}
                      secondary="Registration ID"
                    />
                  </ListItem>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ flexWrap: "wrap" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <AccountCircle />
                    </ListItemIcon>
                    <ListItemText
                      primary={validateString(doctor?.provider?.gender)}
                      secondary="Gender"
                    />
                  </ListItem>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ flexWrap: "wrap" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <LocalHospital />
                    </ListItemIcon>
                    <ListItemText
                      primary={validateString(doctor?.provider?.type)}
                      secondary="Type"
                    />
                  </ListItem>
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  sx={{ flexWrap: "wrap" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <Badge />
                    </ListItemIcon>
                    <ListItemText
                      primary={validateString(doctor?.provider?.npi)}
                      secondary="National Provider Identifier"
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
      <Stack flexDirection="row" justifyContent="center" gap={5}>
        <Box sx={{ width: "100%", alignItems: "center", textAlign: "center" }}>
          {!editOpen ? (
            <CustomButton
              variant="extended"
              color="primary"
              ChangeHandler={ChangeHandler}
            >
              Edit
            </CustomButton>
          ) : (
            // <Box className="flexBox flexCenter flexWrap" sx={{ gap: 2 }}>
            //   <Box sx={{ width: "20%", minWidth: "250px" }}>
            //     <CustomButton
            //       customClass="customButtons"
            //       color="error"
            //       variant="extended"
            //       ChangeHandler={ClearChanges}
            //     >
            //       Cancel
            //     </CustomButton>
            //   </Box>
            //   <Box sx={{ width: "20%", minWidth: "250px" }}>
            //     <CustomButton
            //       customClass="customButtons"
            //       color="secondary"
            //       variant="extended"
            //       ChangeHandler={UpdateDoctorEditChanges}
            //     >
            //       Save
            //     </CustomButton>
            //   </Box>
            // </Box>
            <Box
              className="flexBox flexCenter flexWrap"
              sx={{
                textAlign: "center",
                minWidth: "250px",
                gap: 5,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "20%",
                  minWidth: "250px",
                  fontFamily: "Lato",
                  textTransform: "capitalize",
                }}
                onClick={ClearChanges}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  backgroundColor: "var(--clr-theme-purple) !important",
                  width: "20%",
                  minWidth: "250px",
                  fontFamily: "Lato",
                  textTransform: "capitalize",
                  "&:disabled": {
                    color: "var(--clr-theme-white) !important",
                    backgroundColor: "rgb(211, 211, 211) !important", // You can adjust this styling as needed
                  },
                }}
                onClick={UpdateDoctorEditChanges}
                disabled={isLoading}
              >
                Update
              </Button>
            </Box>
          )}
        </Box>
      </Stack>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Box>
  );
};

export default DoctorEditInformation;
