import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { fetchAllAttachmentsByUser } from "../../api";
import AuthContext from "../../contexts/AuthContext";
import "./Historical.css";
import { ExpandMore, PictureAsPdf } from "@mui/icons-material";
import { DataContext } from "../../contexts/DataProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { FirestoreDB } from "../../utils/firebase/firebase";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const DocumentsUpload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const { patientId } = location.state || {};
  const [loading, setLoading] = useState(false);
  const { user, typeOfUser } = useContext(AuthContext);
  const { patientData: patientData } = useContext(DataContext);
  const [expanded, setExpanded] = React.useState(0);
  const [toggleStates, setToggleStates] = useState({});

  console.log("documement", documents);

  const fetchDocuments = async () => {
    if (!user) return;
    setLoading(true);
    let fetchedDocuments;
    try {
      if (typeOfUser === "DOCTOR") {
        fetchedDocuments = await fetchAllAttachmentsByUser(
          user?.uid,
          typeOfUser
        );

        const filteredDocuments = fetchedDocuments?.data?.map((patient) => {
          const filteredAttachments = patient?.attachmentData?.filter(
            (attachment) => {
              // Show document if `isPrivateAll` is false or if the current user uploaded it
              if (attachment.docData.isPrivateAll) {
                return attachment.docData.uploadedId === user.uid;
              }
              return true; // Show the document if `isPrivateAll` is false
            }
          );

          return {
            ...patient,
            attachmentData: filteredAttachments,
          };
        });

        setDocuments(filteredDocuments);
      } else if (typeOfUser === "PATIENT") {
        fetchedDocuments = await fetchAllAttachmentsByUser(
          user?.uid,
          typeOfUser
        );

        setDocuments(fetchedDocuments?.data);
      }
    
      const initialToggleStates = fetchedDocuments?.data.reduce((acc, doc) => {
        doc.attachmentData.forEach((attachment) => {
          if (attachment?.docData?.isPrivate) {
            acc[attachment.docId] = attachment?.docData?.isPrivate;
          }
        });
        return acc;
      }, {});
      setToggleStates(initialToggleStates);
    } catch (error) {
      console.log("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("documents", documents);

  const updateAttachmentToggleHandler = async (id, attachmentID, checked) => {
    try {
      await updateDoc(
        doc(FirestoreDB, "historicalData", id, "attachments", attachmentID),
        {
          isPrivate: checked,
        }
      );
      return true;
    } catch (error) {
      console.error("Error updating document:", error);
      return false;
    }
  };

  const handleToggleChange = async (docId, patientId, checked) => {
    const success = await updateAttachmentToggleHandler(
      patientId,
      docId,
      checked
    );
    if (success) {
      setToggleStates((prevState) => ({
        ...prevState,
        [docId]: checked,
      }));
    }
  };

  useEffect(() => {
    user && fetchDocuments();
  }, [user]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const openDocumentHandler = (
    attachmentId,
    documentUrl,
    documentName,
    patientId
  ) => {
    navigate("/smartsearch", {
      state: { attachmentId, documentUrl, documentName, patientId },
    }); // for open doc in smartsearch
  };

  useEffect(() => {
    if (patientId) {
      const patientIndex = documents.findIndex(
        (doc) => doc.patientId === patientId
      );
      if (patientIndex !== -1) {
        setExpanded(patientIndex);
      }
    }
  }, [patientId, documents]);

  return (
    <Box className="fullWidth ">
      <Box>
        {documents?.length > 0 &&
          typeOfUser === "DOCTOR" &&
          documents?.map((patient, index) => {
            return (
              <div key={index} style={{ marginBottom: "10px" }}>
                <Accordion
                  key={patient?.patientId}
                  // expanded={expanded === patient?.user?.id}
                  // onChange={handleChange(patient?.user?.id)}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  sx={{ border: " 1px solid #00000040", width: "100%" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        alt={
                          patientData?.filter(
                            (each) => each.patientId === patient?.patientId
                          )[0]?.patientInfo?.fullname
                        }
                        src={
                          patientData?.filter(
                            (each) => each.patientId === patient?.patientId
                          )[0]?.patientInfo?.photoURL
                        }
                      />
                    </ListItemAvatar>
                    <Typography
                      sx={{
                        alignSelf: "center",
                        fontFamily: "Lato",
                        fontSize: "1rem",
                      }}
                    >
                      {patientData?.filter((each) => {
                        return each.patientId === patient?.patientId;
                      })[0]?.patientInfo?.fullname
                        ? patientData
                            ?.filter((each) => {
                              return each.patientId === patient?.patientId;
                            })[0]
                            ?.patientInfo?.fullname.charAt(0)
                            .toUpperCase() +
                          patientData
                            ?.filter((each) => {
                              return each.patientId === patient?.patientId;
                            })[0]
                            ?.patientInfo?.fullname.slice(1)
                            .toLowerCase()
                        : ""}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List
                      className="newVideosList fullWidth flexBox flexWrap"
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1.5,
                      }}
                    >
                      {patient?.attachmentData?.length > 0 ? (
                        patient?.attachmentData?.map((document) => {
                          return (
                            <ListItem
                              key={document.docId}
                              style={{
                                width: "98%",
                                boxShadow: "0 0 7px var(--clr-theme-purple)",
                                padding: "10px",
                                borderRadius: "10px",
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                              className="list-item"
                              onClick={() =>
                                openDocumentHandler(
                                  document?.docId,
                                  document?.docData?.info?.url,
                                  document?.docData?.info?.fileName,
                                  patient?.patientId
                                )
                              }
                            >
                              <ListItemText
                                primary={document.docData.info.fileName}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                className="primary"
                              />
                              <ListItemText
                                secondary={
                                  <>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Date:{" "}
                                      {new Date(
                                        document.docData.createdAt._seconds *
                                          1000
                                      ).toLocaleDateString()}{" "}
                                      {","}
                                      Time:{" "}
                                      {new Date(
                                        document.docData.createdAt._seconds *
                                          1000
                                      ).toLocaleTimeString()}
                                    </Typography>
                                    {document.docData.info.userType !==
                                      "PATIENT" && (
                                      <LightTooltip
                                        title={
                                          toggleStates[document.docId]
                                            ? "Private"
                                            : "Public"
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Switch
                                          color="success"
                                          checked={toggleStates[document.docId]}
                                          onChange={(e) =>
                                            handleToggleChange(
                                              document.docId,
                                              patient.patientId,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </LightTooltip>
                                    )}
                                  </>
                                }
                                style={{ textAlign: "right" }}
                              />
                            </ListItem>
                          );
                        })
                      ) : (
                        <ListItem>
                          <ListItemText primary="No documents found" />
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        {typeOfUser === "PATIENT" && (
          <List style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {documents && documents?.length > 0 ? (
              documents?.map((document) =>
                !document?.docData?.isPrivate ? (
                  <ListItem
                    key={document.docId}
                    style={{
                      width: "98%",
                      border: "1px solid var(--clr-theme-lgray)",
                      backgroundColor: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                    className="list-item"
                    onClick={() =>
                      openDocumentHandler(
                        document?.docId,
                        document?.docData?.info?.url,
                        document?.docData?.info?.fileName,
                        document?.user?.uid
                      )
                    }
                  >
                   <ListItemIcon>
                      {" "}
                      <PictureAsPdf />
                    </ListItemIcon>
                    <ListItemText
                      primary={document?.docData?.info?.fileName}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                      className="primary"
                    />
                    <ListItemText
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textSecondary"
                          >
                            Date:{" "}
                            {new Date(
                              document.docData.createdAt._seconds * 1000
                            ).toLocaleDateString()}{" "}
                            {","}
                            Time:{" "}
                            {new Date(
                              document.docData.createdAt._seconds * 1000
                            ).toLocaleTimeString()}
                          </Typography>
                        </>
                      }
                      style={{ textAlign: "right" }}
                    />
                  </ListItem>
                ) : null
              )
            ) : (
              <ListItem>
                <ListItemText primary="No documents found" />
              </ListItem>
            )}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default DocumentsUpload;
