import React, { useContext, useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';

import CollapsibleList from '../../CollapsibleList';
import AuthContext from '../../../contexts/AuthContext';
import { DataContext } from '../../../contexts/DataProvider';

const PatientList = ({ icon, text }) => {
  const { user } = useContext(AuthContext);
  const { patientData, fetchPatientsForDoctor } = useContext(DataContext);

  useEffect(() => {
    user?.registrationID &&
      patientData.length === 0 &&
      fetchPatientsForDoctor(user.uid);
  }, [user]);

  return (
    <CollapsibleList mainIcon={icon} primaryText={text} data={patientData} />
  );
};

export default PatientList;
