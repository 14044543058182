import { Box, Card, ListItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import MainLayout from "../../hoc/MainLayout";
import "./ContactInformation.css";
import ContactImage from "../../assets/images/contact_page.png";
import {
  Email,
  FollowTheSigns,
  HomeWork,
  LocalPhone,
} from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { FirestoreDB } from "../../utils/firebase/firebase";
import { toast } from "react-toastify";
import Progress from "../../components/Progress";
import FacebookIcon from "../Updated_UI/SocialLinks/Facebook";
import TwitterIcon from "../Updated_UI/SocialLinks/Twitter";
import LinkedInIcon from "../Updated_UI/SocialLinks/LinkedIn";

const ContactInformation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phnNo, setPhnNo] = useState("");
  const [message, setMessage] = useState("");

  const resetFields = () => {
    setName("");
    setEmail("");
    setCompany("");
    setPhnNo("");
    setMessage("");
  };

  const onClickContactUs = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const companyNameRegex = /^[a-zA-Z0-9\s]+$/;
    const fullnameRegex = /^[A-Za-z ]+$/;

    if (!name || !email || !message || !phnNo || !company) {
      toast.error("Please fill all the required fields.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    // Validate Name
    if (!name.trim()) {
      toast.error("Please enter full name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (!name.trim() || !fullnameRegex.test(name)) {
      toast.error("Please enter a valid full name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    // Validate Email
    if (!email.trim()) {
      toast.error("Please enter email address.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    if (!email.trim() || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    if (!company.trim() || !companyNameRegex.test(company)) {
      toast.error("Please enter a valid company name.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    // Validate Phone Number
    const phoneRegex = /^\d{10}$/; // Assuming a 10-digit phone number
    if (phnNo && !phoneRegex.test(phnNo)) {
      toast.error("Please enter a valid 10-digit phone number.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    // Validate Message
    if (!message.trim()) {
      toast.error("Please enter your message.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }


    try {
      const mailDocId = uuidv4();
      if (message && email && phnNo) {
        const mailRef = doc(FirestoreDB, "emails", mailDocId);
        await setDoc(mailRef, {
          type: "contactUs",
          toEmail: email,
          name,
          company,
          phoneNumber: phnNo,
          body: message,
          date: Timestamp.now(),
        });
        toast.success("Successfully sent mail", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      resetFields();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <MainLayout>
      <Box className="fullWidth fullHeight flexCenter flexBox">
        <section className="fullWidth">
          <Box className="section-header">
            <Box className="container">
              {/* <Typography variant="h2">Contact Us</Typography> */}
              <Typography>
                Want to get in touch? We'd love to hear from you. Here's how you
                can reach us.
              </Typography>
            </Box>
          </Box>

          <Box className="container">
            <Box className="row">
              <Box className="contact-info">
                <Box className="contact-info-item">
                  <Box className="contact-info-icon">
                    <HomeWork />
                  </Box>

                  <Box className="contact-info-content">
                    <h4>Address</h4>
                    <p>
                      1138 Desmond Ct,
                      <br /> Fremont CA, 94539 <br />
                    </p>
                  </Box>
                </Box>

                {/* <Box className="contact-info-item">
                  <Box className="contact-info-icon">
                    <LocalPhone />
                  </Box>

                  <Box className="contact-info-content">
                    <h4>Phone</h4>
                    <p>571-457-2321</p>
                  </Box>
                </Box> */}

                <Box className="contact-info-item">
                  <Box className="contact-info-icon">
                    <Email />
                  </Box>

                  <Box className="contact-info-content">
                    <h4>Email</h4>
                    <p>support@mindly.care</p>
                  </Box>
                </Box>
                <Box className="contact-info-item">
                  <Box className="contact-info-icon">
                    <FollowTheSigns />
                  </Box>

                  <Box className="contact-info-content">
                    <h4>Follow us</h4>
                    <Box className="flexBox flexRow" sx={{ gap: 2 }}>
                      <ListItem disablePadding>
                        <FacebookIcon color={"white"} />
                      </ListItem>
                      <ListItem disablePadding>
                        <TwitterIcon color={"white"} size={21} />
                      </ListItem>
                      <ListItem disablePadding>
                        <LinkedInIcon color={"white"} />
                      </ListItem>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="contact-form">
                <form onSubmit={onClickContactUs} id="contact-form">
                  <h2>Send Message</h2>
                  <Box className="input-box">
                    <TextField
                      label="Full name"
                      id="standard-size-small"
                      size="small"
                      variant="standard"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Box>

                  <Box className="input-box">
                    <TextField
                      label="Email"
                      id="standard-size-small"
                      size="small"
                      variant="standard"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                  <Box className="input-box">
                    <TextField
                      label="Company name"
                      id="standard-size-small"
                      size="small"
                      variant="standard"
                      fullWidth
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Box>
                  {phnNo !== null && (
                    <Box className="input-box">
                      <TextField
                        label="Phone number"
                        id="standard-size-small"
                        size="small"
                        variant="standard"
                        fullWidth
                        value={phnNo}
                        onChange={(e) => setPhnNo(e.target.value)}
                      />
                    </Box>
                  )}
                  <Box className="input-box">
                    <TextField
                      label="Type your message..."
                      id="standard-size-small"
                      size="small"
                      variant="standard"
                      fullWidth
                      multiline
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Box>

                  <Box className="input-box">
                    <input type="submit" value="Send" name="sendButton" />
                  </Box>
                </form>
                {isLoading && <Progress />}
              </Box>
            </Box>
          </Box>
        </section>
      </Box>
    </MainLayout>
  );
};

export default ContactInformation;
