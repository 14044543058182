import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Footer from "../Updated_UI/Footer";
import "./Terms.css";

const PolicyTerms = ({ footerRequired }) => {
  return (
    <Box className="terms">
      <Box
        sx={{
          maxWidth: "90%",
          margin: "20px auto",
          padding: 2,
          overflow: "auto",
        }}
      >
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
            MINDLY APPLICATION TERMS OF SERVICE
          </Typography>
          <Typography paragraph>
            Welcome to Mindly!
            <br />
            <br />
            We provide an online and mobile platform to assist with tracking and
            monitoring progress of health care treatments, consisting of our
            website at{" "}
            <span style={{ marginLeft: "2px" }}>
              <a href="https://mindly.care/">https://mindly.care/</a>
            </span>{" "}
            . (“<u>Website</u>”) and a mobile device application (the “
            <u>App</u>”, and together with the Website and services provided via
            the Website and the App, the “<u>Platform</u>”) developed and made
            available by Mindly Care Inc. (“<b>Mindly</b>”, “<b>we</b>”, “
            <b>us</b>” or “<b>our</b>”).
            <br />
            Please read these terms of service (these “<b>Terms</b>”) carefully.
            These Terms create a legal agreement between you (“<b>You</b>” or “
            <b>Your</b>”) and Mindly establishing the terms and conditions under
            which You may access and use our Platform.
            <br />
            If you are viewing this on Your mobile device, you can also view
            these Terms via a web browser on Your computer on our Website.
          </Typography>
          {/* Add more sections based on the information you collect */}
          <Typography gutterBottom>
            <b>1. Acceptance of Terms.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                THESE TERMS CONTAIN AN <u>AGREEMENT TO ARBITRATE</u>, WHICH
                REQUIRES THAT YOU AND MINDLY ARBITRATE CERTAIN CLAIMS BY
                BINDING, INDIVIDUAL ARBITRATION INSTEAD OF GOING TO COURT, AND
                LIMITS CLASS ACTION CLAIMS, UNLESS YOU OPT OUT OF THE AGREEMENT
                TO ARBITRATE AS DESCRIBED IN SECTION 11 OF THESE TERMS. Please
                only create an Account (as defined below) or otherwise use the
                Platform if you agree to be legally bound by all terms and
                conditions herein. Your acceptance of these Terms creates a
                legally binding contract between you and the Mindly. If you do
                not agree with any aspect of these Terms, then do not create an
                Account or otherwise use the Platform.
              </li>
              <li type="a">
                Our Privacy Policy, at
                <span style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <a href="https://mindly.care/main ">
                    https://mindly.care/main
                  </a>
                </span>
                (the “Privacy Policy”), describes the collection, use and
                disclosure of data and information by Mindly in connection with
                the Platform. The Privacy Policy, as may be updated by us from
                time to time in accordance with its terms, is hereby
                incorporated into these Terms, and You hereby agree to the
                collection, use and disclose practices set forth therein.
              </li>
              <li type="a">
                BY USING OUR PLATFORM, YOU REPRESENT TO US THAT YOU ARE AT LEAST
                EIGHTEEN (18) YEARS OF AGE. Our Platform is intended for use
                solely in the United States of America by residents of the
                United States of America, and we make no claims that our
                Platform is appropriate for use outside of such markets.
              </li>
              <li type="a">
                We may modify these Terms for any reason at any time by posting
                a new version on our Website and App, which changes will not
                affect any rights or obligations accrued prior to such changes.
                By continuing to use the Platform following notice of modified
                Terms, You agree to be bound by the modified Terms. If You do
                not agree to any provision of these Terms or any later version
                of these Terms or otherwise wish to terminate Your relationship
                with us, Your sole option and recourse is to cease using the
                Platform.
              </li>
            </ol>
          </Typography>
          {/* Add more sections based on how you use the collected information */}
          <Typography gutterBottom>
            <b> 2. Health Care Practitioner; Medical Advice</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Health Care Practitioners and their Clients</b>. To the
                extent You or a person for whom You are a parent or guardian (a
                “<u>Client</u>”) have or has been referred to the Platform by a
                physician or other health care practitioner (a “
                <u>Practitioner</u>”), our App may enable You to interact with
                Your referring Practitioner including by uploading certain
                media, including text, email, video, image or audio
                (collectively, Your “<u>Content</u>”) and receiving Output (as
                defined below) to and from the Platform in connection with the
                Practitioner’s treatment or evaluation of You or the Client, as
                applicable. In the event of a conflict between these Terms and
                any written agreement between You and a Practitioner, these
                Terms will govern to the extent of the conflict.
              </li>
              <li type="a">
                <b>Mindly Does Not Provide Medical Advice</b>. The information,
                whether text, data, charts, and other materials generated by our
                Platform from Your use of the Platform, including as facilitated
                by a Practitioner (“<b>Output</b>”) are for Your informational
                purposes only. Mindly does not provide, and the Platform does
                not include or provide, professional medical advice, or
                diagnosis or treatment of any medical or other conditions.
                Without limiting the foregoing, You acknowledge and agree that
                the Platform and any Output made available through the Platform
                are intended for use as a non-device clinical decision support
                software application within the meaning of Section 520(o)(1)(E)
                of the federal Food, Drug and Cosmetic Act, 21 U.S.C. Sec.
                360j(o)(1), and the regulations and guidance issued by the U.S.
                Food and Drug Administration to implement that provision. By
                accessing or using the Platform and Output, You agree to only
                use the Platform and Output in this manner and solely for this
                purpose.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b> 3. Login Credentials; Account Information.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Login Credentials</b>.In order to use our Platform, You may
                be required to create an account (an “<b>Account</b>”). We may
                ask You to create a username and password or we may permit You
                to login through a third party application (Your username and
                password for the Platform or such third party application, Your
                “<b>Login Credentials</b>”). You are responsible for protecting
                Your Login Credentials from unauthorized use, and You are
                responsible for any activity that occurs on Your Account,
                including with respect to any financial obligation. You agree to
                notify us immediately if You believe that Your Login Credentials
                have been or may be used without Your permission. We are not
                responsible for any loss or damage caused directly or indirectly
                by Your failure to safeguard Your Login Credentials.
              </li>
              <li type="a">
                <b>Account Information</b>. During registration for Your
                Account, we may require You to provide certain information, such
                as Your name, email address, physical address, phone number or
                payment account information (any such information, Your “
                <b>Account Information</b>”). You represent and warrant to and
                covenant with us that Your Account Information is accurate,
                complete and up to date. You are responsible for maintaining the
                accuracy, completeness and currency of Your Account Information.
                We may contact You to verify Your Account Information and may
                require You to provide additional information for purposes of
                fraud prevention or verifying Your Account Information. We may
                suspend You from the Platform if You do not provide such
                information to our reasonable satisfaction.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>4. Fees.</b>
          </Typography>
          <Typography paragraph>
            We do not currently anticipate charging fees for Your use of our
            Platform. However, we reserve the right to do so in the future. We
            will provide notice on the Platform before we charge such fees. If
            You continue to use the Platform after we provide such notice, You
            agree to pay the fees described on the Platform.
          </Typography>
          <Typography gutterBottom>
            <b>5. Modifying and Terminating Service; Feedback.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Modification of App. </b>Without limiting Section 1(d) of
                these Terms, we may change or update the Platform at any time
                and for any reason. For instance, we may add or remove features
                including making free services paid services and vice versa. We
                will endeavor to give You advance notice about any material
                changes. However, You understand and agree that we may modify
                the Platform at any time without prior notice to You.
              </li>
              <li type="a">
                <b>Suspension of the App.</b>We may suspend or discontinue the
                Platform in whole or in part at any time and for any reason
                without notice to You. The Platform may periodically become
                unavailable due to maintenance or malfunction of computer
                equipment or other reasons. In order to use the Platform, You
                must have a computer and Internet connection or compatible
                mobile device enabled with our App installed. You are solely
                responsible for procuring any hardware, software or other
                materials that are required or recommended for Your use of the
                Platform.
              </li>
              <li type="a">
                <b>Termination by Us.</b>We may terminate Your access to the
                Platform, in our sole discretion, at any time and for any
                reason. If You have provided us with Your email address, we may
                provide electronic notice of such termination to You at such
                email address. You agree that we are not liable to You or any
                third party for any termination of Your access to the Platform.
              </li>
              <li type="a">
                <b>Termination by You.</b>You may terminate these Terms at any
                time by ceasing to use the Platform and, if applicable, by
                closing Your Account. If You have an Account, we may provide
                instructions on the Platform for how to close Your Account.
                Please follow such instructions to close Your Account.
              </li>
              <li type="a">
                <b>Effects of Termination.</b> Upon expiration or termination of
                these Terms, including in accordance with subsection (d), above,
                Your use of and access to the Platform, and Your right to access
                and use the Platform, shall cease and Mindly shall have no
                obligation to maintain or provide any of Your Content beyond the
                greater of: (i) thirty (30) days after termination; (ii) any
                retention period specified in the Privacy Policy; or (iii) as
                otherwise required by our Data Retention and Disposal Policy,
                and may thereafter in Our discretion, unless legally prohibited,
                delete all Your Content in our systems or otherwise in Our
                possession or under Our control.
              </li>
              <li type="a">
                <b>Survival of Terms.</b>The following Sections of these Terms
                and any accrued obligations will survive any termination of
                these Terms: 2, 4, 5(e), 5(f), 5(g), 6, 7(c), 7(d), 9, 10, 11
                and 12, and any related definitions.
              </li>
              <li type="a">
                <b>Unsolicited Feedback.</b>Your sole remedy if You are
                dissatisfied with our App or these Terms is that You may
                discontinue Your use of the Platform. However, if You would like
                to provide feedback or suggestions regarding the Platform or
                these Terms (collectively, “Feedback”), You may in Your
                discretion contact us at
                <span style={{ marginLeft: "2px", marginRight: "2px" }}>
                  <a href="support@mindly.care">support@mindly.care</a>
                </span>
                .We do not guarantee that any modification will be made to the
                Platform or these Terms based on Your Feedback. In no event will
                any modification to the Platform or these Terms following
                receipt of Your Feedback evidence assumption of liability by
                Mindly of any claim or future claim that may be brought in
                connection with the Platform or these Terms.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>6. Intellectual Property; Licenses; Content; Individual Data.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Content, Individual Data, Suggestions.</b>
              </li>
              <ol>
                <li type="i">
                  <b>License to Use the Platform.</b> Subject to Your compliance
                  with all the terms and conditions of these Terms, Mindly
                  grants you a limited, personal, non-transferable,
                  non-exclusive, non-sublicensable, revocable license to use the
                  Platform, including by downloading the App via a compatible
                  mobile device, for Your personal use, in each case in the
                  manner enabled by Mindly. If You are using the App on an
                  Apple, Inc. (“<u>Apple</u>”) iOS device, the foregoing license
                  is further limited to use permitted by the Usage Rules set
                  forth in Apple’s App Store Terms of Service.
                </li>
                <li type="i">
                  <b>Our Materials.</b> Mindly or its licensors own all
                  worldwide right, title and interest, including all
                  intellectual property and other proprietary rights, in and to:
                  (a) the App: (b) the Website; (c) Output, except to the extent
                  it derives from Your Content and all materials we make
                  available through the Platform; and (d) all usage and other
                  data generated or collected in connection with the use thereof
                  (the foregoing (a), (b), (c) and (d), collectively, “
                  <u>Mindly Materials</u>”). Except as expressly set forth in
                  these Terms, You agree not to license, distribute, copy,
                  modify, publicly perform or display, transmit, publish, edit,
                  adapt, create derivative works from, or otherwise make any
                  unauthorized use of the Mindly Materials. You agree not to
                  reverse engineer, decompile, disassemble or otherwise attempt
                  to discover the source code, algorithm or programs underlying
                  the Platform or any Mindly Materials. Mindly reserves the
                  right to modify or discontinue the App, Website, Platform as a
                  whole or any parts or version(s) thereof at any time in its
                  sole discretion, with or without notice.
                </li>
                <li type="i">
                  <b>License to Mindly Materials.</b> Subject to these Terms and
                  any other written agreement signed by You and us, we hereby
                  grant You a limited, personal, non-transferable,
                  non-exclusive, non-sublicensable, revocable license to access
                  and use Mindly Materials that we make available through the
                  Platform, solely for Your personal and non-commercial use, and
                  subject to any restrictions on certain types of Mindly
                  Materials set forth in these Terms. You understand that any
                  Output or other information available through the Platform is
                  used by You at Your own risk.
                </li>
                <li type="i">
                  <b>Changes to Content.</b> We reserve the right to make
                  changes to any Mindly Materials or descriptions of the
                  Platform without obligation to issue any notice of such
                  changes.
                </li>
                <li type="i">
                  <b>Rights in User Content Granted by You.</b> You own Your
                  Content. You hereby grant to Mindly a non-exclusive,
                  perpetual, irrevocable, worldwide, sublicensable,
                  transferable, royalty free, fully paid up license to:
                </li>
                <ol>
                  <li type="a">
                    process, store and otherwise use Your Content that You
                    submit to the Platform for the purposes of providing the
                    Platform and services to You;
                  </li>
                  <li type="a">
                    process store and otherwise use analytical output processed
                    from metadata relating to Your Content for any purpose
                    perpetually; and
                  </li>
                  <li type="a">
                    c. improve the Platform through application of any
                    mathematical model that is derived from any algorithm using
                    any dataset (“<b>Machine Learning</b>”), tuning or any
                    enhancement, modification, derivative or other improvement
                    to the Platform in connection with the provision of any
                    services (“<b>AI Improvements</b>”). You agree that, in the
                    ordinary course of Machine Learning development, as part of
                    such AI Improvements, Mindly may extract and retain patterns
                    from Your Content processed in connection with these Terms
                    which do not identify You and do not enable reconstruction
                    of the Your Content (“<b>Patterns</b>”) and use, copy,
                    prepare derivative works of or otherwise exploit any such
                    Pattern to continue to improve the Platform, or successor
                    product or service, in perpetuity. Any AI Improvement
                    arising from these shall be the sole and exclusive property
                    of Mindly and shall constitute our intellectual property.
                  </li>
                </ol>
                <li type="i">
                  <b>Disclosure of Your Content.</b>Mindly will not disclose
                  Your Content to third parties, except: (a) Your Content
                  disclosed to the applicable Practitioner; (b) as You may
                  authorize through the App; and (c) as otherwise permitted
                  under these Terms or under the Privacy Policy.
                </li>
                <li type="i">
                  <b>Your Responsibility for User Content.</b> YOU ARE SOLELY
                  RESPONSIBLE FOR ALL OF YOUR CONTENT. YOU MUST PROVIDE US ONLY
                  VIDEO AND OTHER CONTENT FOR WHICH YOU HAVE ALL RIGHTS
                  NECESSARY TO COPY, USE, MODIFY AND EXPLOIT SUCH VIDEOS AS
                  CONTEMPLATED IN THESE TERMS. YOU REPRESENT AND WARRANT THAT
                  YOU OWN YOUR CONTENT OR, ALTERNATIVELY, HAVE ALL RIGHTS THAT
                  ARE NECESSARY TO GRANT US THE LICENSE RIGHTS IN YOUR CONTENT
                  UNDER THESE TERMS. YOU REPRESENT AND WARRANT THAT NEITHER YOUR
                  CONTENT, NOR YOUR USE OR PROVISION OF YOUR CONTENT TO BE MADE
                  AVAILABLE THROUGH OUR PLATFORM, NOR ANY USE OF YOUR CONTENT BY
                  MINDLY ON OR THROUGH OUR PLATFORM, WILL INFRINGE,
                  MISAPPROPRIATE, OR VIOLATE ANY THIRD PARTY’S INTELLECTUAL
                  PROPERTY RIGHTS, OR ANY RIGHTS OF PUBLICITY OR PRIVACY, OR
                  WILL RESULT IN THE VIOLATION OF ANY APPLICABLE LAW OR
                  REGULATION.
                </li>
                <li type="i">
                  <b>Unsolicited Feedback.</b> If You send us any Feedback as
                  contemplated under Section 5(g) of these Terms, You represent,
                  warrant and agree that Your Feedback does not contain
                  confidential or proprietary information of any third party. In
                  addition, You hereby grant to Mindly, at no charge, a
                  non-exclusive, royalty-free, worldwide, transferable,
                  sublicensable (through one or more tiers), perpetual,
                  irrevocable license in and to Feedback regarding the Platform
                  or other services, including Feedback regarding features,
                  usability and use, and bug reports, to reproduce, perform,
                  display, create derivative works of the Feedback and
                  distribute such Feedback and/or derivative works in the
                  Platform or any other products or services. Except as set
                  forth in this Section 6(a)viii, Feedback is provided by You
                  “as is” without warranty of any kind by You.
                </li>
              </ol>
              <li type="a">
                <b>Limitations; Revocation.</b> Your license to use the Platform
                is automatically revoked if You violate these Terms. We may
                upgrade or improve the Platform or any parts thereof. You agree
                that these Terms will apply to all such upgrades or
                improvements. The license grant in this Section 6 is not a sale
                of any mobile application we may provide or the Website or a
                sale of a copy of any such application or our Website, and we
                retain all rights and interest in the Platform and all parts
                thereof. Any attempt by You to transfer any of the rights,
                duties or obligations hereunder, except as expressly provided
                for in these Terms, is void. We reserve all rights not expressly
                granted under these Terms.
              </li>
              <li type="a">
                <b>No Implied Licenses.</b> Nothing contained in the Platform
                should be construed as granting, by implication, estoppel, or
                otherwise, any license or right to use the Platform or any
                Mindly Materials, through the use of framing or otherwise,
                except: (a) as expressly permitted by these Terms; or (b) with
                our prior written permission or the written permission of the
                third party that owns the applicable trademark, copyright or
                other material displayed on the Website or the App.
              </li>
              <li type="a">
                <b>Copyright Infringement; DMCA Policy.</b>If You believe that
                any materials displayed on the Platform infringe Your copyright,
                You may request that such materials be removed. This request
                must bear a signature (or electronic equivalent) of the
                copyright holder or an agent and must include the following: (i)
                identification of the copyrighted work that You believe to be
                infringed, including a description of the work and, where
                possible, a copy or the location of an authorized version of the
                work; (ii) identification of the material that You believe to be
                infringing, including a description of the material, and its
                location on our Website; (iii) Your name, address, telephone
                number and email address; (iv) a statement that You have a good
                faith belief that the complained of use of the materials is not
                authorized by the copyright owner, its agent or the law; (v) a
                statement that the information in Your claim is accurate; and
                (vi) a statement that “under penalty of perjury,” You declare
                that You are the lawful copyright owner or are authorized to act
                on the owner’s behalf. Our agent for copyright issues relating
                to the Platform is Copyright Agent, [1138 Desmond Ct, Fremont
                CA, 94539] or [support@mindly.care]. To protect the rights of
                copyright owners, we reserve the right to delete or disable the
                content alleged to be infringing and suspend or terminate the
                Account or other user privileges of any infringing user of the
                Platform
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>7. Your Use; Prohibited Conduct.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>General.</b> As a condition of Your use of the Platform, You
                will not use the Platform for any purpose that is unlawful or
                otherwise prohibited by these Terms. You further agree to comply
                with any other applicable terms and conditions of use set forth
                on the Platform. We reserve the right, without prior notice to
                You and in our sole discretion, to terminate Your access to the
                Platform if we decide that Your use violates these Terms,
                including for the reasons listed in this Section 7, or for any
                other reason.
              </li>
              <li type="a">
                <b>Prohibited Use; Unauthorized Access.</b> You agree not, and
                will not permit any person or entity, to: (i) use, or allow the
                use of, the Platform for any unfair or deceptive practices or in
                contravention of any federal, state, local, foreign or other
                applicable law or rules and regulations of regulatory or
                administrative organizations; (ii) act in a fraudulent,
                tortious, malicious or negligent manner when using the Platform;
                (iii) act in any manner that, in our sole discretion, could
                damage, disable, overburden, impair or interfere with any other
                party’s use of the Platform; (iv) obtain or attempt to obtain
                any information through any means not intentionally made
                available through the Platform; (v) obtain unauthorized access
                to any computer system through the Platform; (vi) circumvent,
                remove or otherwise interfere with any security-related features
                of the Platform, features that prevent copying or using any part
                of the Platform or features that enforce limitations on the use
                of the Platform or any Mindly Materials; (vii) introduce
                viruses, worms, Trojan horses or harmful code to the Platform;
                or (viii) use any robot, spider, site search/retrieval
                application or other automated device, process or means to
                access, retrieve, scrape or index any portion of the Platform or
                any Mindly Materials. In the event that You gain access to
                information not intended to be accessed by You, You agree that
                You will immediately notify us and destroy all copies of such
                information in Your possession.
              </li>
              <li type="a">
                <b>Intellectual Property Infringement.</b> You agree that the
                structure, organization, and code used in conjunction with the
                App and Website are proprietary to us. You shall not, and shall
                not permit any person or entity to: (i) use the Platform or any
                parts thereof on a service bureau, time sharing or any similar
                basis, or otherwise for the benefit of any other person or
                entity; (ii) alter, enhance, or make derivative works of our App
                or Website or any Mindly Materials available through the
                foregoing; (iii) reverse engineer, reverse assemble or
                decompile, or otherwise attempt to derive source code from the
                App or Website; or (iv) sell, transfer, publish, disclose,
                display or otherwise make available the App or Website including
                any modifications, enhancements, derivatives and other software
                and materials provided hereunder by us or copies thereof to
                others in violation of these Terms. Unless as otherwise set
                forth by us in writing, You understand and acknowledge that all
                Content contained on the Platform is the property of us and/or
                our affiliates or licensors and is protected from unauthorized
                copying and dissemination by United States copyright law,
                trademark law, international conventions, and other intellectual
                property laws.
              </li>
              <li type="a">
                <b>Your Representations, Warranties and Covenants.</b> You
                represent, warrant and covenant that You will utilize the App
                hereunder in strict conformance with all applicable laws,
                regulations and professional licensure requirements applicable
                to You.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>8. Third Party Service.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Third Party Service.</b> the Platform may include features or
                functionalities that interoperate with services operated by
                third parties, which may be pursuant to a generally available
                application programming interface made available by such third
                party or an agreement that we have with such third party. We
                have no control over any features or functionalities offered by
                any third party, and such features or functionalities may be
                modified, suspended or terminated at any time without notice.
              </li>
              <li type="a">
                <b>Third Party Links.</b> The Platform may contain links to
                third party sites, which are provided to You as a convenience.
                We are not responsible for and have no control over the content
                of any linked third party site. You acknowledge and agree that
                any third party site linked or otherwise accessed from the
                Platform is independent from us, and that we do not endorse or
                accept any responsibility for the content or use of any such
                third party site. Use of any third party site is subject to the
                terms of service and privacy policy of such third party. We
                advise that You exercise caution and good judgment when
                accessing and using any third party sites and services.
              </li>
              <li type="a">
                <b>Providers of Third Party Platforms.</b> You hereby
                acknowledge and agree that all of our licensors, suppliers or
                other third parties: (i) are not parties to these Terms; (ii)
                have no obligation whatsoever to furnish any maintenance or
                support services with respect to Mindly or the Platform; (iii)
                are not responsible for addressing claims by You or any third
                party relating to the Platform, including any product liability
                claims, claims under consumer protection laws or claims under
                any other law, rule or regulation; and (iv) have no
                responsibility to investigate, defend, settle or discharge any
                claim that the Platform or use thereof infringes any third party
                intellectual property rights.
              </li>
              <li type="a">
                <b>Telecommunications.</b> When using the Platform, Your
                telecommunications carrier’s normal rates and charges apply.
                Unless otherwise set forth in these Terms, we are not
                responsible for any charges You incur from Your
                telecommunications carrier or otherwise as a result of Your use
                of the Platform. You are responsible for ensuring that, at all
                times while using the Platform, You are not in violation of any
                agreement with Your telecommunications carrier.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>9. Limitation of Liability; Disclaimers.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Disclaimer.</b>
              </li>
              <ol>
                <li type="i">
                  <b>No Warranty.</b> THE PLATFORM AND ALL CONTENT ON OR
                  ACCESSIBLE FROM OUR APP IS PROVIDED “AS IS” AND “AS AVAILABLE”
                  WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                  INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                  NON-INFRINGEMENT. SPECIFICALLY, BUT WITHOUT LIMITATION, WE DO
                  NOT WARRANT THAT: (A) THE INFORMATION AVAILABLE THROUGH THE
                  PLATFORM IS FREE OF ERRORS; (B) THE FUNCTIONS OR SERVICES
                  (INCLUDING WITHOUT LIMITATION MECHANISMS FOR THE DOWNLOADING
                  AND TRANSMITTING CONTENT) PROVIDED BY THE PLATFORM WILL BE
                  UNINTERRUPTED, SECURE OR FREE OF ERRORS; (C) DEFECTS WILL BE
                  CORRECTED, OR (D) THAT OUR SERVERS OR THE SERVER(S) THAT MAKE
                  THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                  COMPONENTS.
                </li>
                <li type="i">
                  <b>Waiver of Liability.</b> WE AND OUR AFFILIATES AND
                  LICENSORS CANNOT AND DO NOT GUARANTEE THAT ANY PERSONAL
                  INFORMATION SUPPLIED BY YOU WILL NOT BE MISAPPROPRIATED,
                  INTERCEPTED, DELETED, DESTROYED OR USED BY OTHERS. Under no
                  circumstances will we be liable for any loss or damage caused
                  by failed delivery or receipt of Content or any third party’s
                  use or distribution of Content. You hereby acknowledge and
                  agree that Mindly merely stores and hosts Content but does not
                  actively create Content. Without limiting the foregoing, in
                  the event Mindly chooses to monitor any Content, Mindly still
                  assumes no liability or responsibility for such Content. Under
                  no circumstances will Mindly be liable for any claims that may
                  arise from Content, including without limitation claims for
                  intellectual property infringement.
                </li>
              </ol>
              <li type="a">
                <b>Limitation of Liability.</b>
              </li>
              <ol>
                <li type="i">
                  <b>General.</b> IN NO EVENT SHALL WE BE LIABLE TO YOU, ANY
                  OTHER USER OF THE PLATFORM, ANY THIRD PARTY PROVIDER OR ANY
                  OTHER PERSON OR ENTITY FOR ANY SPECIAL, INCIDENTAL, PUNITIVE,
                  CONSEQUENTIAL, EXEMPLARY OR OTHER INDIRECT DAMAGES (INCLUDING
                  WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA,
                  LOSS OF USE OR COSTS OF OBTAINING SUBSTITUTE GOODS OR
                  SERVICES) ARISING OUT OF THE USE, INABILITY TO USE,
                  UNAUTHORIZED ACCESS TO OR USE OR MISUSE OF THE PLATFORM, YOUR
                  CONTACT INFORMATION, CONTENT OR ANY INFORMATION CONTAINED
                  THEREON OR IN CONNECTION THEREWITH, WHETHER BASED UPON
                  WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE,
                  EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                  DAMAGES OR LOSSES.
                </li>
                <li type="i">
                  <b>Limitation.</b>. OUR AGGREGATE LIABILITY TO YOU FOR ALL
                  CLAIMS ARISING FROM THESE TERMS SHALL NOT EXCEED THE GREATER
                  OF (A) $100.00; OR (B) THE AGGREGATE AMOUNT YOU HAVE PAID TO
                  US IN FEES, IF ANY, IN THE THEN-PRIOR SIX (6)-MONTH PERIOD.
                </li>
                <li type="i">
                  <b>Exclusions.</b> SOME JURISDICTIONS DO NOT ALLOW THE
                  EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION
                  OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
                  ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO
                  YOU BUT SHALL INSTEAD APPLY TO THE MAXIMUM EXTENT PERMITTED BY
                  LAW.
                </li>
              </ol>
              <li type="a">
                <b>Release.</b> The Platform is only a means of tracking and
                monitoring progress of health care treatments, and we do not
                take part in the interaction between or among any Client and any
                Practitioner. As a result of our limited involvement in the
                actual contact between any Client and any Practitioner, in the
                event that You have a dispute with any Practitioner or Client,
                as applicable, You hereby release us, and our officers,
                directors, employees, agents, investors, subsidiaries and
                contractors from any and all claims, demands or damages (actual
                or consequential) of every kind and nature, known and unknown,
                suspected and unsuspected, disclosed and undisclosed, arising
                out of or in any way connected with such dispute. We expressly
                disclaim any liability or claims that may arise between or among
                Users of the Platform and Practitioners. You waive California
                Civil Code Section 1542, which says: “A general release does not
                extend to claims that the creditor or releasing party does not
                know or suspect to exist in his or her favor at the time of
                executing the release and that, if known by him or her, would
                have materially affected his or her settlement with the debtor
                or released party.” You hereby waive any other similar provision
                of applicable law that applies to You.
              </li>
              <li type="a">
                <b>Indemnification.</b> BY USING THE PLATFORM, YOU HEREBY AGREE
                TO INDEMNIFY AND HOLD HARMLESS US AND OUR OFFICERS, DIRECTORS,
                EMPLOYEES, AGENTS, INVESTORS, SUBSIDIARIES AND CONTRACTORS FROM
                ANY CLAIMS, DAMAGES, LOSSES, LIABILITIES, AND COSTS AND EXPENSES
                OF DEFENSE (COLLECTIVELY, “<b>CLAIMS</b>”), INCLUDING ATTORNEYS’
                FEES, RESULTING DIRECTLY OR INDIRECTLY FROM A CLAIM BY A THIRD
                PARTY, INCLUDING ANY PRACTITIONER, THAT ARISES IN CONNECTION
                WITH: (i) YOUR PROVISION OF ANY CONTENT; (ii) YOUR USE OF THE
                PLATFORM; OR (iii) ANY USER OR OTHER THIRD PARTY’S USE OF ANY
                CONTENT THAT YOU SUBMIT VIA THE PLATFORM. AT OUR OPTION, YOU
                AGREE TO DEFEND US FROM ANY CLAIMS.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>10. Miscellaneous; Jurisdiction</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>Miscellaneous.</b> Under these Terms, you consent to receive
                communications from Mindly electronically, including without
                limitation via email or push notifications. These Terms shall be
                governed by and construed in accordance with the laws of the
                State of California, without regard to principles of conflicts
                of law that would result in the application of the law of any
                other jurisdiction, except that the Federal Arbitration Act
                shall govern the interpretation and enforcement of the
                arbitration provisions set forth below. You agree that any
                action at law or in equity arising out of or relating to these
                Terms or the Platform that is not subject to arbitration under
                Section 11 shall be filed only in the state or federal courts in
                California and You hereby consent and submit to the personal
                jurisdiction of such courts for the purposes of litigating any
                such action. The failure of any party at any time to require
                performance of any provision of these Terms shall in no manner
                affect such party’s right at a later time to enforce the same. A
                waiver of any breach of any provision of these Terms shall not
                be construed as a continuing waiver of other breaches of the
                same or other provisions of these Terms. We and You are
                independent contractors and are not partners, joint venturers,
                agents, employees or representatives of each other. If any
                provision of these Terms shall be unlawful, void, or for any
                reason unenforceable, then that provision shall be deemed
                severable from these Terms and shall not affect the validity and
                enforceability of any remaining provisions. These Terms, and any
                rights and licenses granted hereunder, may not be transferred or
                assigned by You, but may be assigned by Mindly without
                restriction. The headings and captions used in these Terms are
                used for convenience only and are not to be considered in
                construing or interpreting these Terms. Any use of the term
                “including” in these Terms shall be deemed to mean “including
                without limitation.” If any part of these Terms is held to be
                unlawful, void, or unenforceable, such part will be deemed
                severable and shall not affect the validity and enforceability
                of the remaining provisions of these Terms. This is the entire
                agreement between You and Mindly relating to the subject matter
                herein and shall not be modified except in a writing, signed by
                both parties, or by a change to these Terms made by Mindly as
                set forth herein.
              </li>
              <li type="a">
                <b>Jurisdictional Issues.</b> We make no representation that
                information on the Platform is appropriate or available for use
                outside of the United States of America. Those who choose to
                access the Platform from outside the United States do so on
                their own initiative and at their own risk and are responsible
                for compliance with applicable local laws. By using the
                Platform, You consent to having Your Login Credentials and any
                personal information that You provide to us transferred to and
                processed in the United States of America subject to the
                restrictions on such data as provided in our Privacy Policy.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>11. Agreement to Arbitrate; Waiver of Class Action.</b>
          </Typography>
          <Typography paragraph>
            <ol>
              <li type="a">
                <b>
                  Mandatory Arbitration of Disputes; Arbitration Procedures.
                </b>{" "}
                Except if You opt-out or for disputes relating to Your or our
                intellectual property (such as trademarks, trade dress, domain
                names, trade secrets, copyrights and patents), You agree that
                all disputes between You and us (whether or not such dispute
                involves a third party) arising out of or relating to these
                Terms, the Platform, and/or our Privacy Policy shall be finally
                resolved by arbitration before a single arbitrator conducted in
                the English language in the Commonwealth of Massachusetts. under
                the Commercial Arbitration Rules of the American Arbitration
                Association (“AAA”) and You and we hereby expressly waive trial
                by jury. You and we shall appoint as sole arbitrator a person
                mutually agreed by You and us or, if You and we cannot agree
                within thirty (30) days of either party’s request for
                arbitration, such single arbitrator shall be selected by the AAA
                upon the request of either party. The parties shall bear equally
                the cost of the arbitration (except that the prevailing party
                shall be entitled to an award of reasonable attorneys’ fees
                incurred in connection with the arbitration in such an amount as
                may be determined by the arbitrator). All decisions of the
                arbitrator shall be final and binding on both parties and
                enforceable in any court of competent jurisdiction.
                Notwithstanding the foregoing, application may be made to any
                court for a judicial acceptance of the award or order of
                enforcement. Under no circumstances shall the arbitrator be
                authorized to award damages, remedies or awards that conflict
                with these Terms.
              </li>
              <li type="a">
                <b>Class Action Waiver.</b> Any claims brought by You or us must
                be brought in such party’s individual capacity, and not as a
                plaintiff or class member in any purported class or
                representative proceeding. You agree and acknowledge that
                neither You nor we will participate in a class action or
                class-wide arbitration for any claims covered by these Terms.
                You hereby waive any and all rights to bring any claims related
                to these Terms and/or our Privacy Policy as a plaintiff or class
                member in any purported class or representative proceeding. You
                may bring claims only on Your own behalf.
              </li>
              <li type="a">
                <b>Opt-out.</b> You may opt out of this Agreement to Arbitrate.
                If You do so, neither You nor we can require the other to
                participate in an arbitration proceeding. To opt out, You must
                notify us in writing within thirty (30) days of the date that
                You first became subject to this arbitration provision. The
                opt-out notice must state that You do not agree to the Agreement
                to Arbitrate and must include Your name, address, phone number,
                Your Account, if applicable, to which the opt-out applies and a
                clear statement that You want to opt out of this Agreement to
                Arbitrate. You must sign the opt-out notice for it to be
                effective. This procedure is the only way You can opt out of the
                Agreement to Arbitrate. You must use this address to opt out:
                Mindly Care Inc. ATTN: Arbitration Out-Out, [1138 Desmond Ct,
                Fremont CA, 94539].
              </li>
              <li type="a">
                <b>Effect of Changes on Arbitration.</b> Notwithstanding any
                provision in these Terms to the contrary, You and we agree that
                if we make any change to the arbitration procedures set forth in
                this Section 11 (other than a change to any notice address or
                Website link provided herein) in the future, that change shall
                not apply to any claim that was filed in a legal proceeding
                against us prior to the effective date of the change. Moreover,
                if we seek to terminate such arbitration procedures from these
                Terms, such termination shall not be effective until thirty (30)
                days after the version of these Terms not containing such
                arbitration procedures is posted to our Website, and shall not
                be effective as to any claim that was filed in a legal
                proceeding against us prior to the effective date of removal.
              </li>
              <li type="a">
                <b>Survival.</b> In accordance with Section 5 (Modifying and
                Terminating Service), this Section 11 (Agreement to Arbitrate;
                Waiver of Class Action) will survive the termination of Your
                relationship with us.
              </li>
            </ol>
          </Typography>
          <Typography gutterBottom>
            <b>12. Additional Information; Complaints.</b>
          </Typography>
          <Typography paragraph>
            If You have any questions about these Terms, please contact Us at
            [support@mindly.care]. If you are a California resident, note that
            you may reach the Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs
            via mail at 1625 North Market Blvd., Suite N112, Sacramento, CA
            95834 or telephone at (800) 952-5210. Hearing impaired users can
            call (800) 735-2929 (TTY) or (800) 735-2922 (Voice).
          </Typography>
        </CardContent>
      </Box>
      {footerRequired && <Footer />}
    </Box>
  );
};

export default PolicyTerms;
