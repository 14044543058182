import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UIContext } from '../../contexts/UIProvider';

import './CollapsibleList.css';

const CollapsibleList = ({ mainIcon, primaryText, data }) => {
  const { selectedPatientId, selectPatient } = useContext(UIContext);
  const [open, setOpen] = React.useState(selectedPatientId !== -1 || false);

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const goToPatient = (item) => {
    selectPatient(item.patientId);

    navigate(`/patient/${item.patientId}`, {
      state: {
        patientData: { ...item?.patientInfo, id: item.patientId },
      },
    });
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ color: 'var(--clr-theme-purple)' }}>
          {mainIcon}
        </ListItemIcon>
        <ListItemText primary={primaryText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding className='collapsedList'>
          {data.map((item, i) => (
            <ListItemButton
              key={i}
              sx={{ pl: 4 }}
              className={
                selectedPatientId === item.patientId ? 'selectedPatient' : ''
              }
              onClick={() => goToPatient(item)}
            >
              <ListItemIcon>
                {item.icon ||
                  (item?.patientInfo?.photoURL && (
                    <Avatar src={item?.patientInfo?.photoURL} />
                  )) || <Avatar />}
              </ListItemIcon>
              <ListItemText
                primary={item?.patientInfo?.fullname
                  ? `${item.patientInfo.fullname.split(/\s+/)
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(" ")}`
                  : ''}
                sx={{}}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default CollapsibleList;
