import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import React from "react";

import "./HomeCard.css";

const HomeCard = ({
  title,
  subheader,
  picture,
  children,
  actions,
  showAvatar,
  textAlign,
  customClass = "",
}) => {
  return (
    <Card className={`flexBox flexColumn ${customClass || "home-card"}`}>
      {title && (
        <CardHeader
          sx={{
            padding: "10px",
            backgroundColor: "var(--clr-theme-purple-50)",
            textAlign:{textAlign}
          }}
          title={title}
          subheader={subheader}
          avatar={
            showAvatar && (
              <Avatar
                src={picture}
                sx={{
                  border: "2px solid white",
                }}
              >
                {title?.substring(0, 1)}
              </Avatar>
            )
          }
          // action={actions}
        />
      )}
      <CardContent>{children}</CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};

export default HomeCard;
