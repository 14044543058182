import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  InputBase,
  IconButton,
  Paper,
  Avatar,
  Divider,
  Box,
  TextField,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  ListItem,
  ListItemAvatar,
  CircularProgress,
  CardHeader,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Stack,
} from "@mui/material";
import {
  Search,
  Paperclip,
  EmojiEmotions,
  Send,
  Close,
  West,
  Groups,
} from "@mui/icons-material";
import AuthContext from "../../contexts/AuthContext";
import Reminders from "../../components/Reminders";
import { fetchDoctorAndPatientMessages, fetchGroupMessages } from "../../api";
import { stableSort } from "../../utils/helper";
import {
  Timestamp,
  addDoc,
  collection,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore";
import { FirestoreDB } from "../../utils/firebase/firebase";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";

// here chat id will represents the group chat id or slected user id
const Conversation = ({
  chatId,
  chatName = "",
  chatPhotoURL,
  isGroupChat,
  onChatNameUpdate,
  navigateProfile = () => {},
  handleReminder = () => {},
  chatUserInfo,
  
}) => {
  
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [inputError, setInputError] = useState("");
  const [members, setMembers] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false); // State to control edit dialog visibility
  const [editedChatName, setEditedChatName] = useState(chatName); // State to hold edited chatName
  // const [originalChatName, setOriginalChatName] = useState(chatName);
  console.log(chatName,'chatName');

  const scroll = useRef(null);

  const { user, typeOfUser, setOpenRightPanel, setPanelContent } =
    useContext(AuthContext);

  useEffect(() => {
    setEditedChatName(chatName);
  }, [chatName]);

  const handleOpenEditDialog = () => {
    setEditedChatName(chatName); // Reset editedChatName to current chatName
    // setOriginalChatName(chatName); // Store original chatName before editing
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    // Reset editedChatName to originalChatName when closing dialog without saving
    // setEditedChatName(originalChatName);
  };

 
  const handleSaveEdit = async () => {
    // Log edited chat name to console
    console.log("Edited Chat Name:", editedChatName);

    try {
      // Update Firestore document with editedChatName
      const groupChatRef = doc(FirestoreDB, "groupChats", chatId);
      await updateDoc(groupChatRef, { groupName: editedChatName });

      // Update local state to reflect the saved edited name
      setEditedChatName(editedChatName);

      // Close the edit dialog
      setOpenEditDialog(false);
      
      // // Close the panel or perform any other necessary actions
      setOpenRightPanel(false);
      setPanelContent(null); // Clear panel content

      if (typeof onChatNameUpdate === "function") {
        onChatNameUpdate(editedChatName);
      }
      
      toast.success("Group name updated successfully");

    } catch (error) {
      console.error("Error updating group chat name:", error);
      toast.error("Failed to update group chat name. Please try again later.");
    }
  };
  const handleCancelEdit = () => {
    // Close the edit dialog
    setOpenEditDialog(false);
    // Reset editedChatName to originalChatName when canceling
    // setEditedChatName(originalChatName);
  };

  const fetchMembers = () => {
    const memeber = collection(FirestoreDB, "groupChats", chatId, "members");
    const unsubscribe = onSnapshot(memeber, (groupSnapshot) => {
      const memberList = groupSnapshot.docs.map((doc) => doc.data());
      setMembers(memberList);
    });

    return unsubscribe;
  };
  useEffect(() => {
    const unsubscribe = chatId && fetchMembers();
    return () => chatId && unsubscribe();
  }, [chatId]);

  const viewGroup = () => {
    setOpenRightPanel(true);
    setPanelContent(
      <div
        className="fullWidth fullHeight flexBox flexColumn"
        style={{ gap: 4 }}
      >
        <Stack spacing={2} sx={{ backgroundColor: "#9291f033", p: 5 }}>
          <Avatar
            alt="Remy Sharp"
            src="https://cdn-icons-png.flaticon.com/512/6387/6387947.png"
            sx={{
              width: 156,
              height: 156,
              alignSelf: "center",
              border: "5px solid white",
            }}
          />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="h4"
              sx={{ fontFamily: "Lato" }}
              textAlign="center"
            >
              {chatName.charAt(0).toUpperCase() + chatName.slice(1).toLowerCase()}
            </Typography>
            <Button onClick={handleOpenEditDialog} sx={{ ml: 1, mt: 1, color: 'black' }}><MdEdit size={24} /></Button>
          </Box>
        </Stack>
        <Typography variant="h6" sx={{ fontFamily: "Lato" }}>
          Members
        </Typography>
        <Card className="fullWidth fullHeight" sx={{ overflowY: "auto" }}>
          <List>
            {members?.map((member) => (
              <ListItem
                key={member?.id}
                sx={{ border: "1px solid lightgray", mt: 1 }}
                secondaryAction={
                  <Chip
                    label={member?.typeOfUser}
                    color="success"
                    variant="outlined"
                  />
                }
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      src={
                        member?.photoURL ||
                        "https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-placeholder-black-png-image_3918427.jpg"
                      }
                    />
                  </ListItemAvatar>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <ListItemText primary={member?.name} />
                    
                  </Box>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Card>
      </div>
    );
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
    setInputError("");
  };

  const fetchMessages = async (showLoader = true) => {
    if (showLoader) {
      setLoading(true); // Set loading to true before fetching data
    }
    try {
      let fetchedMessages = [];
      if (typeOfUser === "DOCTOR" && !isGroupChat) {
        fetchedMessages = await fetchDoctorAndPatientMessages(
          user?.uid,
          chatId,
          user?.uid
        );
        console.log(" docter messages : ", fetchedMessages);
      } else if (typeOfUser === "PATIENT" && !isGroupChat) {
        fetchedMessages = await fetchDoctorAndPatientMessages(
          user?.uid,
          chatId,
          user?.uid
        );
      } else if (isGroupChat) {
        fetchedMessages = await fetchGroupMessages(user?.uid, chatId);
      }
      console.log(" messages form convaer 44444444444", fetchedMessages);
      if (fetchedMessages.length) {
        // Optimize sorting by using the stableSort function
        const sortedMessages = stableSort(fetchedMessages, (a, b) => {
          const dateA = b.createdDate?._seconds || b.createdDate?.seconds;
          const dateB = a.createdDate?._seconds || a.createdDate?.seconds;
          return dateB - dateA;
        });
        setMessages(sortedMessages);
        return;
      }
      // setMessages([]);
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setLoading(false); // Set loading to false after data fetching (whether successful or not)
    }
  };

  useEffect(() => {
    if (scroll.current) {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    setMessages([]);
    fetchMessages();
  }, [chatId]);

  // patientData?.find((patient) => patient?.patientId === id
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim()) {
      setInputError("Message cannot be empty");
      return;
    }
    // setLocalMessage({
    //   description: input,
    //   sentBy: user.email,
    //   userName: user.fullname,
    //   createdDate: Timestamp.now(),
    //   userPhotoURL: user?.photoURL,
    // });

    if (typeOfUser === "DOCTOR" && !isGroupChat) {
      const params = {
        title: "Healthcare Professional has sent you a message",
        description: input,
        notifyTo: chatUserInfo?.patientInfo?.email,
        sentBy: user?.email,
        userName: user?.fullname,
        userPhotoURL: user?.photoURL,
      };

      const message = {
        infoMessage: btoa(JSON.stringify(params)),
        notificationSource: "doctorMessage",
        userId: user?.uid,
        isEncrypted: false,
        readStatus: false,
        createdDate: Timestamp.now(),
      };
      const res = await addDoc(
        collection(FirestoreDB, "notifications", chatId, "messages"),
        message
      );
      setMessages([...messages, { ...message, id: res.id, ...params }]);

      toast.success("Message sent successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (typeOfUser === "PATIENT" && !isGroupChat) {
      const params = {
        title: "Patient has sent you a message",
        description: input,
        notifyTo: chatUserInfo?.email,
        sentBy: user?.email,
        userName: user?.fullname,
        userPhotoURL: user?.photoURL,
      };

      const message = {
        infoMessage: btoa(JSON.stringify(params)),
        notificationSource: "patientMessage",
        userId: user?.uid,
        isEncrypted: false,
        readStatus: false,
        createdDate: Timestamp.now(),
      };

      const res = await addDoc(
        collection(
          FirestoreDB,
          "notifications",
          chatUserInfo?.doctorId,
          "messages"
        ),
        message
      );

      setMessages([...messages, { ...message, id: res.id, ...params }]);
      toast.success("Message sent successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      const params = {
        title:
          typeOfUser === "PATIENT"
            ? "Patient has sent you a message in group"
            : "Healthcare Professional has sent you a message in group",
        description: input,
        // notifyTo: chatUserInfo?.email,
        sentBy: user?.email,
        userName: user?.fullname,
        userPhotoURL: user?.photoURL,
      };

      const readStatus = members
        ?.filter((member) => member.id !== user?.uid)
        .map((member) => ({
          id: member.id,
          status: false,
        }));

      const message = {
        infoMessage: btoa(JSON.stringify(params)),
        // infoMessage: params,
        notificationSource: "groupMessage",
        userId: user?.uid,
        isEncrypted: false,
        readStatus: readStatus,
        createdDate: Timestamp.now(),
      };

      const res = await addDoc(
        collection(FirestoreDB, "groupChats", chatId, "groupMessages"),
        message
      );
      // console.log(" res at group chat : @@@@@@@@@22", res);
      setMessages([...messages, { ...message, id: res.id, ...params }]);
      toast.success("Message sent successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    setInput("");
    setInputError("");
    // await fetchMessages(false); // Call fetchMessages without showing the loader
  };

  console.log("members : ", members);

  return (
    // <div>hi</div>
    <Card sx={{ height: "89.5vh" }}>
      <CardHeader
        avatar={<Avatar src={chatPhotoURL} aria-label="recipe" />}
        title={chatName.charAt(0).toUpperCase() + chatName.slice(1).toLowerCase()}
        subheader={
          typeOfUser === "DOCTOR" &&
          !isGroupChat && (
            <React.Fragment>
              <Typography
                sx={{ display: "inline", cursor: "pointer" }}
                component="span"
                variant="body2"
                color="text.primary"
                onClick={() => navigateProfile(chatId)}
              >
                View profile
              </Typography>
            </React.Fragment>
          )
        }
        action={
          <>
            {typeOfUser === "DOCTOR" && !isGroupChat ? (
              <>
                <Button
                  sx={{
                    backgroundColor: "var(--clr-theme-purple)!important",
                    color: "white",
                    textTransform: "capitalize",
                    width: "240px",
                  }}
                  onClick={() => setOpen(true)}
                  // goToPatient
                >
                  Set Reminder
                </Button>
                <Dialog
                  onClose={() => setOpen(false)}
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  maxWidth="lg"
                >
                  <AppBar
                    sx={{
                      position: "relative",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <Toolbar>
                      <Typography
                        sx={{
                          flex: 1,
                          color: "black",
                          fontSize: "1.8rem",
                          fontFamily: "Lato",
                        }}
                        variant="h6"
                        component="div"
                      >
                        Reminders
                      </Typography>
                      <Button
                        autoFocus
                        color="inherit"
                        onClick={() => setOpen(false)}
                      >
                        <Close sx={{ color: "black" }} />
                      </Button>
                    </Toolbar>
                  </AppBar>
                  <DialogContent sx={{ width: "42vw" }}>
                    <Reminders currentPatient={chatId} />
                  </DialogContent>
                </Dialog>
              </>
            ) : (
              isGroupChat && (
                <Button
                  variant="outlined"
                  onClick={viewGroup}
                  sx={{ textTransform: "capitalize" }}
                >
                  View Group
                </Button>
              )
            )}
          </>
        }
      />
      <Divider sx={{ border: "1px solid lightgray" }} />

      <CardContent>
        <Box
          sx={{
            height: "79vh",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }} ref={scroll}>
            {loading ? (
              <CircularProgress
                size={"5rem"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  background: "transparent !important",
                  color: "var(--clr-theme-purple) !important",
                }}
              />
            ) : (
              messages?.map((message, index) => {
                // console.log(" message at map ", message);
                return (
                  <Chatconversation
                    key={index}
                    message={message}
                    typeOfUser={typeOfUser}
                    user={user}
                  />
                );
              })
            )}
            {/* {localMessage && (
              <Chatconversation
                message={localMessage}
                typeOfUser={typeOfUser}
                user={user}
              />
            )} */}
          </Box>
          <form onSubmit={sendMessage}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Type a message..."
                    variant="outlined"
                    value={input}
                    onChange={handleInputChange}
                    error={!!inputError}
                    helperText={inputError}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--clr-theme-purple) !important",
                    }}
                    endIcon={<Send />}
                  ></Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </CardContent>

      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        maxWidth="sm" // Change this value as needed, options are 'xs', 'sm', 'md', 'lg', 'xl'
        fullWidth={true}
        sx={{ zIndex: 10000 }}
      >
        <DialogTitle sx={{ textAlign: 'center', color: '#9291f0', fontWeight: 'bold' }} variant="h5">Edit group name</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            sx={{ margin: '5px 0px' }}
            value={editedChatName}
            onChange={(e) => setEditedChatName(e.target.value)}
            label="Group name"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions sx={{ paddingRight: 3}}>
          <Button onClick={handleCancelEdit} style={{ color: '#9291f0', width: '20%' }}>Cancel</Button>
          <Button onClick={handleSaveEdit} variant="contained"  style={{ backgroundColor: '#9291f0', width: '20%' }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Conversation;

const Chatconversation = ({ message, typeOfUser, user }) => {
  if (!message.description) {
    return null;
  }

  // console.log("messagessssssssssssssssssssssssssssssss", message);

  // Determine if the message is from the doctor or the patient
  const isDoctorMessage = message?.notificationSource === "doctorMessage";
  const isPatientMessage = message?.notificationSource === "patientMessage";

  // Determine the alignment of the message content
  // const contentAlignment = isDoctorMessage ? "flex-end" : "flex-start";
  const isCurrentUserMessage =
    (typeOfUser === "DOCTOR" && message.sentBy === user.email) ||
    (typeOfUser === "PATIENT" && message.sentBy === user.email);

  // Determine the alignment of the message content
  const contentAlignment = isCurrentUserMessage ? "flex-end" : "flex-start";

  // Convert createdDate to a human-readable format
  const formattedDate = new Date(
    message?.createdDate?.seconds * 1000 ||
      message?.createdDate?._seconds * 1000
  ).toLocaleString();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isCurrentUserMessage ? "row-reverse" : "row",
        alignItems: contentAlignment,
        padding: "10px",

        marginBottom: 5,
      }}
    >
      <img
        src={
          message?.userPhotoURL ||
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
        }
        alt="User Avatar"
        style={{ width: "30px", height: "30px", borderRadius: "50%" }}
      />
      <div
        style={{
          marginLeft: "10px",
          width: "70%",
          backgroundColor: "var(--clr-theme-purple-20)",
          padding: "10px",
          borderRadius: isDoctorMessage
            ? "20px 5px 20px 20px"
            : "20px 20px 20px 5px",
        }}
      >
        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
          {message?.userName
            .split(/\s+/)
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ") || "NA"}
        </p>
        <p style={{ color: "#454545", margin: "0" }}>{message?.description}</p>
        <p
          style={{
            fontSize: "12px",
            color: "#999",
            margin: "0",
            marginTop: "5px",
          }}
        >
          {formattedDate}
        </p>
      </div>
    </div>
  );
};
