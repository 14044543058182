import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";

import { ArrowRightAlt } from "@mui/icons-material";

import AuthContext from "../../contexts/AuthContext";
import Progress from "../Progress";

import { doc, updateDoc } from "firebase/firestore";
import { FirestoreDB } from "../../utils/firebase/firebase";

import SaasAgrement from "../../pages/Updated_UI/SaasAgreement";
import TermConditionLandingpage from "../../pages/Updated_UI/TermconditionLandingpage";
import PrivacyLandingpage from "../../pages/Updated_UI/PrivacyLandingpage";

import "./Header.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const UpdatedAcceptPolicies = () => {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const { user, updateGlobalUser, typeOfUser } = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentStep(1);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const renderTitle = () => {
    switch (currentStep) {
      case 1:
        return "Mindly Care";
      case 2:
        return "Mindly Care Inc. Terms of Service";
      case 3:
        return "Mindly Care Inc. Privacy Policy";
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return <Typography>Our Terms and Policies have been revised. Kindly take a moment to review and confirm your acceptance.</Typography>;
      case 2:
        return <TermConditionLandingpage footerRequired={false} />;
      case 3:
        return <PrivacyLandingpage footerRequired={false} />;
      default:
        return null;
    }
  };

  const renderButton = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="">
            <Button size="small" onClick={nextStep}>
              View Terms{" "}
              <ArrowRightAlt sx={{ ml: 1, fontSize: "30px" }} />
            </Button>
          </div>
        );
      case 2:
        return (
          <Button size="large" onClick={nextStep}>
            Agree Terms & Conditions{" "}
            <ArrowRightAlt sx={{ ml: 1, fontSize: "30px" }} />
          </Button>
        );
      case 3:
        return (
          <Button size="large" onClick={updatedAcceptPoliciesHandler}>
            Agree Privacy & Policy
          </Button>
        );
      default:
        return null;
    }
  };

  const updatedAcceptPoliciesHandlerForPatient = async () => {
    setLoading(true);
    const userRef = doc(FirestoreDB, "patientsList", user.uid);
    const result = await updateDoc(userRef, {
      isUpdatedPoliciesAccepted: true,
      isPoliciesUpdated: false,
    });
    setLoading(false);
    handleClose();
    updateGlobalUser({
      ...user,
      isUpdatedPoliciesAccepted: true,
      isPoliciesUpdated: false,
    });
  };

  const updatedAcceptPoliciesHandlerForDoctor = async () => {
    setLoading(true);
    const userRef = doc(FirestoreDB, "doctorsList", user.uid);
    const result = await updateDoc(userRef, {
      isUpdatedPoliciesAccepted: true,
      isPoliciesUpdated: false,
    });
    setLoading(false);
    handleClose();
    updateGlobalUser({
      ...user,
      isUpdatedPoliciesAccepted: true,
      isPoliciesUpdated: false,
    });
  };
  const updatedAcceptPoliciesHandler = () => {
    if (typeOfUser === "DOCTOR") {
      updatedAcceptPoliciesHandlerForDoctor();
    } else if (typeOfUser === "PATIENT") {
      updatedAcceptPoliciesHandlerForPatient();
    } else {
      console.log("No user found");
    }
  };

  return (
    <div className="UpdatedAcceptPolicies">
      {user && user?.isPoliciesUpdated && (
        <Box className="flexBox flexCenter" sx={{ gap: 3, mb: 1 }}>
          {/* <Typography gutterBottom sx={{ fontFamily: "Lato" }}>
            Terms and Policies are updated, Please accept
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "capitalize" }}
            onClick={handleClickOpen}
          >
            Click here
          </Button> */}
          <Dialog
            className="dialog"
            open={handleClickOpen}
            TransitionComponent={Transition}
            keepMounted
            // onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            {loading && <Progress />}
            <DialogTitle
              sx={{
                fontFamily: "Lato",
                backgroundColor: "var(--clr-theme-purple)",
                color: "white",
              }}
            >
              {renderTitle()}
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText id="alert-dialog-slide-description">
                {renderContent()}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="renderBtn">
              {/* <Button onClick={handleClose} size="large">
                Disagree
              </Button> */}
              {renderButton()}
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </div>
  );
};

export default UpdatedAcceptPolicies;
