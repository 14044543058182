import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import React from "react";

const PatientCard = ({
  title,
  subheader,
  picture,
  children,
  actions,
  showAvatar,
  padding,
  textAlign,
  backgroundColor,
  color,
  customClass = "",
}) => {
  return (
    <Card className={`flexBox flexColumn ${customClass || "patient-card"}`}>
      {title && (
        <CardHeader
          sx={{
            padding: { padding },
            textAlign: { textAlign },
            backgroundColor: { backgroundColor },
            color: { color },
            fontFamily: "Lato",
          }}
          title={title}
          subheader={subheader}
          avatar={
            showAvatar && (
              <Avatar
                src={picture}
                sx={{
                  border: "2px solid white",
                }}
              >
                {title?.substring(0, 1)}
              </Avatar>
            )
          }
          // action={actions}
        />
      )}
      <CardContent>{children}</CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};

export default PatientCard;
