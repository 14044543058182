import React from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import "./SocialLinks.css";

const TwitterIcon = ({ color,size }) => {
  return (
    <div>
      <a
        href="https://twitter.com/mindly_care?s=21"
        target="_blank"
        rel="noopener noreferrer"
        className="socialIcon"
      >
        <FaSquareXTwitter size={size} color={color}/>
      </a>
    </div>
  );
};

export default TwitterIcon;
