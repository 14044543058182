import { Cancel, Delete, Medication, Psychology } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SymptomRating from "./Rating";

const Behaviour = ({
  behaviour,
  updateBehaviours,
  index,
  deleteBehaviour,
  isLoading,
  behavioursName,
}) => {
  const [isCustomBehaviour, setIsCustomBehaviour] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedBehaviorCount, setSelectedBehaviorCount] = useState(0);

  console.log(behaviour, "behaviour");

  const behaviourHandler = (e) => {
    console.log(e.target.value, "abcd");
    if (e.target.value !== "other") {
      updateBehaviours(e.target.value, "Behaviour", behaviour.id);
      setIsCustomBehaviour(false);
    } else {
      setIsCustomBehaviour(true);
    }
  };

  const incrementCount = () => {
    const incrementCount = selectedBehaviorCount + 1;
    const updatedCount = Math.min(incrementCount, 50);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, "BehaviourRating", behaviour.id);
  };

  const frequencyHandler = (e) => {
    const enteredValue = e.target.value;
    const numericValue = parseInt(enteredValue);

    // Check if the entered value is a valid number
    if (!isNaN(numericValue)) {
      const updatedValue = Math.max(0, Math.min(numericValue, 50));
      setSelectedBehaviorCount(updatedValue);
      updateBehaviours(updatedValue, "BehaviourRating", behaviour.id);
    } else {
      // Handle the case where the entered value is not a valid number
      // For example, clear the input or show an error message
      setSelectedBehaviorCount(0);
    }
  };

  const decrementCount = () => {
    const newCount = selectedBehaviorCount - 1;
    const updatedCount = Math.max(newCount, 0);
    setSelectedBehaviorCount(updatedCount);
    updateBehaviours(updatedCount, "BehaviourRating", behaviour.id);
  };

  // useEffect(() => {
  //   console.log("Updated Count:", selectedBehaviorCount);
  // }, [selectedBehaviorCount]);

  if (isLoading)
    return (
      <CircularProgress
        sx={{ position: "absolute", left: "81%", top: "30%" }}
      />
    );

  return (
    <Card className="treatmentBox" sx={{ border: "1px solid lightgray", p: 1 }}>
      <CardHeader
        action={
          <IconButton onClick={() => deleteBehaviour(behaviour?.id)}>
            <Cancel />
          </IconButton>
        }
        subheader={"Behavior information"}
      />

      <CardContent>
        <div
          className="cardContent"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
          }}
        >
          <Typography gutterBottom variant="subtitle2">
            Behavior
          </Typography>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel id="demo-simple-select-label">
              Select behaviour
            </InputLabel>
            <Select
              label="Select behavior"
              value={isCustomBehaviour ? "other" : behaviour?.behaviour}
              onChange={behaviourHandler}
            >
              {behavioursName?.length > 0 &&
                behavioursName?.map((item, i) => {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {isCustomBehaviour && (
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label={`Add behaviour `}
                onChange={(e) => {
                  updateBehaviours(e.target.value, "Behaviour", behaviour.id);
                }}
              />
            )}
            <Box className="flexBox flexColumn" sx={{ gap: 2 }}>
              <Box
                className="flexBox flexCenter"
                sx={{ justifyContent: "space-between", mt: 2 }}
              >
                <Typography variant="subtitle2" fontWeight={"bold"}>
                  {/* Selected Behavior:{" "}
                  {isCustomBehaviour ? "Custom Behavior" : behaviour?.behaviour} */}
                  Frequency count
                </Typography>
                <Box className="flexBox flexCenter" sx={{ gap: 2 }}>
                  <Fab
                    onClick={decrementCount}
                    size="small"
                    sx={{
                      backgroundColor: "var(--clr-theme-purple)!important",
                      fontSize: "2rem",
                      color: "var(--clr-theme-white)",
                    }}
                  >
                    -
                  </Fab>
                  {/* <Typography>{selectedBehaviorCount}</Typography> */}
                  <TextField
                    type="tel"
                    inputProps={{
                      pattern: "[0-50]*", // Allows only numeric input
                      inputMode: "numeric", // Specifies a numeric keyboard
                    }}
                    sx={{ width: "55px" }}
                    value={selectedBehaviorCount}
                    onChange={frequencyHandler}
                  />

                  <Fab
                    onClick={incrementCount}
                    size="small"
                    sx={{
                      backgroundColor: "var(--clr-theme-purple) !important",
                      fontSize: "1.5rem",
                      color: "var(--clr-theme-white)",
                    }}
                  >
                    +
                  </Fab>
                </Box>
              </Box>

              <TextField
                label="Antecedents"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => {
                  updateBehaviours(e.target.value, "Antecedents", behaviour.id);
                }}
              />
              <TextField
                label="Consequences"
                multiline
                rows={4}
                fullWidth
                onChange={(e) => {
                  updateBehaviours(
                    e.target.value,
                    "Consequences",
                    behaviour.id
                  );
                }}
              />
            </Box>
          </FormControl>
        </div>
      </CardContent>
    </Card>
  );
};

export default Behaviour;
