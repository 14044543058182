import {
  AddAPhoto,
  Email,
  Event,
  FitnessCenter,
  Home,
  Image,
  MedicalInformation,
  Person,
  Phone,
  AppRegistration,
  Info,
  LocalHospital,
  Add,
  Chat,
} from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Stack } from "@mui/system";
import { doc, updateDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { UploadFileToStorage } from "../../utils/firebase/helper";
import React, { useState, useEffect, useContext } from "react";
import { FirebaseStorage, FirestoreDB } from "../../utils/firebase/firebase";

import { toast } from "react-toastify";

import CustomButton from "../../components/CustomButton";

import "react-toastify/dist/ReactToastify.css";
import "./PatientEditInformation.css";
import { updatePatientProfileDetails } from "../../api";
import AuthContext from "../../contexts/AuthContext";
import Progress from "../Progress";
import UpdateTreatments from "../UpdateTreatments";
import Chatconversation from "../../components/Chat";
import { useNavigate } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function PatientEditInformation({ userDetails }) {
  const [patient, setPatient] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [profilePicURL, setProfilePicUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  const { setOpenRightPanel, setPanelContent, user, updateGlobalUser } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const UpdateTreatmentHandler = () => {
    setPanelContent(<UpdateTreatments patientID={user?.uid} />);
    setOpenRightPanel(true);
  };

  const ChatconversationHandler = (doctor) => {
    setPanelContent(<Chatconversation patient={user?.uid} doctor={doctor} />);
    setOpenRightPanel(true);
  };
  const navigateToChatScreen = async (doctorDetails) => {
    console.log(doctorDetails, "at navigate");
    navigate("/message", {
      state: { doctorId: doctorDetails?.doctorId },
    });
  };

  console.log("userrrrrrrrrrrrrrr", user);

  useEffect(() => {
    setPatient(user);
  }, []);

  const ChangeHandler = () => {
    setEditOpen(true);
  };

  const validateInputs = () => {
    let isValid = true;

    if (!patient?.email.trim()) {
      setEmail("Email is required.");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(patient.email.trim())) {
      setEmail("Invalid email format.");
      isValid = false;
    } else {
      setEmail("");
    }
    const areaCodeRegex = /^\d{1,3}$/;
    const numberRegex = /^\d{10}$/;
    if (!areaCodeRegex.test(patient?.contactNo?.areaCode)) {
      setContact("Area code max length is 3-digits.");
      isValid = false;
    } else if (!numberRegex.test(patient?.contactNo?.number)) {
      setContact("Phone number must have 10-digits.");
      isValid = false;
    } else if (
      !areaCodeRegex.test(patient?.contactNo?.areaCode) &&
      !numberRegex.test(patient?.contactNo?.number)
    ) {
      setContact("Invalid contact number format.");
      isValid = false;
    } else {
      setContact("");
    }

    return isValid;
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file?.size <= 10048576) {
      // Greater than 10MB
      setProfilePicFile(file);
    }
    let url = "";

    if (file) {
      let blob = new Blob([file], {
        type: file.type,
      });
      const profileStorageRef = ref(
        FirebaseStorage,
        `profiles/${user?.uid}/${file.name}`
      );

      const result = await UploadFileToStorage(profileStorageRef, blob);

      if (result.status) {
        url = result.data;

        url && setPatient({ ...patient, photoURL: url });
        setProfilePicUrl(url);
      }
    }
  };

  const showToastMessage = () => {
    toast.success("Profile edited successfully!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const UpdatePatientEditChanges = async () => {
    // const patientRef = doc(FirestoreDB, "patientsList", userDetails.uid);

    if (!validateInputs()) {
      return;
    }

    setIsLoading(true);

    const params = {
      // email: patient?.email || "",
      contactNo: patient?.contactNo || { areaCode: "00", number: "000000000" },
      photoURL: patient?.photoURL || null,
      currentWeight: patient?.currentWeight || 0,
      birthWeight: patient?.birthWeight || 0,
      parentName: patient?.parentName || 0,
      fullname: patient?.fullname || 0,
      dob: patient?.dob || 0,
      gender: patient?.gender || 0,
    };

    const result = await updatePatientProfileDetails(
      btoa(JSON.stringify(params)),
      user.uid
    );
    if (result) {
      updateGlobalUser({ ...user, ...patient });
    }
    setIsLoading(false);
    setEditOpen(false);
    showToastMessage();
  };

  const validateString = (str) => {
    return str?.toString().trim() || "-";
  };

  const ClearChanges = () => {
    setPatient(user);
    setProfilePicFile(null);
    setEditOpen(false);
  };

  return (
    <>
      {editOpen && (
        <Box
          sx={{
            paddingLeft: { xs: "10px", sm: "40px", md: "60px" },
            paddingRight: { xs: "10px", sm: "40px", md: "60px" },
            paddingBottom: "25px",
            // width: "100%",
          }}
        >
          <Stack alignItems="center" sx={{ position: "relative", pb: "25px" }}>
            <Box
              sx={{
                border: 3,
                borderColor: "var(--clr-theme-purple)",
                borderRadius: 20,
              }}
            >
              <Avatar
                className="profile-avatar"
                alt="upload picture"
                sx={{ width: 150, height: 150 }}
                src={profilePicURL ? profilePicURL : patient?.photoURL}
              >
                <Image />
              </Avatar>
            </Box>

            <Tooltip title="Max size of 10MB">
              <IconButton
                className="profile-upload-icon"
                color="secondary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}
                  disabled={isLoading}
                />
                <AddAPhoto />
              </IconButton>
            </Tooltip>

            {/* <Typography
              variant="h4"
              sx={{
                color: "var(--clr-theme-purple)",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {validateString(patient?.fullname)?.charAt(0).toUpperCase() +
                validateString(patient?.fullname)?.slice(1).toLowerCase()}
            </Typography> */}

            <TextField
              label="Full name"
              value={patient?.fullname}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  fullname: e.target.value,
                });
              }}
              disabled={isLoading}
            />
          </Stack>
          <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Parent Name:</Typography>
                <TextField
                  fullWidth
                  label="Parent Name"
                  sx={{ width: "100%" }}
                  value={patient?.parentName}
                  onChange={(e) => {
                    setPatient({
                      ...patient,
                      parentName: e.target.value.trim(),
                    });
                  }}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Gender:</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    value={patient?.gender || ""}
                    onChange={(e) => {
                      setPatient({
                        ...patient,
                        gender: e.target.value.trim(),
                      });
                    }}
                  >
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {/* <Box>
                  <Typography gutterBottom>Email:</Typography>
                  <TextField
                    fullWidth
                    label="email"
                    sx={{ m: 1, width: "100%" }}
                    value={patient?.email}
                    error={!!email}
                    helperText={email}
                    onChange={(e) => {
                      setPatient({ ...patient, email: e.target.value.trim() });
                    }}
                    disabled={isLoading}
                  />
                </Box> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Contact Number:</Typography>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      textTransform: "initial",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Area code"
                      sx={{ m: 1, width: "30%" }}
                      type={"phone"}
                      value={patient?.contactNo?.areaCode}
                      onChange={(e) => {
                        setPatient({
                          ...patient,
                          contactNo: {
                            ...patient.contactNo,
                            areaCode: e.target.value.trim(),
                          },
                        });
                      }}
                      disabled={isLoading}
                    />
                    <TextField
                      fullWidth
                      label="Number"
                      type={"phone"}
                      sx={{ m: 1, width: "70%" }}
                      value={patient?.contactNo?.number}
                      onChange={(e) => {
                        setPatient({
                          ...patient,
                          contactNo: {
                            ...patient.contactNo,
                            number: e.target.value.trim(),
                          },
                        });
                      }}
                      disabled={isLoading}
                    />
                  </div>
                  <Typography
                    className="errorMessage"
                    variant="caption"
                    sx={{
                      paddingLeft: "18px",
                      textTransform: "none",
                      color: "#d32f2f",
                    }}
                  >
                    {contact}
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexWrap: "wrap" }}>
              <Box>
                <Typography gutterBottom>Date of birth:</Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Date of Birth"
                    value={patient?.dob || null}
                    onChange={(newValue) => {
                      setPatient({
                        ...patient,
                        dob: newValue._d,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ width: "93%" }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap", textTransform: 'initial' }}>
              <Box>
                <Typography gutterBottom>Current weight:</Typography>
                <TextField
                  fullWidth
                  type={"number"}
                  label="Current weight"
                  sx={{ m: 1, width: "100%" }}
                  value={patient?.currentWeight}
                  onChange={(e) => {
                    setPatient({
                      ...patient,
                      currentWeight: e.target.value.trim(),
                    });
                  }}
                />
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      )}

      {!editOpen && (
        <Box className="DoctorEdit">
          <Stack alignItems="center" sx={{ position: "relative" }}>
            <Box
              sx={{
                textAlign: "center",
                border: 3,
                borderColor: "var(--clr-theme-purple)",
                borderRadius: 20,
              }}
            >
              <Avatar
                alt="Remy Sharp"
                src={patient?.photoURL}
                sx={{
                  width: 150,
                  height: 150,
                }}
              />
            </Box>
            <Typography
              variant="h4"
              sx={{
                color: "var(--clr-theme-purple)",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {validateString(patient?.fullname)?.charAt(0).toUpperCase() +
                validateString(patient?.fullname)?.slice(1).toLowerCase()}
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>

                  <ListItemText
                    primary={validateString(patient?.parentName)}
                    secondary="Parent Name"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>

                  <ListItemText
                    primary={validateString(patient?.parentName)}
                    secondary="Parent Name"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ wordBreak: "break-word" }}
                    primary={validateString(patient?.email)}
                    secondary="Email"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    primary={validateString(patient?.gender)}
                    secondary="Gender"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Phone />
                  </ListItemIcon>
                  <ListItemText
                    primary={`+${patient?.contactNo?.areaCode || "00"}-${
                      patient?.contactNo?.number || "00000000"
                    }`}
                    secondary="Contact No."
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>

                  <ListItemText
                    primary={validateString(patient?.parentName)}
                    secondary="Parent Name"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Email />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      patient?.dob &&
                      validateString(
                        new Date(
                          patient?.dob.seconds * 1000 || patient?.dob
                        ).toLocaleDateString()
                      )
                    }
                    secondary="Date of birth"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    primary={validateString(patient?.gender)}
                    secondary="Gender"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Phone />
                  </ListItemIcon>
                  <ListItemText
                    primary={`+${patient?.contactNo?.areaCode || "00"}-${
                      patient?.contactNo?.number || "00000000"
                    }`}
                    secondary="Contact No."
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Event />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      patient?.dob &&
                      validateString(
                        new Date(
                          patient?.dob.seconds * 1000 || patient?.dob
                        ).toLocaleDateString()
                      )
                    }
                    secondary="Date of birth"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox ">
                <ListItem>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    primary={validateString(
                      (() => {
                        const dobInMillis = patient?.user?.dob?.seconds
                          ? patient.user.dob.seconds * 1000
                          : new Date(patient?.dob).getTime();
                        const ageInMillis = new Date() - new Date(dobInMillis);
                        const ageInYears =
                          ageInMillis / (1000 * 3600 * 24 * 365);
                        return ageInYears < 1 ? 0 : Math.floor(ageInYears);
                      })()
                    )}
                    secondary="Age"
                  />
                </ListItem>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox">
                <ListItem>
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText
                    primary={validateString(
                      patient?.patient?.address &&
                        Object.values(patient?.address)
                          .filter((x) => x)
                          .join(", ")
                    )}
                    secondary="Address"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox">
                <ListItem>
                  <ListItemIcon>
                    <MedicalInformation />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${validateString(patient?.diagnosis)}`}
                    secondary="Diagnosis"
                  />
                </ListItem>
              </Box>
            </Grid>
            <Divider />

            {/* <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox">
                <ListItem>
                  <ListItemIcon>
                    <FitnessCenter />
                  </ListItemIcon>

                  <ListItemText
                    primary={validateString(patient?.currentWeight)}
                    secondary="Current weight"
                  />
                </ListItem>
              </Box>
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap" }}>
              <Box className="flexBox">
                <ListItem>
                  <ListItemIcon>
                    <AppRegistration />
                  </ListItemIcon>
                  <ListItemText
                    primary={validateString(patient?.doctorRegistrationID)}
                    secondary="doctorRegistrationID"
                  />
                </ListItem>
              </Box>
            </Grid> */}
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ flexWrap: "wrap", mb: 2 }}>
            <List
              sx={{
                width: "85%",
                maxWidth: 360,
              }}
            >
              <Box
                className="flexBox"
                ml={2}
                sx={{ gap: 4, alignItems: "center" }}
              >
                <LocalHospital sx={{ color: "var(--clr-theme-purple)" }} />
                <Typography
                  variant="subtitle2"
                  color={"rgba(0, 0, 0, 0.6)"}
                  gutterBottom
                  // ml={2}
                >
                  Healthcare Professional
                </Typography>
              </Box>             
              {patient?.doctorDetails?.map((doc, i) => {
                if (doc?.fullname) {
                return (
                  <ListItem
                    alignItems="center"
                    key={i}
                    sx={{
                      backgroundColor: "var(--clr-theme-purple-20)",
                      mb: 1,
                      color: "#454545",
                      borderRadius: 6,
                      ml: 2,
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={doc?.photoURL} />
                    </ListItemAvatar>
                    <ListItemText primary={doc?.fullname} />
                    <IconButton onClick={() => navigateToChatScreen(doc)}>
                      <Chat />
                    </IconButton>
                  </ListItem>
                )
              } else{
                return null
              }
              })}
            </List>
            {/* <Card className="flexBox">
                <ListItem>
                  <ListItemIcon>
                    <Info />
                  </ListItemIcon>
                  <ListItemText
                    primary={patient?.doctorDetails?.map((doc, i) => {
                      return (
                        <Box
                          key={i}
                          className="flexBox"
                          sx={{
                            mt: 2,
                            alignItems: "center",
                            border: "1px solid lightgray",
                            p: 1,
                            borderRadius: 7,
                            backgroundColor: "var(--clr-theme-purple)",
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={doc?.photoURL} />
                          </ListItemAvatar>
                          <Typography
                            color={"white"}
                            sx={{ fontFamily: "Lato" }}
                          >
                            {doc?.fullname}
                          </Typography>
                        </Box>
                      );
                    })}
                    secondary={
                      <Typography mt={1} ml={1} color={"gray"}>
                        Healthcare Professional
                      </Typography>
                    }
                  />
                </ListItem>
              </Card> */}
          </Grid>
        </Box>
      )}
      <Stack flexDirection="row" justifyContent="center" gap={5}>
        <Box sx={{ width: "100%" }}>
          {!editOpen ? (
            <Box className="flexBox flexColumn" gap={1}>
              <CustomButton
                variant="extended"
                color="primary"
                ChangeHandler={UpdateTreatmentHandler}
              >
                View / Add Diagnosis
              </CustomButton>
              <CustomButton
                variant="extended"
                color="primary"
                ChangeHandler={ChangeHandler}
              >
                Edit
              </CustomButton>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                flexWrap: "wrap",
                minWidth: "250px",
                justifyContent: "center ",
                gap: 5,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "20%",
                  minWidth: "250px",
                  fontFamily: "Lato",
                  textTransform: "capitalize",
                }}
                onClick={ClearChanges}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  backgroundColor: "var(--clr-theme-purple) !important",
                  width: "20%",
                  minWidth: "250px",
                  fontFamily: "Lato",
                  textTransform: "capitalize",
                  "&:disabled": {
                    color: "var(--clr-theme-white) !important",
                    backgroundColor: "rgb(211, 211, 211) !important", // You can adjust this styling as needed
                  },
                }}
                onClick={UpdatePatientEditChanges}
                disabled={isLoading}
              >
                Update
              </Button>
            </Box>
          )}
        </Box>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Stack>
    </>
  );
}

export default PatientEditInformation;
