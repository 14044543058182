import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import AuthContext from "../contexts/AuthContext";
import DataProvider from "../contexts/DataProvider";
import { FirebaseAuth } from "../utils/firebase/firebase";

const PrivateRoute = ({ children }) => {
  const { isUserAuthenticated, login, logout } = useContext(AuthContext);
  const [initialNavigation, setInitialNavigation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialNavigation) {
      navigate("/");
      setInitialNavigation(true);
    }
  }, [initialNavigation, navigate]);

  const validateSession = () => {
    const itemStr = localStorage.getItem("session");
    if (!itemStr) {
      return false;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem("session");
      logout();
      return false;
    }
    return true;
  };
  return isUserAuthenticated ||
    FirebaseAuth.currentUser ||
    validateSession() ? (
    <>
      <DataProvider>{children}</DataProvider>
    </>
  ) : (
    <>
      <Navigate to={"/main"} />
    </>
  );
};

export default PrivateRoute;
