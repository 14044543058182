import { useMediaQuery } from "@mui/material";
import React, { createContext, useState } from "react";
import { LeftMenu, LeftMenu_BottomSection } from "../constants/LeftMenu";

export const UIContext = createContext();

const UIProvider = ({ children }) => {
  const LeftMenuItems = [...LeftMenu, ...LeftMenu_BottomSection];

  const [selectedMenuKey, setSelectedMenuKey] = useState(LeftMenuItems[0].key);
  const [selectedPatientId, setSelectedPatientId] = useState(-1);
  const [bottomDrawer, setBottomDrawer] = useState({
    show: false,
    title: "",
    content: null,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewMesage, setIsNewMessage] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width: 800px)");
  const drawerVariant = isSmallScreen
    ? "temporary"
    : isDrawerOpen
    ? "persistent"
    : "permanent";

  const updateSelectedMenu = (key) => {
    setSelectedMenuKey(key);
    selectPatient(-1, true);
    // localStorage.setItem('selectedMenuKey', key);
    // localStorage.setItem('selectedPatientId', -1)
  };

  const selectPatient = (id, reset) => {
    setSelectedPatientId(id);
    // localStorage.setItem('selectedMenuKey', 'Patient List')
    // localStorage.setItem('selectedPatientId', id)
    !reset && setSelectedMenuKey(-1);
  };

  return (
    <UIContext.Provider
      value={{
        selectedMenuKey,
        setSelectedMenuKey,
        updateSelectedMenu,
        selectedPatientId,
        selectPatient,
        bottomDrawer,
        setBottomDrawer,
        isDrawerOpen,
        setIsDrawerOpen,
        drawerVariant,
        isSmallScreen,
        isNewMesage,
        setIsNewMessage,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
