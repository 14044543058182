import React from "react";

import LoginLayout from "../../hoc/LoginLayout";
import BrandLogo from "../BrandLogo";
import Progress from "../Progress";

const FallbackScreen = () => {
  return (
    <LoginLayout>
      <BrandLogo />
      <Progress />
    </LoginLayout>
  );
};

export default FallbackScreen;
