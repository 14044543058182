import React from "react";

import { Typography } from "@mui/material";
import { Public } from "@mui/icons-material";

import BackgroundImage from "../../../assets/images/camila.jpg";
import USImage from "../../../assets/images/us_bg.png";
import GlobeImage from "../../../assets/images/globe.png";
import StatsView from "./StatsView";

const Stats = () => {
  return (
    <div
      style={{
        padding: "4em",
        flex: 0.3,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundPositionY: "20%",
      }}
    >
      <div
        className="flexBox flexWrap flexCenter"
        style={{
          justifyContent: "space-around",
          textAlign: "center",
          gap: "2rem",
        }}
      >
        <StatsView title={"53 Million"} image={USImage}>
          Neurodivergent
          <br />
          individuals in the US
        </StatsView>
        <StatsView title={"1 Billion+"} image={GlobeImage}>
          Neurodivergent
          <br />
          individuals Worldwide
        </StatsView>
        <StatsView title={"$800 Billion"} image={USImage}>
          Annual spend on
          <br />
          Neurological Disorders in the US
        </StatsView>
        <StatsView title={"$3 Trillion"} image={GlobeImage}>
          Annual spend on
          <br />
          Neurological Disorders Worldwide
        </StatsView>
      </div>
    </div>
  );
};

export default Stats;
