import { Box, Typography } from "@mui/material";
import React from "react";

const StatsView = ({ image, title, children }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <img src={image} height={"60px"} />
      <Typography variant="h3" sx={{ color: "var(--clr-theme-white)" }}>
        {title}
      </Typography>
      <Typography
        color={"var(--clr-theme-white)"}
        variant="h6"
        className="clipText"
        sx={{ lineHeight: "25px" }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default StatsView;
