import React from "react";
import { Box} from "@mui/system";
import { Typography } from "@mui/material";

const index = () => {
  return (
    <Box>
      <Typography>
      Payment Method 
      </Typography>
    </Box>
  );
};

export default index;
