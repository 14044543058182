import React, { useEffect } from "react";
import { AppBar, Box, Button, Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText, Toolbar, Typography } from "@mui/material";
import NewLogo from "../../../assets/images/NewLogo.png"
import LogoWhite from "../../../assets/images/LogoWhite.png"
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import FacebookIcon from "../SocialLinks/Facebook";
import TwitterIcon from "../SocialLinks/Twitter";
import LinkedInIcon from "../SocialLinks/LinkedIn";
import Footer from "../Footer";
import "./Term.css";
import { useNavigate } from "react-router-dom";

const TermConditionLandingpage = () => {

  const [colorChange, setColorChange] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState("Home");
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const mobileMenuId = "primary-search-account-menu-mobile";

  const getCurrentYear = () => new Date().getFullYear();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleFootermenuclick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handlePrivacy = () => {
    navigate('/privacy-policy');
  }

  const handleTermsConditions = () => {
    navigate('/terms-and-conditions');
  }

  const handleAbout = () => {
    navigate('/main');
  }

  useEffect(() => {
    const adjustWidth = () => {
      const rootElement = document.getElementById("root"); 
      if (rootElement) {
        rootElement.style.overflowX = "hidden"; 
      }
    };

    adjustWidth();
    window.addEventListener("resize", adjustWidth); 

    return () => {
      window.removeEventListener("resize", adjustWidth); 
    };
  }, []);

  return (
    <Box className="terms">
        <AppBar position="sticky" sx={{ bgcolor: "var(--clr-theme-purple)" }}>
          <Toolbar>
            <ListItemAvatar
              sx={{
                display: { xs: "flex", md: "flex" },
                mx: 1,
                cursor: "pointer",
              }}
              onClick={handleAbout}

            >
              {colorChange ? (<img src={NewLogo} height="50px" />) : (<img src={LogoWhite} height="50px" />)}
            </ListItemAvatar>
            <Typography
              className="brand"
              variant="h6"
              noWrap
              sx={{
                letterSpacing: ".1rem",
                color: colorChange ? "var(--clr-theme-purple)" : "inherit",
                fontSize: "24px !important",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              Mindly
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {/* <Box sx={{ display: { xs: "none", md: "flex", gap: 25 } }}>
              <Button
                variant="text"
                color={colorChange ? "secondary" : "inherit"}
                sx={{  color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
                onClick={() => {
                  setCurrentPage(<HomePage />);
                  handleButtonClick("Home");
                  handleMenuClose();
                }}
                className={activeButton === "Home" ? "active" : "notActive"}
              >
                Home
              </Button>
              <Button
                variant="text"
                color={colorChange ? "secondary" : "inherit"}
                sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
                onClick={
                  () => {
                  setCurrentPage(<OurStory />);
                  handleButtonClick("about");
                  handleMenuClose();
                }
              }
                className={activeButton === "about" ? "active" : "notActive"}
              >
                About
              </Button>
              <Button
              variant="text"
              color={colorChange ? "secondary" : "inherit"}
              sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
              onClick={() => {
                setCurrentPage(<OurTeam />);
                handleButtonClick("team")
                handleMenuClose();
              }}
              className={activeButton === "team" ? "active" : "notActive"}
              >
                Team
              </Button>
              <Button
              variant="text"
              color={colorChange ? "secondary" : "inherit"}
              sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
              onClick={() => {
                setCurrentPage(<Contact />);
                handleButtonClick("Contact");
                handleMenuClose();
              }}
              className={activeButton === "Contact" ? "active" : "notActive"}
              >
                Contact
              </Button>
            </Box> */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                sx={{ color: colorChange ? "var(--clr-theme-purple)" : "inherit" }}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu} */}
      <Box
        sx={{
          maxWidth: "90%",
          margin: "20px auto",
          padding: 2,
          overflow: "auto",
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Terms of Service
          </Typography>
          <p>Last Modified: March [13th], 2024</p>
          <Typography variant="h6">Acceptance of the Terms of Use</Typography>
          <Typography paragraph>
            These website terms of use are entered into by and between you and
            Mindly Care Inc. ("Company," "we," or "us"). The following terms and
            conditions, together with any documents they expressly incorporate
            by reference (collectively, "Terms of Use"), govern your access to
            and use of https://mindly.care/ (the "Website")
            <br />
            We may offer certain content, functionality, and services
            (“Services”) on or through the Website. The Services are subject to
            additional and separate terms and conditions, which you must agree
            to in order to access the Services.
            <br />
            Please read these Terms of Use carefully before you start to use the
            Website.
            <b>
              {" "}
              By using the Website, you accept and agree to be bound and abide
              by these Terms of Use and our Privacy Policy, found at
              https://mindly.care/main, incorporated herein by reference
            </b>
            . If you do not want to agree to these Terms of Use or the Privacy
            Policy, you must not access or use the Website.
            <br />
            The Website is not for persons under the age of 18. If you are under
            18 years of age, then please do not use the Site.By using the
            Website, you represent that you are at least 18 years of age.
          </Typography>
          <Typography variant="h6"> Changes to the Terms of Use </Typography>
          <Typography paragraph>
            We may revise and update these Terms of Use from time to time in our
            sole discretion. All changes are effective immediately when we post
            them, and apply to all access to and use of the Website thereafter.
            However, any changes to the dispute resolution provisions set out in
            the “Governing Law and Jurisdiction” section will not apply to any
            disputes for which the parties have actual notice on or before the
            date the change is posted on the Website
            <br />
            Your continued use of the Website following the posting of revised
            Terms of Use means that you accept and agree to the changes. You are
            expected to check this page from time to time so you are aware of
            any changes, as they are binding on you.
          </Typography>
          <Typography variant="h6">Accessing the Website</Typography>
          <Typography paragraph>
            We reserve the right to withdraw or amend this Website, and any
            service or material we provide on the Website, in our sole
            discretion without notice. We will not be liable if for any reason
            all or any part of the Website is unavailable at any time or for any
            period. From time to time, we may restrict access to some parts of
            the Website, or the entire Website, to users.
            <br />
            You are responsible for both:
            <ul style={{ listStyleType: "disc", paddingLeft: "30px" }}>
              <li>
                Making all arrangements necessary for you to have access to the
                Website.
              </li>
              <li>
                Ensuring that all persons who access the Website through your
                internet connection are aware of these Terms of Use and comply
                with them.
              </li>
            </ul>
          </Typography>

          <Typography variant="h6"> Intellectual Property Rights</Typography>
          <Typography paragraph>
            As between you and the Company, the Company owns all worldwide
            right, title and interest, including all intellectual property and
            other proprietary rights,in and to the Website, all content
            available in connection therewith and all usage and other data
            generated or collected in connection with the use thereof
            (collectively, the “Company Materials”).Except for as expressly set
            forth herein, you agree not to license, distribute, copy, modify,
            publicly perform or display, transmit, publish, edit, adapt, create
            derivative works from, or otherwise make any unauthorized use of the
            Company Materials. You agree not to reverse engineer, decompile,
            disassemble or otherwise attempt to discover the source code,
            algorithm or programs underlying the Company Materials.
          </Typography>
          <Typography variant="h6"> Trademarks </Typography>
          <Typography paragraph>
            The Company name, the terms, and all related names, logos, product
            and service names, designs, and slogans are trademarks of the
            Company or its affiliates or licensors. You must not use such marks
            without the prior written permission of the Company. All other
            names, logos, product and service names, designs, and slogans on
            this Website are the trademarks of their respective owners.
          </Typography>
          <Typography variant="h6"> Prohibited Uses</Typography>
          <Typography paragraph>
            You may use the Website only for lawful purposes and in accordance
            with these Terms of Use. You agree not to use the Website: in any
            way that violates any applicable federal, state, local, or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the US or
            other countries); for the purpose of exploiting, harming, or
            attempting to exploit or harm minors in any way by exposing them to
            inappropriate content, asking for personally identifiable
            information, or otherwise; to send, knowingly receive, upload,
            download, use, or re-use any material that does not comply with any
            other provision in this Terms of Use; to transmit, or procure the
            sending of, any advertising or promotional material without our
            prior written consent, including any "junk mail," "chain letter,"
            "spam," or any other similar solicitation; to impersonate or attempt
            to impersonate the Company, a Company employee, another user, or any
            other person or entity (including, without limitation, by using
            email addresses or screen names associated with any of the
            foregoing); or to engage in any other conduct that restricts or
            inhibits anyone's use or enjoyment of the Website, or which, as
            determined by us, may harm the Company or users of the Website, or
            expose them to liability.
            <br />
            Additionally, you agree not to: use the Website in any manner that
            could disable, overburden, damage, or impair the site or interfere
            with any other party's use of the Website, including their ability
            to engage in real time activities through the Website; use any
            robot, spider, or other automatic device, process, or means to
            access the Website for any purpose, including monitoring or copying
            any of the material on the Website; use any manual process to
            monitor or copy any of the material on the Website, or for any other
            purpose not expressly authorized in these Terms of Use, without our
            prior written consent; use any device, software, or routine that
            interferes with the proper working of the Website; introduce any
            viruses, Trojan horses, worms, logic bombs, or other material that
            is malicious or technologically harmful; Attempt to gain
            unauthorized access to, interfere with, damage, or disrupt any parts
            of the Website, the server on which the Website is stored, or any
            server, computer, or database connected to the Website.
          </Typography>
          <Typography variant="h6">Termination</Typography>
          <Typography paragraph>
            You may terminate this Agreement by ceasing to use the Website at
            any time. The Company may also in its sole discretion and at any
            time discontinue providing the Site, or any part thereof, with or
            without notice. You agree that the Company shall not be liable to
            you or any third-party for any such termination. The following
            provisions shall survive the termination of these Terms and shall
            apply indefinitely: Intellectual Property Rights, Trademarks,
            Disclaimer of Warranties, Limitation of Liability, Indemnification,
            Governing Law and Jurisdiction, Arbitration, Waiver and Severability
            and Entire Agreement.
          </Typography>
          <Typography variant="h6"> Reliance on Information Posted</Typography>
          <Typography paragraph>
            The information presented on or through the Website is made
            available solely for general information purposes. We do not warrant
            the accuracy, completeness, or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk.
            We disclaim all liability and responsibility arising from any
            reliance placed on such materials by you or any other visitor to the
            Website, or by anyone who may be informed of any of its contents.
            <br />
            This Website may include content provided by third parties,
            including materials provided by other users, bloggers, and
            third-party licensors, syndicators, aggregators, and/or reporting
            services. All statements and/or opinions expressed in these
            materials, and all articles and responses to questions and other
            content, other than the content provided by the Company, are solely
            the opinions and the responsibility of the person or entity
            providing those materials. These materials do not necessarily
            reflect the opinion of the Company. We are not responsible, or
            liable to you or any third party, for the content or accuracy of any
            materials provided by any third parties.
            <br />
            We may update the content on this Website from time to time, but its
            content is not necessarily complete or up-to-date. Any of the
            material on the Website may be out of date at any given time, and we
            are under no obligation to update such material.
          </Typography>
          <Typography variant="h6">
            Information About You and Your Visits to the Website
          </Typography>
          <Typography paragraph>
            All information we collect on this Website is subject to our Privacy
            Policy [https://mindly.care/main]. By using the Website, you consent
            to all actions taken by us with respect to your information in
            compliance with the Privacy Policy.
            <br />
            By using this Website you are consenting to the use of cookies which
            allow a server to recall previous requests or registration and/or IP
            addresses to analyze website use patterns. You can set your browser
            to notify you before you receive a cookie, giving you the chance to
            decide whether to accept it. You can also set your browser to turn
            off cookies. If you do, however, some areas of the Website may not
            function adequately.
          </Typography>
          <Typography variant="h6">Links from the Website </Typography>
          <Typography paragraph>
            If the Website contains links to other sites and resources provided
            by third parties, these links are provided for your convenience
            only. This includes links contained in advertisements, including
            banner advertisements and sponsored links. We have no control over
            the contents of those sites or resources, and accept no
            responsibility for them or for any loss or damage that may arise
            from your use of them. If you decide to access any of the
            third-party websites linked to this Website, you do so entirely at
            your own risk and subject to the terms and conditions of use for
            such websites.
          </Typography>
          <Typography variant="h6">Disclaimer of Warranties</Typography>
          <Typography paragraph>
            THE WEBSITE AND ANY CONTENT, INFORMATION OR OTHER MATERIALS MADE
            AVAILABLE IN CONJUNCTION WITH OR THROUGH THE WEBSITE ARE PROVIDED
            “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR
            IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
            LAW, THE COMPANY AND ITS LICENSORS, SERVICE PROVIDERS AND PARTNERS
            DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. THE
            COMPANY AND ITS LICENSORS, SERVICE PROVIDERS AND PARTNERS DO NOT
            WARRANT THAT THE FEATURES AND FUNCTIONALITY OF THE WEBSITE WILL BE
            UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
            THE WEBSITE OR THE SERVERS THAT MAKE AVAILABLE THE FEATURES AND
            FUNCTIONALITY THEREOF ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
            WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE FOREGOING
            DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND
            YOU MIGHT HAVE ADDITIONAL RIGHTS.
          </Typography>
          <Typography variant="h6">Limitation on Liability</Typography>
          <Typography paragraph>
            UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
            SHALL THE COMPANY OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES,
            OFFICERS, DIRECTORS, AGENTS, OR THIRD PARTY PARTNERS, LICENSORS OR
            SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT,
            INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES THAT ARISE OUT OF OR
            RELATE TO THE WEBSITE, INCLUDING YOUR USE THEREOF, OR ANY OTHER
            INTERACTIONS WITH THE COMPANY, EVEN IF THE COMPANY OR A COMPANY
            AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR
            EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU, IN WHICH
            CASE THE COMPANY’S LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED
            BY LAW. IN NO EVENT SHALL THE TOTAL LIABILITY OF COMPANY OR ITS
            AFFILIATES, CONTRACTORS, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, OR
            THIRD-PARTY PARTNERS, LICENSORS OR SERVICE PROVIDERS TO YOU FOR ALL
            DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO
            THIS AGREEMENT OR YOUR USE OF THE WEBSITE EXCEED ONE HUNDRED ($100)
            U.S. DOLLARS.
          </Typography>
          <Typography variant="h6">Indemnification</Typography>
          <Typography paragraph>
            You agree to defend, indemnify, and hold harmless the Company, its
            affiliates, licensors, and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors, and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses, or fees (including reasonable attorneys' fees) arising out
            of or relating to your violation of these Terms of Use or your use
            of the Website, including, but not limited to, any use of the
            Website's content, services, and products other than as expressly
            authorized in these Terms of Use, or your use of any information
            obtained from the Website.
          </Typography>
          <Typography variant="h6">Governing Law and Jurisdiction</Typography>
          <Typography paragraph>
            All matters relating to the Website and these Terms of Use, and any
            dispute or claim arising therefrom or related thereto (in each case,
            including non-contractual disputes or claims), shall be governed by
            and construed in accordance with the internal laws of the State of
            California without giving effect to any choice or conflict of law
            provision or rule (whether of the State of California or any other
            jurisdiction).
            <br />
            Any legal suit, action, or proceeding arising out of, or related to,
            these Terms of Use or the Website shall be instituted exclusively in
            the federal courts of the United States or the courts of the State
            of California, in each case located in the City and County of San
            Francisco, although we retain the right to bring any suit, action,
            or proceeding against you for breach of these Terms of Use in your
            country of residence or any other relevant country. You waive any
            and all objections to the exercise of jurisdiction over you by such
            courts and to venue in such courts.
          </Typography>
          <Typography variant="h6">Arbitration</Typography>
          <Typography paragraph>
            At Company's sole discretion, it may require You to submit any
            disputes arising from these Terms of Use or use of the Website,
            including disputes arising from or concerning their interpretation,
            violation, invalidity, non-performance, or termination, to final and
            binding arbitration under the Rules of Arbitration of the American
            Arbitration Association applying the Commonwealth of Massachusetts
            law.
          </Typography>
          <Typography variant="h6">
            Limitation on Time to File Claims
          </Typography>
          <Typography paragraph>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE
            (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
            ACTION OR CLAIM IS PERMANENTLY BARRED.
          </Typography>
          <Typography variant="h6">Waiver and Severability</Typography>
          <Typography paragraph>
            No waiver by the Company of any term or condition set out in these
            Terms of Use shall be deemed a further or continuing waiver of such
            term or condition or a waiver of any other term or condition, and
            any failure of the Company to assert a right or provision under
            these Terms of Use shall not constitute a waiver of such right or
            provision.
            <br />
            If any provision of these Terms of Use is held by a court or other
            tribunal of competent jurisdiction to be invalid, illegal, or
            unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of
            the Terms of Use will continue in full force and effect.
          </Typography>
          <Typography variant="h6">Entire Agreement</Typography>
          <Typography paragraph>
            These Terms of Use and our Privacy Policy constitute the sole and
            entire agreement between you and the Company regarding the Website
            and supersede all prior and contemporaneous understandings,
            agreements, representations, and warranties, both written and oral,
            regarding the Website.
          </Typography>
          <Typography variant="h6">Your Comments and Concerns</Typography>
          <Typography paragraph>
            This website is operated by Mindly Care, Inc.
            <br />
            All feedback, comments, requests for technical support, and other
            communications relating to the Website should be directed to:
            [support@mindly.care]
          </Typography>
        </CardContent>
      </Box>
      {/* {footerRequired && <Footer />} */}
      <Box>
        <Box className="footer fullWidth">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            padding={"1.3rem 3.8rem"}
          >
            <List
              className="footerCss"
              subheader={
                <Typography variant="h6" className="footerGrid">
                  Mindly
                </Typography>
              }
            >
              <ListItem
                disablePadding
                sx={{ cursor: "pointer" }}
                onClick={
                  () => {
                    // setCurrentPage(<OurStory />);
                    handleFootermenuclick("about");
                  }
                }
              >
                <ListItemText primary="About" />
              </ListItem>
              <ListItem
                disablePadding
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // setCurrentPage(<OurTeam />);
                  handleFootermenuclick("team");
                }}
              >
                <ListItemText primary="Team" />
              </ListItem>
              <ListItem
                disablePadding
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // setCurrentPage(<Contact />);
                  handleFootermenuclick("Contact");
                }}
              >
                <ListItemText primary="Contact Us" />
              </ListItem>
            </List>

            <List
              subheader={
                <Typography variant="h6" className="footerGrid">
                  Connect
                </Typography>
              }
            >
              <ListItem disablePadding>
                <ListItemText primary="1138 Desmond Ct" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary="Fremont CA, 94539" />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary="support@mindly.care" />
              </ListItem>
            </List>
            <List
              subheader={
                <Typography variant="h6" className="footerGrid">
                  Follow us
                </Typography>
              }
            >
              <Box className="flexBox flexRow" sx={{ gap: 2, fontSize: "1.7rem" }}>
                <ListItem disablePadding>
                  <FacebookIcon />
                </ListItem>
                <ListItem disablePadding>
                  <TwitterIcon size={28} />
                </ListItem>
                <ListItem disablePadding>
                  <LinkedInIcon />
                </ListItem>
              </Box>
            </List>
          </Grid>
          <Box
            className="flexBox"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              padding: "2rem 3.8rem",
              fontFamily: "Karla",
              lineHeight: "1.5rem",
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <span className="text">© {getCurrentYear()} All rights reserved</span>
            <span className="text" style={{ cursor: "pointer" }}>
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Lato",
                  fontSize: "1.1rem",
                }}
                onClick={handlePrivacy}
              >
                Privacy Policy
              </Button>
              /
              <Button
                sx={{
                  color: "black",
                  textTransform: "capitalize",
                  fontFamily: "Lato",
                  fontSize: "1.1rem",
                }}
                // onClick={() => {
                //   setCurrentPage(
                //     <TermconditionLandingpage footerRequired={true} />
                //   );
                // }}
                onClick={handleTermsConditions}
              >
                Term and Conditions
              </Button>
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default TermConditionLandingpage;
