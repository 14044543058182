import { ArrowRightAlt } from "@mui/icons-material";
import { Box, CircularProgress, Fab, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../hoc/MainLayout";
import PatientDetails from "./PatientDetails";
import FullScreenDialog from "../../components/FullScreenDialog";
import VideoListPatient from "../../components/VideoListforPatientOverView";

import "./PatientOverview.css";

import AuthContext from "../../contexts/AuthContext";
import { useContext } from "react";
import ViewPatientAllVideos from "../../components/ViewPatientAllVideos";
import SymptomRatingChart from "../../components/Statistics/symptomRatingChart";
import PatientMeds from "./PatientMeds";
import PatientDiagnosis from "./PatientDiagnosis";
import PatientInformation from "./PatientInformation";
import PatientCard from "../../components/PatientCard";
import { UIContext } from "../../contexts/UIProvider";
import { DataContext } from "../../contexts/DataProvider";
import Progress from "../../components/Progress";
import { fetchAllAttachmentsByUser } from "../../api";
import Historical from "../Historical";

const PatientOverview = () => {
  const location = useLocation();
  const [patient, setPatient] = useState();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    location.state?.patientData && setPatient(location.state?.patientData);
  }, [location.state]);

  const { user, typeOfUser } = useContext(AuthContext);
  const { updateSelectedMenu, setSelectedMenuKey } = useContext(UIContext);
  const { isLoading } = useContext(DataContext);

  const navigate = useNavigate();

  const getPatientAttachments = async () => {
    // setLoading(true);
    try {
      let fetchedDocuments;
      fetchedDocuments = await fetchAllAttachmentsByUser(patient?.id, 'PATIENT');
      setDocuments(fetchedDocuments?.data);
    } catch (error) {
      console.log("Error fetching documents:", error);
    } finally {
      // setLoading(false);
    }
  };

  const openDocumentHandler = (documentUrl, documentName) => {   
    navigate("/smartsearch", { state: { documentUrl,documentName } }); // for open doc in smartsearch
  };

  useEffect(() => {
    location.state?.patientData && setPatient(location.state?.patientData);
  }, [location.state]);

  useEffect(() => {
    getPatientAttachments();
  },[patient]);

  return (
    <MainLayout>
      <Box className="patientOverviewContainer">
        <Box className="profile-area patientOverview">
          <PatientCard customClass="patientOverviewCard">
            {patient && <PatientDetails patient={patient} />}
          </PatientCard>
        </Box>
        <Box className="Diagnosis-area patientOverview">
          <PatientCard
            customClass="patientOverviewCard"
            title={"Diagnosis"}
            textAlign={"center"}
            color={"var(--clr-theme-purple)"}
          >
            {patient && <PatientDiagnosis patient={patient} />}
          </PatientCard>
        </Box>
        <Box className="Meds-area patientOverview" height={"100%"}>
          <PatientCard
            customClass="patientOverviewCard"
            title={"Treatment Information"}
            textAlign={"center"}
            color={"var(--clr-theme-purple)"}
            // actions={<Button
            //   variant="contained"
            //   // onClick={EditMedsAndTherapy}
            //   sx={{ backgroundColor: "var(--clr-theme-purple) !important" }}
          >
            {/* Modify Treatment Methods
            </Button>}
            */}

            {patient && <PatientMeds patient={patient} />}
          </PatientCard>
        </Box>
        <Box className="Information-area patientOverview">
          <PatientCard
            customClass="patientOverviewCard"
            title={"Information"}
            textAlign={"center"}
            color={"var(--clr-theme-purple)"}
          >
            {patient && (
              <PatientInformation patient={patient} setPatient={setPatient} />
            )}
          </PatientCard>
        </Box>
        <Box className="statistics-area patientOverview">
          <PatientCard
            title={"Statistics"}
            subheader="Rating by Symptom"
            customClass="patientOverviewCard"
            color={"var(--clr-theme-purple)"}
            actions={
              <Fab
                className="viewAllButton"
                variant="extended"
                color="primary"
                size="small"
                onClick={() => {
                  setSelectedMenuKey("Statistics");
                  updateSelectedMenu("Statistics");
                  navigate("/statistics");
                }}
              >
                View all
              </Fab>
            }
          >
            <SymptomRatingChart patientID={patient?.id} />
          </PatientCard>
        </Box>
        <Box className="videos-area patientOverview">
          <PatientCard
            customClass="patientOverviewCard"
            title="Videos"
            textAlign={"start"}
            color={"var(--clr-theme-purple)"}
            actions={
              <FullScreenDialog
                customClass="viewAllButton"
                buttonText={"View All"}
                buttonColor="primary"
                buttonIcon={<ArrowRightAlt />}
                title="All Videos"
                buttonVariant="extended"
              >
                <ViewPatientAllVideos
                  patient={patient}
                  typeOfUser={typeOfUser}
                />
              </FullScreenDialog>
            }
          >
            {typeOfUser === "DOCTOR" && (
              <VideoListPatient patient={patient} typeOfUser={typeOfUser} />
            )}
          </PatientCard>
        </Box>
        <Box className="Attachment-area patientOverview">
          <PatientCard
            customClass="patientOverviewCard"
            title="Attachments"
            textAlign={"start"}
            color={"var(--clr-theme-purple)"}
            actions={
              <Fab
              className="viewAllButton"
              variant="extended"
              color="primary"
              size="small"
              onClick={() => {
                navigate("/historical", { state: { patientId: patient?.id } });
              }}
            >
              View all
            </Fab>
            }
          >
         {patient &&  <List style={{ display: 'flex', flexWrap: 'wrap', gap: '12px',marginTop:'10px' }}>
        {documents && documents?.length > 0 ? (
          documents?.map((document) => (
            <ListItem
              key={document.docId}
              style={{
                width: '100%',
                border: '1px solid white',
                boxShadow: "0 0 5px var(--clr-theme-purple)",
                padding: '16px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
              }}
              className='list-item'
              onClick={() => openDocumentHandler(document.docData.info.url,document.docData.info.fileName)}
            >
              <ListItemText
                primary={document.docData.info.fileName}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
                className='primary'
              />
              <ListItemText
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      Date: {new Date(document.docData.createdAt._seconds * 1000).toLocaleDateString()} {','}
                      Time: {new Date(document.docData.createdAt._seconds * 1000).toLocaleTimeString()}
                    </Typography>
                  </>
                }
                style={{ textAlign: 'right' }}
              />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No documents found" />
          </ListItem>
        )}
      </List>}
          </PatientCard>
        </Box>
      </Box>
      {isLoading && (
        <div className="backdrop-container">
          <CircularProgress className="loader" color="inherit" />
        </div>
      )}
    </MainLayout>
  );
};

export default PatientOverview;
