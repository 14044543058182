import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import FallbackScreen from "../components/FallbackScreen";
import Appointments from "../pages/Appointments";
import PatientOverview from "../pages/PatientOverview";
import Settings from "../pages/Settings";
import Termcondition from "../pages/Termcondition";
import Privacy from "../pages/Privacy";
import PrivateRoute from "./PrivateRoute";
import MainPage from "../pages/Updated_UI";
import Statistics from "../pages/Statistics";
import ContactInformation from "../pages/ContactInformation";
import PrivacyLandingpage from "../pages/Updated_UI/PrivacyLandingpage";
import Message from "../pages/Messaging";
import UserGuide from "../pages/Userguide";
import Historical from "../pages/Historical";
import Smartsearch from "../pages/Historical/Smartsearch";
import TermConditionLandingpage from "../pages/Updated_UI/TermconditionLandingpage";

const HomePage = React.lazy(() => import("../pages/Home"));
const ProfilePage = React.lazy(() => import("../pages/Profile"));
const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<FallbackScreen />}>
        <Routes>
          <Route exact path="/main" element={<MainPage />} />

          <Route exact path="/termandcondition" element={<ProfilePage />} />

          <Route exact path="/profile" element={<ProfilePage />} />
          <Route
          exact
          path="/smartsearch"
          element ={
            <PrivateRoute>            
             <Smartsearch/>
            </PrivateRoute>
          } 
          />
          
          <Route
            exact
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/appointments"
            element={
              <PrivateRoute>
                <Appointments />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/statistics"
            element={
              <PrivateRoute>
                <Statistics />
              </PrivateRoute>
            }
          />
           <Route
            exact
            path="/historical"
            element={
              <PrivateRoute>
                <Historical />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/message"
            element={
              <PrivateRoute>
                <Message />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/Termcondition"
            element={
              <PrivateRoute>
                <Termcondition />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/Privacy"
            element={
              <PrivateRoute>
                <Privacy />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/Contact"
            element={
              <PrivateRoute>
                <ContactInformation />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/Userguide"
            element={
              <PrivateRoute>
                <UserGuide />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/patient/:id"
            element={
              <PrivateRoute>
                <PatientOverview />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={
            <PrivacyLandingpage 
            // footerRequired={true}
             />}
          />

          <Route exact path="/terms-and-conditions" element={<TermConditionLandingpage />} />

          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
