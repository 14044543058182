import {
  Google,
  Key,
  Lock,
  Person,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FilledInput,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import NewLogo from "../../../components/BrandLogo";
import React, { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Progress from "../../../components/Progress";

const SignIn = ({ signupInfoHandler, goToHome, forgotPasswordHandler }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState({
    show: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const passwordInputRef = useRef(null);

  const {
    loginWithEmailPassword,
    loginWithGoogleProvider,
    getUserFromFirestore,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const googleHandler = async () => {
    try {
      const response = await loginWithGoogleProvider();
      if (response.status) {
        // ! Check if user exists in firebase collection already. i.e., to get previous user / new user
        if (!response.data?.isNewUser) {
          const result = await getUserFromFirestore(response.data.uid);
          if (result.status) {
            console.log("Google sign-in successful");
            navigate("/");
          } else {
            signupInfoHandler({ provider: "GOOGLE", data: response.data });
          }
        } else {
          // new user -> navigate to info page
          console.log("New user - redirecting to info page");
          signupInfoHandler({ provider: "GOOGLE", data: response.data });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const validateInputs = () => {
    return email.trim().length > 0 && password.trim().length > 0;
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const signinToFirebase = async () => {
    if (validateInputs()) {
      setIsLoading(true);
      const result = await loginWithEmailPassword(email, password);
      if (result.status) {
        goToHome();
        setIsLoading(false);
      } else {
        setPasswordError({
          show: true,
          message: "Invalid email & password",
        });
        setIsLoading(false);
      }
    } else {
      setPasswordError({ show: true, message: "Enter email & password" });
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.id === "username") {
        passwordInputRef.current.focus();
      } else if (event.target.id === "password") {
        await signinToFirebase();
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 15,
      }}
    >
      <NewLogo newline={false} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            textAlign: "left",
          }}
        >
          <Typography variant="h4" sx={{ fontFamily: "Lato", fontWeight: 400 }}>
            Welcome to Mindly.
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{
              color: "var(--clr-theme-gray)",
              fontFamily: "Karla",
              fontWeight: 700,
            }}
          >
            Please enter your email and password to proceed
          </Typography>
        </div>

        <InputLabel
          sx={{
            color: "var(--clr-theme-gray)",
            fontFamily: "Karla",
            fontWeight: 700,
          }}
        >
          Email
        </InputLabel>
        <TextField
          id="username"
          type={"email"}
          onChange={emailChangeHandler}
          sx={{ borderRadius: "10px" }}
          variant="outlined"
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <InputLabel
            sx={{
              color: "var(--clr-theme-gray)",
              fontFamily: "Karla",
              fontWeight: 700,
            }}
          >
            Password
          </InputLabel>
          <Typography
            sx={{
              color: "var(--clr-theme-gray)",
              fontFamily: "Karla",
              fontWeight: 700,
            }}
          >
            <Link onClick={forgotPasswordHandler} style={{ cursor: "pointer" }}>
              Forgot your Password ?
            </Link>
          </Typography>
        </div>
        <OutlinedInput
          disabled={isLoading}
          inputRef={passwordInputRef}
          id="password"
          fullWidth
          type={showPassword ? "text" : "password"}
          variant="outlined"
          onChange={passwordChangeHandler}
          // helperText={passwordError.show && passwordError.message}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="fullWidth"
        >
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Remember Me"
          />

          {passwordError.show && passwordError.message && (
            <Typography color="red" variant="subtitle2">
              {passwordError.show && passwordError.message}
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="success"
          onClick={signinToFirebase}
          sx={{
            backgroundColor: "#9291f0",
            textTransform: "capitalize",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "1.2rem",
            width: "100%",
          }}
          disabled={isLoading}
        >
          Log in
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Divider />
        <Button
          variant="outlined"
          onClick={googleHandler}
          sx={{
            textTransform: "capitalize",
            color: "var(--clr-theme-gray)",
            fontFamily: "Karla",
            fontWeight: "700",
            fontSize: "1rem",
          }}
        >
          <Google sx={{ color: "red", mr: 1 }} />
          Log in with Google
        </Button>
        <Typography>
          <Link
            onClick={forgotPasswordHandler}
            style={{ cursor: "pointer", fontFamily: "Karla" }}
          >
            Forgot Password ?
          </Link>
        </Typography>
        <Typography
          sx={{
            fontFamily: "Karla",
            fontWeight: 700,
            fontSize: "1rem",
            color: "var(--clr-theme-gray)",
          }}
        >
          Don't have an account ?
          <Link
            onClick={signupInfoHandler}
            style={{
              marginLeft: "5px",
              cursor: "pointer",
              fontFamily: "Karla",
            }}
          >
            Sign Up
          </Link>
        </Typography>
      </div>
      {isLoading && <Progress />}
    </div>
  );
};

export default SignIn;