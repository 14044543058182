import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import React, { useRef } from 'react';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import './FlipCard.css';

const FlipCard = ({ title, content }) => {
  const ref = useRef();

  return (
    <Flippy
      flipOnHover={true} // default false
      flipOnClick={false} // default false
      flipDirection='vertical' // horizontal or vertical
      ref={ref}
      className='box'
    >
      <FrontSide className="front-content">
        <Typography gutterBottom className='clrblackheader'>
          {title}
        </Typography>
      </FrontSide>
      <BackSide className="back-content">
        <Typography className='clrsubheader'>{content}</Typography>
      </BackSide>
    </Flippy>
  );
};

export default FlipCard;
