import React from 'react';

import freeVenturesLogo from '../../../assets/images/freeventureslogo.png';
import BerkeleySkydeck_pad13Logo from '../../../assets/images/berkeley-pad13.png';
import CITRISFoundryLogo from '../../../assets/images/CITRIS-Foundry-Logo.png';
import { Box, Grid } from '@mui/material';
import Section from './Section';
import { Fade } from 'react-reveal'

import './Work.css';

const Partners = ({ size }) => {
  return (
    <Section
      size={size}
      title={'Proudly Supported By'}
      titleColor='var(--clr-theme-gray)'
      content={``}
      contentColor='var(--clr-theme-white)'
      dividerColor='var(--clr-theme-purple)'
    >
      <Grid
        container
        direction='row'
        justifyContent='space-evenly'
        alignItems='center'
        gap={'2.5rem'}
        sx={{ alignItems: 'center' }}
        className='partnersGrid'
      >
        <Fade left duration={1500} delay={400}>
          <img src={freeVenturesLogo} />
        </Fade>
        <Fade bottom duration={1500} delay={400}>
          <img src={CITRISFoundryLogo} />
        </Fade>
        <Fade right duration={1500} delay={400}>
          <img src={BerkeleySkydeck_pad13Logo} />
        </Fade>
      </Grid>
    </Section>
  );
};
export default Partners;
