import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import ForgotPassword from "./ForgotPassword";

import "./Login.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignUpInfo from "./SignUpInfo";

const LoginPage = () => {
  const [showDivIndex, setShowDivIndex] = useState(0);
  const [infoParams, setInfoParams] = useState();
  const [redirectFromProvider, setRedirectFromProvider] = useState({
    status: false,
    data: null,
  });

  const { user, login } = useContext(AuthContext);

  const navigate = useNavigate();

  const signinHandler = () => {
    setShowDivIndex(0);
  };
  
  const signupInfoHandler = (redirectParams) => {
    if (redirectParams.provider === "GOOGLE") {
      setRedirectFromProvider({
        status: true,
        data: redirectParams.data,
      });
    }
    setShowDivIndex(1);
  };

  const signupHandler = (params) => {
    setShowDivIndex(2);

    setInfoParams(params);
  };

  const forgotPasswordHandler = () => {
    setShowDivIndex(3);
  };

  const goToHome = () => {
    navigate("/");
  };

  const goToLogin = () => {
    window.location.reload();
  };

  // useEffect(() => {
  //   if (!redirectFromProvider.status) {
  //     !user && FirebaseAuth.currentUser && login(FirebaseAuth.currentUser);
  //     user && goToHome();
  //   }
  // }, [user, FirebaseAuth.currentUser]);

  // ! TODO: Make card css reponsive with 'rem', 'em'
  return (
    <div className="accountPage">
      {showDivIndex === 0 && (
        <SignIn
          signupInfoHandler={signupInfoHandler}
          goToHome={goToHome}
          forgotPasswordHandler={forgotPasswordHandler}
        />
      )}
      {showDivIndex === 1 && (
        <SignUpInfo
          signinHandler={signinHandler}
          signupHandler={signupHandler}
          redirectFromProvider={redirectFromProvider}
        />
      )}
      {showDivIndex === 2 && (
        <SignUp
          signinHandler={signinHandler}
          goToLogin={goToLogin}
          infoParams={infoParams}
        />
      )}
      {showDivIndex === 3 && (
        <ForgotPassword
          goToLogin={goToLogin}
          infoParams={infoParams}
          signinHandler={signinHandler}
        />
      )}

      {/* <Divider /> */}
      {/* <SocialIcons signupInfoHandler={signupInfoHandler} /> */}
    </div>
  );
};

export default LoginPage;
