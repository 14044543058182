import {
  Box,
} from "@mui/material";
import React from "react";
import "./Work.css";
import Section from "./Section";
import FlipCard from '../../../components/FlipCard';
import { Fade } from "react-reveal"

const Work = () => {
  return (
    <div style={{ backgroundColor: "var(--clr-theme-purple-50)" }}>
      <Box className="featuresPages">
        <Section
          title={"Why Mindly Matters"}
          titleColor="var(--clr-theme-white)"
          content={`Healthcare professionals are unable to understand crucial patient behaviors outside of the clinic leading to faulty and lengthy treatments. Mindly empowers them with real-time, deeper insights into patient's lives, leading to more accurate treatments for neurological disorders.`}
          contentColor="var(--clr-theme-white)"
          dividerColor="var(--clr-theme-white)"
        ></Section>
        <Fade bottom duration={1500} delay={400} >
          <div className="fullWidth">
            <Box
              className="box2 flexBox flexWrap fullWidth fullHeight"
              sx={{ flex: 0.6, gap: "1em" }}
            >
              <FlipCard
                title={'Real-Time Monitoring'}
                content={`Capture and track individual behavior and responses exhibited in various settings via consistent video documentation, enabling a more holistic and informed analysis of each patient's overall condition.`}
              />
              <FlipCard
                title={'Personalized Care'}
                content={`Provide tailored interventions and treatment plans by collecting and analyzing patient-specific data, improving treatment accuracy and patient outcomes.`}
              />
              <FlipCard
                title={'Enhanced Collaboration'}
                content={`Streamline the communication process between parents and professionals in ASD behavioral therapy by providing a centralized platform for sharing insights, observations, and treatment progress.`}
              />
            </Box>
          </div>
        </Fade>
      </Box>
    </div>
  );
};

export default Work;
