import React, { createContext, useEffect, useRef, useState } from "react";
import {
  fetchPatientTreatments,
  getAllPatientVideos,
  getMyPatientsDetails,
  getPatientVideos,
  patientLogin,
} from "../api";
import {
  FirebaseAuth,
  FirebaseStorage,
  FirestoreDB,
} from "../utils/firebase/firebase";
export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [patientData, setPatientData] = useState([]);
  const [currentPatientData, setCurrentPatientData] = useState(null);
  const [currentPatientVideos, setCurrentPatientVideos] = useState(null);
  const [allPatientsAllVideos, setAllPatientsAllVideos] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [typeOfUser, setTypeOfUser] = useState(null);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  // * Only for doctor login
  // For patient this info is already retrieved during login and kept in user state
  const fetchPatientsForDoctor = async (doctorUid) => {
    setIsLoading(true);
    const result = await getMyPatientsDetails(doctorUid);
    if (result) {
      setPatientData(result?.patients);
    }
    setIsLoading(false);
  };

  // * Both for doctor and patient logins
  const fetchOnePatientData = async (patientUid) => {
    setIsLoading(true);
    const treatments_diagnosis = await fetchPatientTreatments(patientUid);
    const patientData = await patientLogin(patientUid);
    if (treatments_diagnosis || patientData) {
      setCurrentPatientData({
        data: treatments_diagnosis,
        id: patientUid,
        user: patientData?.myDetails,
      });
    }
    setIsLoading(false);
  };

  const fetchOnePatientAllVideos = async (patientUid) => {
    setIsLoading(true);
    const result = await getPatientVideos(patientUid, "all");
    if (result) {
      setCurrentPatientVideos({ data: result, id: patientUid });
    }
    setIsLoading(false);
  };

  const fetchAllPatientsAllVideos = async (doctorUid) => {
    setIsLoading(true);
    const result = await getAllPatientVideos(doctorUid);
    if (result) {
      setAllPatientsAllVideos({ data: result, id: doctorUid });
    }
    setIsLoading(false);
  };

  const resetStates = () => {
    setPatientData([]);
    setCurrentPatientData(null);
    setAllPatientsAllVideos(null);
    setCurrentPatientVideos(null);
  };

  return (
    <DataContext.Provider
      value={{
        patientData,
        fetchPatientsForDoctor,
        currentPatientData,
        fetchOnePatientData,
        currentPatientVideos,
        fetchOnePatientAllVideos,
        allPatientsAllVideos,
        fetchAllPatientsAllVideos,
        setCurrentPatientData,
        setPatientData,
        isLoading,
        user,
        typeOfUser,
        resetStates,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
