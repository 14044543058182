import { Box, Tooltip, Typography, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import CustomButton from "../../components/CustomButton";
import "./Historical.css";

const data = [
  {
    name: "Feb",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "march",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "April",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "May",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "June",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "July",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Aug",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AssessmentAnalytics = ({
  documents,
  selectedPatient,
  selectedDocData,
  PatientSelect,
  AssessmentIndexSelect,
  typeOfUser,
}) => {
  return (
    <Box
      className="fullWidth fullHeight assessmentAnalytics"
      sx={{ overflowY: "auto", p: 1 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Lato",
              fontWeight: 700,
            }}
            gutterBottom
          >
            Assessment
          </Typography>
          <Grid container spacing={2}>
            {typeOfUser === "DOCTOR" && (
              <Grid item xs={12} sm={6} md={6}>
                {PatientSelect}
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={12}>
              {AssessmentIndexSelect}
            </Grid>
          </Grid>
          {selectedDocData ? (
            <Grid item xs={12}>
              <Box
                // className="fullHeight"
                mt={2}
                sx={{
                  backgroundColor: "var(--clr-theme-lgray)",
                  p: 2,
                  borderRadius: 3,
                  width: { xs: "100%", sm: "100%" }, // 100% for mobile, 40% for larger screens
                  height: { xs: "100%", sm: "400%" },
                }}
              >
                {selectedDocData?.docData?.summary?.MedicalHistory ? (
                  <Typography fontFamily={"Lato"}>
                    {selectedDocData?.docData?.summary?.MedicalHistory}
                  </Typography>
                ) : (
                  <Typography>No Medical History Found</Typography>
                )}
              </Box>
            </Grid>
          ) : (
            <Box
              className="flexBox flexColumn flexCenter"
              sx={{
                height: "50%",
                backgroundColor: "var(--clr-theme-lgray)",
                mt: 1,
                borderRadius:2
              }}
            >
              <Typography fontFamily={"Lato"}>
                No assesments available at this time.
              </Typography>
            </Box>
          )}
        </Grid>

        {/* Right Side - Chart */}
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant="h5"
            fontFamily={"Lato"}
            textAlign={"center"}
            gutterBottom
            fontWeight={700}
          >
            Scores Over Time
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: {
                xs: 200,
                sm: 400,
                md: 300,
              },
            }}
          >
            <ResponsiveContainer width="99%" height={"100%"}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                {/* <YAxis /> */}
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
          <Box className="flexBox fullWidth expandButton">
            <CustomButton customClass="customButton">
              Expand/View All
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssessmentAnalytics;
