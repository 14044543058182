import React, { useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  getAdditionalUserInfo,
  beforeAuthStateChanged,
} from "firebase/auth";
import { ref } from "firebase/storage";

import AuthContext from "./AuthContext";
import {
  FirebaseAuth,
  FirebaseStorage,
  FirestoreDB,
} from "../utils/firebase/firebase";
import { UploadFileToStorage } from "../utils/firebase/helper";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import {
  FIRESTORE_DOCTOR_TEMPLATE,
  FIRESTORE_PATIENT_HISTORY_TEMPLATE,
  FIRESTORE_PATIENT_TEMPLATE,
} from "../constants/DataTemplates";
import { patientLogin, doctorLogin } from "../api";

const AuthProvider = ({ children }) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [typeOfUser, setTypeOfUser] = useState(null);
  const [openRightPanel, setOpenRightPanel] = useState(false);
  const [openVideoUpload, setOpenVideoUpload] = useState(false);
  const [panelContent, setPanelContent] = useState();
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [isNotificationPanel, setIsNotificationPanel] = useState(false);

  const registerWithGoogle = async (data, infoParams) => {
    try {
      if (infoParams.type === "PATIENT") {
        // Only patients have this documents mapped to their ID. Doctors will only read based on patient id.
        await setDoc(doc(FirestoreDB, "videoLogs", data.uid), {
          videos: [],
        });
        await setDoc(doc(FirestoreDB, "patientHistory", data.uid), {
          history: [],
        });
      }

      // Add document to firestore collection
      const collectionName =
        infoParams.type === "DOCTOR" ? "doctorsList" : "patientsList";

      const documentParams =
        infoParams.type === "DOCTOR"
          ? {
              registrationID: infoParams.regID,
              fullname: data.displayName || "",
              email: data.email,
              photoURL: data.photoURL || "",
              ...FIRESTORE_DOCTOR_TEMPLATE,
            }
          : {
              doctorRegistrationID: infoParams.regID,
              fullname: data.displayName || "",
              email: data.email,
              photoURL: data.photoURL || "",
              ...FIRESTORE_PATIENT_TEMPLATE,
            };

      if (infoParams.type === "DOCTOR") {
        await setDoc(doc(FirestoreDB, collectionName, data.uid), {
          profile: btoa(JSON.stringify(documentParams)),
          isEncrypted: false,
        });
      }

      if (infoParams.type === "PATIENT") {
        return {
          status: true,
          data: {
            uid: data.uid,
            fullname: data.displayName,
            email: data.email,
            photoURL: data.photoURL || "",
          },
        };
      }
      login({ ...data });
      return { status: true, data: { uid: data.uid, userEmail: data?.email } };
    } catch (err) {
      return { status: false, data: err };
    }
  };

  const registerWithEmailPassword = async (
    email,
    password,
    fullname,
    parentName,
    profilePictureFile,
    infoParams
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        FirebaseAuth,
        email,
        password
      );
      const user = userCredential.user;

      let url = "";

      if (profilePictureFile) {
        let blob = new Blob([profilePictureFile], {
          type: profilePictureFile.type,
        });
        const profileStorageRef = ref(
          FirebaseStorage,
          `profiles/${user?.uid}/${profilePictureFile.name}`
        );
        const result = await UploadFileToStorage(profileStorageRef, blob);
        if (result.status) {
          url = result.data;
        }
      }
      await updateProfile(user, {
        displayName: fullname,
        photoURL: url,
      });

      if (infoParams.type === "PATIENT") {
        // Only patients have this documents mapped to their ID. Doctors will only read based on patient id.
        await setDoc(doc(FirestoreDB, "videoLogs", user.uid), {
          videos: [],
        });
        await setDoc(doc(FirestoreDB, "patientHistory", user.uid), {
          history: [],
        });
      }

      // Add document to firestore collection
      const collectionName =
        infoParams.type === "DOCTOR" ? "doctorsList" : "patientsList";

      const documentParams =
        infoParams.type === "DOCTOR"
          ? {
              registrationID: infoParams.regID,
              fullname: fullname,
              email: user.email,
              photoURL: url || "",
              ...FIRESTORE_DOCTOR_TEMPLATE,
            }
          : {
              doctorRegistrationID: infoParams.regID,
              fullname: fullname,
              email: user.email,
              parentName:parentName,
              photoURL: url || "",
              ...FIRESTORE_PATIENT_TEMPLATE,
            };

      // await setDoc(doc(FirestoreDB, collectionName, user.uid), documentParams);
      if (infoParams.type === "DOCTOR") {
        await setDoc(doc(FirestoreDB, collectionName, user.uid), {
          profile: btoa(JSON.stringify(documentParams)),
          isEncrypted: false,
          isPoliciesAccepted: true,
        });
      }
      if (infoParams.type === "PATIENT") {
        return {
          status: true,
          data: {
            uid: user.uid,
            fullname: fullname,
            parentName:parentName,
            email: user.email,
            photoURL: url || "",
          },
        };
      }
      // login(user); // Relogin and set the state after signup
      // return { status: true, data: null };
      return { status: true, data: { uid: user.uid, userEmail: user?.email } };
    } catch (err) {
      return { status: false, data: err };
    }
  };

  const registerNewPatient = async (patientInfo, doctorId) => {
    try {
      // Only patients have this documents mapped to their ID. Doctors will only read based on patient id.
      await setDoc(doc(FirestoreDB, "videoLogs", patientInfo.uid), {
        videos: [],
      });
      await setDoc(doc(FirestoreDB, "patientHistory", patientInfo.uid), {
        history: [],
      });

      // Add document to firestore collection
      const collectionName = "patientsList";
      const documentParams = {
        fullname: patientInfo?.fullname,
        email: patientInfo.email,
        photoURL: patientInfo.photoURL || "",
        parentName: patientInfo.parentName || "",
        ...FIRESTORE_PATIENT_TEMPLATE,
      };
      console.log("documentParams", documentParams);
      await setDoc(doc(FirestoreDB, collectionName, patientInfo.uid), {
        profile: btoa(JSON.stringify(documentParams)),
        isEncrypted: false,
        isPoliciesAccepted: true,
        doctorIDs: [doctorId],
      });

      login(user); // Relogin and set the state after signup
      return { status: true, data: null };
    } catch (err) {
      return { status: false, data: err };
    }
  };

  const loginWithEmailPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        FirebaseAuth,
        email,
        password
      );
      // Signed in
      const user = userCredential.user;
      login(user);
      return { status: true, data: user };
    } catch (e) {
      return { status: false, data: e };
    }
  };

  const loginWithGoogleProvider = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/calendar.events");

      const result = await signInWithPopup(FirebaseAuth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      const user = result.user;

      const moreInfo = getAdditionalUserInfo(result);

      //checking if user is exits or not by authentication
      const isNewUser = moreInfo.isNewUser;

      // Additional logic after successful sign-in
      console.log("User signed in:", user);
      return { status: true, data: { ...user, isNewUser } };
    } catch (e) {
      if (typeof console !== "undefined") {
        console.error("Google Sign-In error:", e);
      }
      console.log(e);
      return { status: false, data: e };
    }
  };

  const getUserFromFirestore = async (uid) => {
    let documentRef = await getDoc(doc(FirestoreDB, "patientsList", uid));

    if (!documentRef.exists()) {
      documentRef = await getDoc(doc(FirestoreDB, "doctorsList", uid));
      if (!documentRef.exists()) {
        return { status: false, message: "Invalid user" };
      }
      setTypeOfUser("DOCTOR");
      const response = await doctorLogin(uid);

      return { status: true, data: response.myDetails || {} };
    } else {
      setTypeOfUser("PATIENT");
      const response = await patientLogin(uid);
      return {
        status: true,
        data:
          { ...response.myDetails, doctorDetails: response.doctorDetails } ||
          {},
      };
    }
  };

  const login = async (user) => {
    const response = await getUserFromFirestore(user.uid);
    if (response.status) {
      setIsUserAuthenticated(true);
      setUser({ ...user, ...response.data });
      const item = {
        value: user.uid,
        expiry: new Date().getTime() + 3600000, // 1hr
      };
      localStorage.setItem("session", JSON.stringify(item));
    } else {
      setIsUserAuthenticated(false);
      setUser(null);
      localStorage.removeItem("session");
      //ToDo: add toast to the Login button check if authenticate false
    }
  };

  const logout = () => {
    FirebaseAuth.currentUser &&
      FirebaseAuth.signOut().then(() => {
        setIsUserAuthenticated(false);
        setUser(null);
        localStorage.removeItem("session");
      });
  };

  const updateGlobalUser = (data) => {
    setUser(data);
  };

  const validateContactNo = (contactNo) => {
    const { areaCode, number } = contactNo;

    if ((areaCode > 0 && number > 0) || (areaCode !== "" && number !== "")) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (user?.uid) {
      if (typeOfUser === "PATIENT") {
        if (
          (user.age < 0 || user.age !== "") &&
          user.gender !== "" &&
          // user.birthWeight !== 0 &&
          user.dob !== "" &&
          // (user.currentWeight !== 0 || user.currentWeight !== "") &&
          // (user.height !== 0 || user.height !== "") &&
          validateContactNo(user.contactNo)
        ) {
          setIsProfileCompleted(true);
        } else {
          setIsProfileCompleted(false);
        }
      } else if (typeOfUser === "DOCTOR") {
        setIsProfileCompleted(true);
      }
    }
  }, [user, typeOfUser]);

  useEffect(() => {
    onAuthStateChanged(FirebaseAuth, (user) => {
      if (user) {
        // TODO ! Review later
        // user.providerData[0].providerId !== "google.com" && login(user);
        login(user);
      } else {
        // logout();
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isUserAuthenticated,
        user,
        setUser,
        registerWithGoogle,
        registerWithEmailPassword,
        loginWithEmailPassword,
        loginWithGoogleProvider,
        login,
        logout,
        typeOfUser,
        setTypeOfUser,
        openRightPanel,
        setOpenRightPanel,
        openVideoUpload,
        setOpenVideoUpload,
        panelContent,
        setPanelContent,
        isProfileCompleted,
        setIsProfileCompleted,
        isNotificationPanel,
        setIsNotificationPanel,
        registerNewPatient,
        updateGlobalUser,
        getUserFromFirestore,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
