import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import MainLayout from "../../hoc/MainLayout";
import { useLocation } from "react-router-dom";
import { East as EastIcon } from "@mui/icons-material";
import AuthContext from "../../contexts/AuthContext";
import { fetchAllAttachmentsByUser } from "../../api";
import Progress from "../../components/Progress";
import { DataContext } from "../../contexts/DataProvider";

const Smartsearch = () => {
  const location = useLocation();
  const { attachmentId, documentUrl, documentName, patientId } =
    location.state || {};

  const { patientData: patients } = useContext(DataContext);
  const { user, typeOfUser } = useContext(AuthContext);

  const [patientData, setPatientData] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [docUrl, setDocumentUrl] = useState(documentUrl);
  const [selectedPatientName, setSelectedPatientName] = useState("");

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (patients) {
      const dataArray = patients?.map((each) => ({
        ...each?.patientInfo,
        id: each?.patientId,
      }));
      setPatientData(dataArray);
    }
  }, [patients]);

  useEffect(() => {
    if (selectedPatientId) {
      filterDocumentsByPatient(selectedPatientId);
    }
  }, [selectedPatientId, documents]);

  console.log("location : ", location);
  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId);
      // Find the patient object based on patientId
      const selectedPatient = patientData.find(
        (patient) => patient.id === patientId
      );
      // Set the default patient name
      // setSelectedPatientName(selectedPatient.fullname);
    }

    if (documentName) {
      setSelectedDocument(documentName);
    }
  }, [patientId, documentName]);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const fetchedDocuments = await fetchAllAttachmentsByUser(
        user?.uid,
        typeOfUser
      );
      setDocuments(fetchedDocuments?.data);
      if (typeOfUser === "PATIENT") {
        setFilteredDocuments(fetchedDocuments?.data);
      }
    } catch (error) {
      console.log("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterDocumentsByPatient = (patientId) => {
    const patientDocuments = documents
      .flatMap((pat) =>
        pat?.attachmentData?.map((doc) => ({ ...doc, patientId: pat.patientId }))
      )
      .filter((doc) => doc.patientId === patientId);
    setFilteredDocuments(patientDocuments);
  };

  const handleSelectDocumentChange = (event) => {
    const selectedFileName = event.target.value;
    const selectedDoc = filteredDocuments.find(
      (doc) => doc.docData.info.fileName === selectedFileName
    );

    setSelectedDocument(selectedFileName);
    setDocumentUrl(selectedDoc?.docData?.info?.url || "");
  };

  const handleSelectPatientChange = (event) => {
    const patientId = event.target.value;
    setSelectedPatientId(patientId);
    setSelectedDocument("");
    setDocumentUrl("");
    setFilteredDocuments([]);
  };

  const handleSendClick = () => {
    if (inputText.trim() !== "") {
      setMessages([...messages, { text: inputText, sender: "user" }]);
      setInputText("");
    }
  };

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent={"center"}
          gap={1}
        >
          <Grid
            item
            xs={8}
            sm={8}
            md={12}
            lg={12}
            sx={{
              border: "none",
              p: 0.5,
              height: "86vh",
              width: "100%",
              maxWidth: "100%",
              backgroundColor: "#D9D9D9",
              borderRadius: 1,
              mb: 1,
            }}
          >
            <Grid>          
            {typeOfUser === "DOCTOR" && (
                <FormControl sx={{ width: "45%", m: 0.1, pl: 1 }}>
                  <Select
                    value={selectedPatientId}
                    onChange={handleSelectPatientChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Select Patient" }}
                  >
                    <MenuItem value="" disabled>
                      Select Patient
                    </MenuItem>
                    {patientData.map((patient) => (
                      <MenuItem key={patient.id} value={patient.id}>
                        {patient.fullname
                          ? `${patient.fullname
                              .split(/\s+/)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1).toLowerCase()
                              )
                              .join(" ")}`
                          : ""}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}


              <FormControl sx={{ width: "45%", m: 0.1, pl: 1 }}>
                <Select
                  value={selectedDocument}
                  onChange={handleSelectDocumentChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Select Document" }}
                >
                  <MenuItem value="" disabled>
                    Select Document
                  </MenuItem>
                  {selectedPatientId && filteredDocuments.length === 0 ? (
                        <MenuItem value="" disabled>
                          No documents
                        </MenuItem>
                      ) : (
                        filteredDocuments.map((doc) => (
                          <MenuItem
                            key={doc?.docId}
                            value={doc?.docData?.info?.fileName}
                          >
                            {doc?.docData?.info?.fileName}
                          </MenuItem>
                        ))
                      )}                 
                </Select>
              </FormControl>
            </Grid>
            <Box
              className="contentContainer"
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "75vh",
                m: 1,
                backgroundColor: "white",
                padding: 2,
                borderRadius: 2, 
              }}
            >
              {docUrl ? (
                <iframe
                  src={docUrl}
                  title="Document Viewer"
                  width="100%"
                  height="100%"
                />
              ) : (
                <Box
                  className="contentContainer"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "72vh",
                    mr: 1,
                    backgroundColor: "white",
                    borderRadius: 1,
                    mt: 0.5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" color="textSecondary">
                    Select any document to view
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          {/* <Grid
            item
            xs={12}
            md={5.8}
            sm={12}
            lg={5.8}
            sx={{
              maxHeight: "100%",
              height: "86vh",
              backgroundColor: "#D9D9D9",
            }}
          >
            <Box
              className="contentContainer"
              sx={{
                mb: 0.75,
                mr: 1,
                backgroundColor: "white",
                overflowY: "auto",
                height: "73.5vh",
              }}
            > */}
              {/* {<QAInterface />} */}
            {/* </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                mt: 1.5,
              }}
            >
              <TextField
                variant="outlined"
                placeholder="SearchDocument..."
                sx={{
                  width: "100%",
                  maxWidth: "80%",
                  marginBottom: "20px",
                  marginTop: "5px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "999px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--clr-theme-purple)",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleSendClick}
                      disabled={!inputText}
                      style={{
                        backgroundColor: inputText ? "gray" : "transparent",
                      }}
                      // onChange={handleInputChange}
                      value={inputText}
                    >
                      <EastIcon
                        style={{ color: inputText ? "#fff" : "gray" }}
                      />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Grid> */}
        </Grid>
      </Box>
      {/* {isLoading && <Progress size={"5rem"} />} */}
    </MainLayout>
  );
};

export default Smartsearch;
