import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  DRAWER_WIDTH,
  LeftMenu,
  LeftMenu_BottomSection,
} from "../../constants/LeftMenu";
import { UIContext } from "../../contexts/UIProvider";
import PatientList from "./PatientList";
import LogoWhite from "../../assets/images/LogoWhite.png";

import "./LeftPanel.css";

const LeftPanel = ({ userType }) => {
  const {
    selectedMenuKey,
    updateSelectedMenu,
    isDrawerOpen,
    setIsDrawerOpen,
    drawerVariant,
    isNewMesage,
  } = useContext(UIContext);

  const navigate = useNavigate();
  return (
    <Drawer
      className="left-drawer"
      sx={{
        width: drawerVariant === "permanent" ? DRAWER_WIDTH : "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant={drawerVariant}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      anchor="left"
    >
      <Box className="flexBox flexCenter" sx={{ m: 3 }}>
        <ListItemAvatar
          sx={{
            display: { xs: "flex", md: "flex" },
            mx: 1,
          }}
        >
          <img src={LogoWhite} height="50px" />
        </ListItemAvatar>
        <Typography
          className="brand"
          variant="caption"
          noWrap
          sx={{
            display: { md: "flex" },
            fontSize: "2rem !important",
            fontFamily: "System-ui",
            color: "var(--clr-theme-white)",
          }}
        >
          mindly
        </Typography>
      </Box>
      <List className="topSection">
        {LeftMenu.map((item, index) =>
          item.collapsible ? (
            userType &&
            userType === "DOCTOR" && (
              <PatientList key={index} icon={item.icon} text={item.name} />
            )
          ) : (
            <ListItem
              key={index}
              disablePadding
              onClick={() => {
                updateSelectedMenu(item.key);
                item.path && navigate(item.path);
                setIsDrawerOpen(false);
              }}
              className={selectedMenuKey === item.key ? "selected" : ""}
            >
              <ListItemButton>
                <ListItemIcon
                  className={
                    selectedMenuKey === item.key
                      ? "selectedIcon"
                      : "nonSelectedIcon"
                  }
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText sx={{}} primary={item.name} />
                {item.key === "Messaging" &&
                  item.additionalIcon &&
                  isNewMesage && (
                    <ListItemIcon><div style={{fontSize:'1.5rem',color:'green'}}>{item.additionalIcon}</div></ListItemIcon>
                  )}
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>

      <List className="bottomSection">
        {LeftMenu_BottomSection.map((item, index) => (
          <ListItem
            key={index}
            // disablePadding
            sx={{ py: 0 }}
            onClick={() => {
              updateSelectedMenu(item.key);
              item.path && navigate(item.path);
              setIsDrawerOpen(false);
            }}
            className={selectedMenuKey === item.key ? "selected" : ""}
          >
            <ListItemButton sx={{ p: 0.5 }}>
              <ListItemIcon
                className={
                  selectedMenuKey === item.key
                    ? "selectedIcon"
                    : "nonSelectedIcon"
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default LeftPanel;
