import React, { useState } from "react";
import {Box,Fab,FormControl,Grid,TextField,Typography} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";

import ContactImage from "../../../assets/images/contact_page.png";
import MaskedImage from "../../../components/MaskedImage";
import Footer from "../Footer";
import FacebookIcon from "../SocialLinks/Facebook";
import TwitterIcon from "../SocialLinks/Twitter";
import LinkedInIcon from "../SocialLinks/LinkedIn";

import "./Contact.css";
import { Scrollbar } from "smooth-scrollbar-react";
import Fade from "react-reveal";
import LightSpeed from "react-reveal/LightSpeed";
import { FirestoreDB } from "../../../utils/firebase/firebase";
import { Timestamp, doc, setDoc } from "firebase/firestore";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phnNo, setPhnNo] = useState("");
  const [message, setMessage] = useState("");

  const resetFields = () => {
    setName("");
    setEmail("");
    setCompany("");
    setPhnNo("");
    setMessage("");
  };

  const onClickContactUs = async () => {
    setIsLoading(true);
    try {
      const mailDocId = uuidv4();
      if (message && email && phnNo) {
        const mailRef = doc(FirestoreDB, "emails", mailDocId);
        await setDoc(mailRef, {
          type: "contactUs",
          toEmail: email,
          name,
          company,
          phoneNumber: phnNo,
          body: message,
          date: Timestamp.now(),
        });
        toast.success("Successfully sent mail", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      resetFields();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: "",
        },
      }}
    >
      <ToastContainer autoClose={true} />
      <Box className="landingPageMainContainer">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MaskedImage
              currentStyle={{
                height: "40vh",
                backgroundImage: `url(${ContactImage})`,
              }}
            >
              <Fade left duration={1500} delay={400}>
                <Typography
                  variant="h2"
                  sx={{ fontFamily: "Lato", fontWeight: 700 }}
                >
                  Contact us
                </Typography>
              </Fade>
            </MaskedImage>
          </Grid>
          <Grid item xs={12}>
            <Box className="contactContainer">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap-reverse",
                  width: "100%",

                  justifyContent: "space-around",
                  flex: 1,
                  gap: 10,
                }}
              >
                <Box
                  className="flexBox flexColumn"
                  sx={{ gap: 5, paddingRight: "6rem" }}
                >
                  <Fade left duration={1500} delay={400}>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                        color: "var(--clr-theme-gray)",
                      }}
                    >
                      Follow us
                    </Typography>
                  </Fade>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 7,
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "1.4rem",
                    }}
                  >
                    <Fade left duration={1500} delay={400}>
                      <FacebookIcon />
                    </Fade>
                    <Fade center duration={1500} delay={400}>
                      <TwitterIcon />
                    </Fade>
                    <Fade right duration={1500} delay={400}>
                      <LinkedInIcon />
                    </Fade>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    flex: 0.4,
                    alignItems: "start",
                    minWidth: "300px",
                  }}
                >
                  <Fade right duration={1500} delay={400}>
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                        color: "var(--clr-theme-gray)",
                      }}
                    >
                      Questions? We’d love to hear from you!
                    </Typography>
                  </Fade>
                  <Fade right duration={1500} delay={400}>
                    <FormControl>
                      <Box sx={{ display: "flex", gap: 5, width: "100%" }}>
                        <TextField
                          fullWidth
                          placeholder="Name"
                          variant="standard"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          sx={{ marginBottom: 5 }}
                        />
                        <TextField
                          fullWidth
                          placeholder="Email"
                          variant="standard"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          sx={{ marginBottom: 5 }}
                        />
                      </Box>
                      <Box sx={{ display: "flex", gap: 5, width: "100%" }}>
                        <TextField
                          fullWidth
                          placeholder="Company"
                          variant="standard"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          sx={{ marginBottom: 5 }}
                        />
                        {phnNo !== null && (
                          <TextField
                            fullWidth
                            placeholder="Phone Number"
                            variant="standard"
                            value={phnNo}
                            onChange={(e) => setPhnNo(e.target.value)}
                            sx={{ marginBottom: 5 }}
                          />
                        )}
                      </Box>

                        <TextField
                          fullWidth
                          placeholder="How can we help ?"
                          variant="standard"
                          multiline
                          rows={4}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />                    
                    </FormControl>                    
                  </Fade>                  
                  <LightSpeed bottom duration={1500} delay={400}>
                    <Fab
                      variant="extended"
                      sx={{
                        background: "var(--clr-theme-purple)",
                        color: "white",
                        ":hover": {
                          background: "var(--clr-theme-purple-50)",
                        },
                      }}
                      onClick={onClickContactUs}
                    >
                      Contact Us
                    </Fab>
                  </LightSpeed>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Footer />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Scrollbar>
  );
};

export default Contact;
