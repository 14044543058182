import { Avatar, CardContent, Typography } from '@mui/material';
import React from 'react';
import LinkedInIcon from '../SocialLinks/LinkedIn';

const MemberCard = ({ image, name, title, width, height, linkedInURL }) => {
  return (
    <div className='box'>
      <CardContent className='flexBox flexCenter flexColumn'>
        <Avatar
          sx={{ width: width, height: height }}
          variant='circular'
          src={image}
        />
        <Typography
          variant='h6'
          gutterBottom
          sx={{ color: 'black', fontFamily: 'Lato', fontWeight: 600 }}
        >
          {name}
        </Typography>
        <Typography
          variant='subtitle2'
          gutterBottom
          sx={{ color: 'black', fontFamily: 'Lato', fontWeight: 400 }}
        >
          {title}
        </Typography>
        <LinkedInIcon link={linkedInURL} />
      </CardContent>
    </div>
  );
};

export default MemberCard;
