import { uploadBytes, getDownloadURL } from "firebase/storage";

export const UploadFileToStorage = async (storageRef, fileBlob) => {
  try {
    const snapshot = await uploadBytes(storageRef, fileBlob);

    const url = await getDownloadURL(snapshot.ref);
    return { status: true, data: url };
  } catch (e) {
    return { status: false, data: null };
  }
};
