import React, { useEffect, useState, memo, useContext } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Divider,
  Button,
  Tabs,
  Tab,
  Grid,
  ListSubheader,
  ListItemText,
  TextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Close,
  FileUploadSharp,
  CloudUploadRounded,
  SendRounded,
  CheckCircleOutlineRounded,
  Cancel,
  Info,
  Attachment,
  VideoFile,
  Category,
} from "@mui/icons-material";
import { ref } from "firebase/storage";
import {
  arrayUnion,
  doc,
  updateDoc,
  Timestamp,
  getDocs,
  query,
  collection,
  where,
  setDoc,
  onSnapshot,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

import { FirebaseStorage, FirestoreDB } from "../../utils/firebase/firebase";
import { UploadFileToStorage } from "../../utils/firebase/helper";
import Progress from "../Progress";
import CustomButton from "../../components/CustomButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { ToastContainer, toast } from "react-toastify";
import "./VideoUpload.css";
import "react-toastify/dist/ReactToastify.css";
import BrandLogo from "../BrandLogo";
import AuthContext from "../../contexts/AuthContext";
import { DataContext } from "../../contexts/DataProvider";
import { UIContext } from "../../contexts/UIProvider";
import { fetchAllAttachmentsByUser, fetchPatientTreatments } from "../../api";
import behaviorData from "./behaviorData.json"
import Behaviour from "../PlayVideo/VideoFeedback/Behaviour";
import { values } from "lodash";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: "bold",
    padding: "10px",
  },
}));
const VideoUpload = ({ user, typeOfUser }) => {
  const { openVideoUpload, setOpenVideoUpload } = useContext(AuthContext);
  const { currentPatientData, fetchOnePatientData } = useContext(DataContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [patientData, setPatientData] = useState([]);
  const [SelectedPatient, SetSelectedPatient] = React.useState("");
  const [fileDuration, setFileDuration] = useState(0);
  const [customFileName, setCustomFileName] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [therapiesDropdown, setTherapiesDropdown] = useState([]);
  const [diagnosisDropdown, setDiagnosisDropdown] = useState([]);
  const [patientTreatmentsData, setPatientTreatementsData] = useState([]);
  const [isPatientSelected, setIsPatientSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Medications, setMedications] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [filteredAttachmentsData, setFilteredAttachmentsData] = useState([]);
  const [patientSummaryData, setPatientSummaryData] = useState([]);
  const [selectedBehaviorNames, setSelectedBehaviorNames] = useState([]);
  const [isPrivateAll, setIsPrivateAll] = useState(false);

  // const [medAndTherapies, setMedTherapies] = useState({});
  // const [allSymptoms, setAllSymptoms] = useState([]);
  // const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  // const [selectedMedsAndTherapies, setSelectedMedsAndTherapies] = useState({
  //   meds: [],
  //   therapies: [],
  // });
  const [information, setInformation] = useState({
    notes: "",
  });
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [medAndTherapiesData, setmedAndTherapiesData] = useState("");

  const { patientData: patients } = useContext(DataContext);
  const {
    bottomDrawer,
    setBottomDrawer,
    isDrawerOpen,
    setIsDrawerOpen,
    drawerVariant,
  } = useContext(UIContext);
  const dateChangeHandler = (newValue) => {
    setDate(newValue._d);
    if (date <= new Date()) {
      setError({
        show: false,
        message: "Please select a valid date",
      });
    } else {
      setDate(new Date());
      setError({
        show: true,
        message:
          "Select Valid Date, Video taken time cannot be greater than current time.",
      });
    }
  };
  const showToastMessage = (status) => {
    if (status === "success") {
      toast.success("Your upload was successful", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error("Your upload was Failed", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const handleOpen = () => setOpenVideoUpload(true);
  const handleClose = () => {
    setOpenVideoUpload(false);
    setCustomFileName("");
    setDiagnosisDropdown("");
    setSelectedBehaviorNames([]);
    setTherapiesDropdown("");
    // setSecondDropdownValue("");
    setMedications("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    file && setUploadedFile(file);
    getVideoDuration(file);
  };

  const handleCustomFileNameChange = (e) => {
    setCustomFileName(e.target.value);
  };

  const getVideoDuration = (file) => {
    var vid = document.createElement("video");
    var fileURL = URL.createObjectURL(file);
    vid.src = fileURL;
    vid.ondurationchange = function () {
      setFileDuration(this.duration);
    };
  };
  const validateInputs = () => {
    const errors = {};

    if (!uploadedFile) {
      errors.uploadedFile = "Please select a file to upload.";
    }

    if (!date) {
      errors.date = "Please select a valid date.";
    }
    if (date >= new Date()) {
      errors.uploadedFile = "Please select a valid date.";
    }
    if (typeOfUser === "DOCTOR" && !SelectedPatient) {
      errors.SelectedPatient = "Please select a patient.";
    }
    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
  };
  const validateInputsForAttchment = () => {
    const errors = {};

    if (!uploadedFile) {
      errors.uploadedFile = "Please select a file to upload.";
    }

    if (!date) {
      errors.date = "Please select a valid date.";
    }
    if (date >= new Date()) {
      errors.uploadedFile = "Please select a valid date.";
    }

    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
  };
  const resetStates = () => {
    setError({
      show: false,
      message: "",
    });
    setUploadedFile(null);
    setInformation({
      notes: "",
    });
    setDate(new Date());
    SetSelectedPatient("");
    // setAllSymptoms([]);
    // setMedTherapies({ meds: [], therapies: [] });
    // setSelectedMedsAndTherapies({ meds: [], therapies: [] });
  };

  //Here getting Meds and Therapies
  const getMedAndTherapy = async (id) => {
    onSnapshot(
      collection(FirestoreDB, "patientsList", id, "treatments"),
      (querySnapshot) => {
        const meds = [];
        const therapies = [];
        for (let i = 0; i < querySnapshot.docs.length; i++) {
          for (let j = 0; j < querySnapshot.docs[i].data()?.meds?.length; j++) {
            meds.push(querySnapshot.docs[i].data()?.meds[j]);
          }
          for (
            let k = 0;
            k < querySnapshot.docs[i].data()?.therapies?.length;
            k++
          ) {
            therapies.push(querySnapshot.docs[i].data()?.therapies[k]);
          }
        }
        // setMedTherapies({ meds, therapies });
      }
    );
  };

  const addNotification = async (videoID, selectedPatientId) => {
    try {
      if (typeOfUser === "PATIENT") {
        const doctors = user?.doctorDetails;
        doctors?.map(async (each) => {
          if (each.doctorId) {
            const params = {
              title: "Patient uploaded a new video",
              description: "",
              notifyTo: each?.email,
              sentBy: user?.email,
              userName: user?.fullname,
              userPhotoURL: user?.photoURL,
              videoID: videoID,
            };
            await addDoc(
              collection(
                FirestoreDB,
                "notifications",
                each.doctorId,
                "messages"
              ),
              {
                infoMessage: btoa(JSON.stringify(params)),
                notificationSource: "patientVideoUploaded",
                userId: user?.uid,
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        });
      } else if (typeOfUser === "DOCTOR") {
        if (selectedPatientId) {
          const selectedPatientDetails = patients.find(
            (each) => each?.patientId === selectedPatientId
          );
          const params = {
            title: "Healthcare professional uploaded a new video",
            description: "",
            notifyTo: selectedPatientDetails?.patientInfo?.email,
            sentBy: user?.email,
            userName: user?.fullname,
            userPhotoURL: user?.photoURL,
            videoID: videoID,
          };
          await addDoc(
            collection(
              FirestoreDB,
              "notifications",
              selectedPatientDetails.patientId,
              "messages"
            ),
            {
              infoMessage: btoa(JSON.stringify(params)),
              userId: user?.uid,
              notificationSource: "doctorVideoUploaded",
              isEncrypted: false,
              readStatus: false,
              createdDate: Timestamp.now(),
            }
          );
        }
      }
    } catch (e) {
      console.log("error while adding notification", e);
    }
  };

  // const addAttachmentsNotification = async (attachmentID, selectedPatientId) => {
  //   try {
  //     if (typeOfUser === "PATIENT") {
  //       const doctors = user?.doctorDetails;
  //       doctors?.map(async (each) => {
  //         if (each.doctorId) {
  //           const params = {
  //             title: "Patient uploaded a new attachment",
  //             description: "",
  //             notifyTo: each?.email,
  //             sentBy: user?.email,
  //             userName: user?.fullname,
  //             userPhotoURL: user?.photoURL,
  //             attachmentID: attachmentID,
  //           };
  //           await addDoc(
  //             collection(
  //               FirestoreDB,
  //               "notifications",
  //               each.doctorId,
  //               "messages"
  //             ),
  //             {
  //               infoMessage: btoa(JSON.stringify(params)),
  //               notificationSource: "patientAttachmentUploaded",
  //               userId: user?.uid,
  //               isEncrypted: false,
  //               readStatus: false,
  //               createdDate: Timestamp.now(),
  //             }
  //           );
  //         }
  //       });
  //     } else if (typeOfUser === "DOCTOR") {
  //       if (selectedPatientId) {
  //         const selectedPatientDetails = patients.find(
  //           (each) => each?.patientId === selectedPatientId
  //         );
  //         const params = {
  //           title: "Healthcare professional uploaded a new attachment",
  //           description: "",
  //           notifyTo: selectedPatientDetails?.patientInfo?.email,
  //           sentBy: user?.email,
  //           userName: user?.fullname,
  //           userPhotoURL: user?.photoURL,
  //           attachmentID: attachmentID,
  //         };
  //         await addDoc(
  //           collection(
  //             FirestoreDB,
  //             "notifications",
  //             selectedPatientDetails.patientId,
  //             "messages"
  //           ),
  //           {
  //             infoMessage: btoa(JSON.stringify(params)),
  //             userId: user?.uid,
  //             notificationSource: "doctorAttachmentUploaded",
  //             isEncrypted: false,
  //             readStatus: false,
  //             createdDate: Timestamp.now(),
  //           }
  //         );
  //       }
  //     }
  //   } catch (e) {
  //     console.log("error while adding notification", e);
  //   }
  // };

  const videoUploadFile = async (id) => {
    try {
      if (validateInputs().isValid) {
        setIsLoading(true);
        setError({
          show: false,
          message: "",
        });
        const userId = typeOfUser === "DOCTOR" ? id : user?.uid;
        if (uploadedFile && uploadedFile.type === "video/mp4") {
          const videoID = uuidv4();
          const blob = new Blob([uploadedFile], {
            type: "video/mp4",
          });
          const videoStorageRef = ref(
            FirebaseStorage,
            `videoLogs/${userId}/${videoID}/rawVideo/${customFileName
              ? customFileName.charAt(0).toUpperCase() +
              customFileName.slice(1) +
              ".mp4"
              : uploadedFile.name
            }`
          );

          const result = await UploadFileToStorage(videoStorageRef, blob);

          if (result.status) {
            const url = result.data;
            const params = {
              duration: fileDuration || 0,
              fileName: customFileName
                ? customFileName.charAt(0).toUpperCase() +
                customFileName.slice(1) +
                ".mp4"
                : uploadedFile.name,
              fileSize: uploadedFile.size,
              fileType: uploadedFile.type,
              time: Timestamp.now(),
              url: url,
              id: videoID,
              date: date || null,
              userName: user?.fullname,
              userType: typeOfUser,
            };

            await setDoc(
              doc(FirestoreDB, "videoLogs", id, "rawVideos", videoID),
              {
                info: btoa(JSON.stringify(params)),
                isEncrypted: false,
              }
            );

            await setDoc(
              doc(FirestoreDB, "videoLogs", id, "patientNotes", videoID),
              {
                notes: btoa(information?.notes || ""),
                isEncrypted: false,
                isDeleted: false,
              }
            );

            const meds = Array.isArray(Medications) ? Medications.map((med) => ({ drug_name: med })) : [{ drug_name: Medications }]
            const therapies = Array.isArray(therapiesDropdown) ? therapiesDropdown.map((therapy) => ({ therapy_name: therapy })) : [{ therapy_name: therapiesDropdown }]
            // const antecedent =  selectedAntecedents.map((antecedent) => ({antecedents: antecedent}))
            // const consequence =  selectedConsequences.map((consequence) => ({consequences: consequence}))
            // const definition = (relatedCrisisPlans?.map((definition, index) => definition.Definition))

            // const behavior = [{
            //   behaviorNames :
            // }]
            // const behavior =
            //   typeOfUser === "DOCTOR"
            //     ? selectedBehaviors.map((behavior) => ({
            //       behaviorName: behavior.TargetBehavior,
            //       definition: behavior.Definition,
            //       antecedent: behavior.AntecedentInterventions,
            //       consequence: behavior.ConsequenceInterventions,
            //     }))
            //     : selectedPatientBehavior.map((behavior) => ({
            //       behaviorName: behavior.TargetBehavior,
            //       definition: behavior.Definition,
            //       antecedent: behavior.AntecedentInterventions,
            //       consequence: behavior.ConsequenceInterventions,
            //     }));

            const selectedBehaviorsData = selectedBehaviorNames.map(
              (behaviorName) => {
                const relatedCrisisPlan = relatedCrisisPlans?.find(
                  (plan) => plan.TargetBehavior === behaviorName
                );

                return {
                  behaviorName: behaviorName,
                  definition: relatedCrisisPlan?.Definition || "No definition",
                  antecedent: selectedAntecedents || [],
                  consequence: selectedConsequences || [],
                  behaviorId: uuidv4()
                };
              }
            );

            const treatmentParams = {
              // diagnosis: diagnosisDropdown || "",
              // therapy: therapiesDropdown || "",
              // meds: Medications || [],
              // behaviors: behavior || [],
              meds: Medications || [],
              therapies: therapiesDropdown || [],
              diagnosis: diagnosisDropdown || "",
              behaviors: selectedBehaviorsData,
              videoID: videoID,
              createdDate: Timestamp.now(),
            };

            await setDoc(
              doc(FirestoreDB, "videoLogs", id, "patientTreatmentInfo", videoID),
              {
                info: btoa(JSON.stringify(treatmentParams)),
                isEncrypted: false,
              }
              // treatmentParams
            );

            const patientTreatmentsData = {
              isDoctorApproved: typeOfUser === "DOCTOR" ? true : false,
              meds: btoa(JSON.stringify(meds || [])),
              therapies: btoa(JSON.stringify(therapies || [])),
              treatment_name: btoa(diagnosisDropdown || ""),
              userName: user.fullname,
              userType: typeOfUser,
            }

            await setDoc(doc(FirestoreDB, "patientsList", id, "treatments", videoID), patientTreatmentsData);

            addNotification(videoID, id);
            showToastMessage("success");
            handleClose();
          } else {
            toast.error("Error uploading video. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else if (date >= new Date()) {
          toast.error("Please select a valid date", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error("Please upload only mp4 videos!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setIsLoading(false);
        // handleClose();
      } else {
        setError({
          show: true,
          message: "Enter all the above values.",
        });
      }
    } catch (error) {
      console.error("Error message: ", error);
      setIsLoading(false);
      // handleClose();
      showToastMessage("error");
    }
  };

  const attachmentUploadFile = async (id) => {
    try {
      if (validateInputsForAttchment().isValid) {
        setIsLoading(true);
        setError({
          show: false,
          message: "",
        });
        const userId = typeOfUser === "DOCTOR" ? id : user?.uid;
        // const userId = user?.uid;
        if (
          uploadedFile &&
          (uploadedFile.type === "application/pdf" ||
            uploadedFile.type === "application/vnd.ms-excel" ||
            uploadedFile.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            uploadedFile.type === "text/csv")
        ) {
          const attachmentID = uuidv4();
          const blob = new Blob([uploadedFile], {
            type: uploadedFile.type,
          });

          const videoStorageRef = ref(
            FirebaseStorage,
            `historicalData/${userId}/${attachmentID}/attachments/${uploadedFile.name}`
          );

          const result = await UploadFileToStorage(videoStorageRef, blob);

          if (result.status) {
            const url = result.data;
            const params = {
              // duration: fileDuration || 0,
              fileName: uploadedFile.name,
              fileSize: uploadedFile.size,
              fileType: uploadedFile.type,
              url: url,
              id: attachmentID,
              userName: user?.fullname,
              userType: typeOfUser,
            };

            // added this logic to prevent isPrivate only for doctor
            const docData = {
              info: btoa(JSON.stringify(params)),
              isEncrypted: false,
              notes: btoa(information?.notes || ""),
              isDeleted: false,
              createdAt: Timestamp.now(),
              selectedDate: date || null,
              isPrivate: typeOfUser === "DOCTOR" ? isPrivate : false, // Conditionally add isPrivate if typeOfUser is DOCTOR
              isPrivateAll: isPrivateAll,
              uploadedId: user.uid,
            };

            // Conditionally add isPrivate if typeOfUser is DOCTOR
            // if (typeOfUser === "DOCTOR") {
            //   docData.isPrivate = true;
            // }

            await setDoc(
              doc(
                FirestoreDB,
                "historicalData",
                id,
                "attachments",
                attachmentID
              ),
              docData
              // {
              //   info: btoa(JSON.stringify(params)),
              //   isPrivate: isPrivate,
              //   isEncrypted: false,
              //   notes: btoa(information?.notes || ""),
              //   isDeleted: false,
              //   createdAt: Timestamp.now(),
              //   selectedDate: date || null,
              // }
            );

            // await setDoc(
            //   doc(FirestoreDB, "historicaldata", id, "patientNotes", attachmentID),
            //   {
            //     notes: btoa(information?.notes || ""),
            //     isEncrypted: false,
            //     isDeleted: false,
            //   }
            // );
            // addAttachmentsNotification(attachmentID, id);
            showToastMessage("success");
            handleClose();
          } else {
            toast.error("Error uploading file. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else if (date >= new Date()) {
          toast.error("Please select a valid date", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error("Please upload only documents!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setIsLoading(false);
        // handleClose();
      } else {
        setError({
          show: true,
          message: "Enter all the above values.",
        });
      }
    } catch (error) {
      console.error("Error message: ", error);
      setIsLoading(false);
      showToastMessage("error");
    }
  };

  // const getSymptoms = async (patientID) => {
  //   const symptomsCollectionRef = await getDocs(
  //     collection(FirestoreDB, "patientsList", patientID, "treatments")
  //   );
  //   const symptomsArray = [];
  //   for (let i = 0; i < symptomsCollectionRef.docs.length; i++) {
  //     symptomsArray.push(symptomsCollectionRef.docs[i].data().treatment_name);
  //   }
  //   // setAllSymptoms(symptomsArray);
  // };

  const videoSaveHandler = () => {
    if (typeOfUser === "DOCTOR") {
      videoUploadFile(SelectedPatient);
    } else {
      videoUploadFile(user.uid);
    }
  };

  const attachmentSaveHandler = () => {
    if (typeOfUser === "DOCTOR") {
      attachmentUploadFile(SelectedPatient);
    } else {
      attachmentUploadFile(user.uid);
    }
  };
  console.log("selectedPatient", SelectedPatient);
  useEffect(() => {
    !openVideoUpload && resetStates();
    if (typeOfUser === "PATIENT" && user?.uid) {
      getMedAndTherapy(user?.uid);
      // getSymptoms(user?.uid);
    }
  }, [openVideoUpload]);

  useEffect(() => {
    if (patients) {
      const dataArray = patients?.map((each) => ({
        ...each?.patientInfo,
        id: each?.patientId,
      }));
      setPatientData(dataArray);
    }
  }, [patients]);

  const handlePatientSelection = (e) => {
    SetSelectedPatient(e.target.value);
    setIsPatientSelected(true);
    // setMedTherapies([]);
    getMedAndTherapy(e.target.value);
    // getSymptoms(e.target.value);
  };

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        if (user) {
          const treatmentsData = await fetchPatientTreatments(user.uid);

          if (treatmentsData) {
            setPatientTreatementsData(treatmentsData);
          } else {
            console.log("No treatments found or an error occurred.");
          }
        } else {
          console.log("No user is logged in.");
        }
      } catch (error) {
        console.log("Failed to fetch treatments.");
      } finally {
        // setLoading(false);
      }
    };

    fetchTreatments();
  }, []);

  useEffect(() => {
    if (typeOfUser === 'DOCTOR') {
      if (SelectedPatient) {
        if (
          SelectedPatient &&
          currentPatientData &&
          currentPatientData?.id === SelectedPatient
        ) {
          setmedAndTherapiesData(currentPatientData);
        } else {
          fetchOnePatientData(SelectedPatient);
        }
      }
      if (SelectedPatient && attachmentsData?.length > 0) {
        const filteredAttachments = attachmentsData?.filter(
          (attachment) => attachment?.patientId === SelectedPatient
        );
        const allSummaries = filteredAttachments[0].attachmentData?.map(
          (attachment) => attachment?.docData?.summary
        );
        setFilteredAttachmentsData(allSummaries);
      }
    } else if (typeOfUser === "PATIENT" && user?.uid && attachmentsData?.length > 0) {
      const filterPatientAttachmentsSummary = attachmentsData?.map(
        (doc) => doc?.docData?.summary
      );
      setPatientSummaryData(filterPatientAttachmentsSummary);
    }
  }, [SelectedPatient, currentPatientData, attachmentsData]);

  const behaviorNames = filteredAttachmentsData
    ?.flatMap(
      (data) =>
        data?.BehavioralGoalsAndBaselines?.map((goal) => goal?.BehaviorName) ||
        []
    )
    .filter((behaviorName) => behaviorName !== undefined)
    .filter((value, index, self) => self.indexOf(value) === index);

  const behaviorRelatedData = filteredAttachmentsData
    ?.flatMap((data) => data?.CrisisPlanSummary || [])
    .filter(
      (crisis) =>
        (crisis?.TargetBehavior &&
          crisis?.Definition &&
          crisis?.AntecedentInterventions) ||
        crisis?.ConsequenceInterventions
    )
    .filter((value, index, self) => self.indexOf(value) === index);

  const patientBehaviorName = patientSummaryData
    ?.flatMap(
      (data) =>
        data?.BehavioralGoalsAndBaselines?.map((goal) => goal?.BehaviorName) ||
        []
    )
    .filter((behaviorName) => behaviorName !== undefined)
    .filter((value, index, self) => self.indexOf(value) === index);

  const patientRelatedBehaviorData = patientSummaryData
    ?.flatMap((data) => data?.CrisisPlanSummary || [])
    .filter(
      (crisis) =>
        (crisis?.TargetBehavior &&
          crisis?.Definition &&
          crisis?.AntecedentInterventions) ||
        crisis?.ConsequenceInterventions
    )
    .filter((value, index, self) => self.indexOf(value) === index);

  const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

  const selectedBehaviors = behaviorRelatedData
    ?.filter((behavior) =>
      selectedBehaviorNames?.some(
        (name) => cleanString(name) === cleanString(behavior.TargetBehavior)
      )
    )
    .filter((value, index, self) =>
      index === self.findIndex((t) => (
        cleanString(t.TargetBehavior) === cleanString(value.TargetBehavior)
      ))
    );

  // const selectedPatientBehavior = patientRelatedBehaviorData.filter(
  //   (behavior) =>
  //     selectedBehaviorNames.some(
  //       (name) => cleanString(name) === cleanString(behavior.TargetBehavior)
  //     )
  // );
  const selectedPatientBehavior = patientRelatedBehaviorData
    ?.filter((behavior) =>
      selectedBehaviorNames.some(
        (name) => cleanString(name) === cleanString(behavior.TargetBehavior)
      )
    )
    .filter((value, index, self) =>
      index === self.findIndex((t) => (
        cleanString(t.TargetBehavior) === cleanString(value.TargetBehavior)
      ))
    );

  const handleBehaviorChange = (event) => {
    setSelectedBehaviorNames(event.target.value);
  };

  const fetchAttachmentData = async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      const fetchedAttachmentData = await fetchAllAttachmentsByUser(
        user?.uid,
        typeOfUser
      );
      setAttachmentsData(fetchedAttachmentData?.data);
    } catch (error) {
      console.log("Error fetching documents:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    user && fetchAttachmentData();
  }, [user]);

  const [selectedAntecedents, setSelectedAntecedents] = useState([]);
  const [selectedConsequences, setSelectedConsequences] = useState([]);

  const handleAntecedentChange = (event) => {
    const { value } = event.target;
    setSelectedAntecedents(typeof value === "string" ? value.split(",") : value);
  };

  const handleConsequenceChange = (event) => {
    const { value } = event.target;
    setSelectedConsequences(typeof value === "string" ? value.split(",") : value);
  };

  const selectedBehaviorIDs = behaviorData[0]?.BehavioralGoalsAndBaselines?.filter((b) => {
    return selectedBehaviorNames?.includes(b.BehaviorName);
  }).map((b) => {
    return b.BehaviorID;
  });


  const relatedCrisisPlans = behaviorData[0]?.CrisisPlanSummary?.filter((cp) => {
    return selectedBehaviorIDs.includes(cp.BehaviorID);
  });


  return (
    <>
      <Box>
        <BootstrapTooltip title="Upload Video" placement="left">
          <Fab
            size="large"
            className="videoUploadButton"
            onClick={handleOpen}
            color="primary"
          >
            <FileUploadSharp />
          </Fab>
        </BootstrapTooltip>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            textAlign: "left",
            gap: 10,
          }}
        >
          <Dialog
            open={openVideoUpload}
            onClose={handleClose}
            className="videoUploadDialog"
          >
            <DialogTitle sx={{ m: 0, pr: "10%", pl: "10%" }}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                // textColor="secondary"
                indicatorColor="secondary"
                className="tabs"
              >
                <Tab
                  label="Upload Video"
                  icon={<VideoFile />}
                  iconPosition="end"
                />
                <Tab
                  label="Upload Attachments"
                  iconPosition="end"
                  icon={<Attachment />}
                />
              </Tabs>
              <BrandLogo newline={false} />
            </DialogTitle>
            <DialogContent className="dialogContentBox">
              {selectedTab === 0 && (
                <>
                  {/* {(allSymptoms?.length > 0 && typeOfUser === "PATIENT") || */}
                  {typeOfUser === "DOCTOR" ? (
                    <>
                      <Typography
                        gutterBottom
                        sx={{ textAlign: "center", mt: "0.3rem" }}
                      >
                        Upload video for review
                      </Typography>
                      <Box className="flexBox flexCenter">
                        <BootstrapTooltip
                          title="choose a file"
                          placement="right"
                        >
                          <Fab
                            variant="extended"
                            component="label"
                            color="secondary"
                            size="small"
                          >
                            <CloudUploadRounded />
                            <input
                              hidden
                              accept="video/*"
                              multiple
                              type="file"
                              onChange={handleFileChange}
                              disabled={isLoading}
                            />
                          </Fab>
                        </BootstrapTooltip>
                      </Box>
                      <Typography
                        className="flexBox flexCenter"
                        color={"GrayText"}
                      >
                        {uploadedFile?.name && (
                          <>
                            {customFileName
                              ? customFileName.charAt(0).toUpperCase() +
                              customFileName.slice(1)
                              : uploadedFile?.name}
                            {uploadedFile?.name && (
                              <CheckCircleOutlineRounded color="success" />
                            )}
                          </>
                        )}
                      </Typography>
                      <Typography
                        gutterBottom
                        className="dateInput"
                        style={{ marginBottom: "8px" }}
                      >
                        * File Name
                      </Typography>
                      <TextField
                        variant="outlined"
                        label="Custom File Name"
                        value={customFileName}
                        onChange={handleCustomFileNameChange}
                        disabled={isLoading}
                        className="fullWidth"
                      />
                      <Typography gutterBottom className="dateInput">
                        * Date
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          disableFuture
                          className="fullWidth"
                          value={date}
                          disabled={isLoading}
                          onChange={dateChangeHandler}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <Typography gutterBottom>* Patients List</Typography>
                      <Select
                        value={SelectedPatient}
                        className="fullWidth"
                        onChange={handlePatientSelection}
                        disabled={isLoading}
                      >
                        {patientData?.map((data, index) => {
                          return (
                            <MenuItem key={index} value={data?.id}>
                              {data?.fullname
                                ?.split(/\s+/)
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                )
                                .join(" ")}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <Typography
                        gutterBottom
                        className="dateInput"
                        style={{ marginBottom: "8px" }}
                      >
                        * Diagnosis
                      </Typography>
                      {medAndTherapiesData?.data?.length > 0 ? (
                        <>
                          <Select
                            value={diagnosisDropdown}
                            className="fullWidth"
                            onChange={(e) =>
                              setDiagnosisDropdown(e.target.value)
                            }
                            disabled={isLoading || !isPatientSelected}
                          >
                            {medAndTherapiesData.data.map((item, i) => (
                              <MenuItem
                                key={`${i}`}
                                value={item?.treatment_name}
                              >
                                {item?.treatment_name}
                              </MenuItem>
                            ))}
                            {/* <MenuItem
                              value="addNew"
                              onClick={() => setShowTextField(true)}
                            >
                              + Add New Option
                            </MenuItem> */}
                          </Select>
                          {showTextField && (
                            <TextField
                              variant="outlined"
                              label="New Option"
                              value={newOptionValue}
                              onChange={(e) =>
                                setNewOptionValue(e.target.value)
                              }
                              onBlur={() => {
                                if (newOptionValue.trim()) {
                                  setmedAndTherapiesData((prevData) => ({
                                    ...prevData,
                                    data: [
                                      ...prevData.data,
                                      { treatment_name: newOptionValue },
                                    ],
                                  }));
                                  setDiagnosisDropdown(newOptionValue);
                                }
                                setShowTextField(false);
                              }}
                              className="fullWidth"
                            />
                          )}
                        </>
                      ) : (
                        <TextField
                          variant="outlined"
                          label="Diagnosis"
                          value={diagnosisDropdown}
                          onChange={(e) => setDiagnosisDropdown(e.target.value)}
                          disabled={isLoading || !isPatientSelected}
                          className="fullWidth"
                        />
                      )}
                      <Typography gutterBottom>* Type of therapy</Typography>
                      {medAndTherapiesData?.data?.length > 0 ? (
                        <>
                          <Select
                            value={therapiesDropdown}
                            className="fullWidth"
                            onChange={(e) =>
                              setTherapiesDropdown(e.target.value)
                            }
                            disabled={isLoading || !isPatientSelected}
                          >
                            {medAndTherapiesData.data.map((item, i) =>
                              item?.therapies?.map((therapy, j) => (
                                <MenuItem
                                  key={`${i}-${j}`}
                                  value={therapy?.therapy_name}
                                >
                                  {therapy?.therapy_name}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </>
                      ) : (
                        <TextField
                          variant="outlined"
                          label="Therapies"
                          value={therapiesDropdown}
                          onChange={(e) => setTherapiesDropdown(e.target.value)}
                          disabled={isLoading || !isPatientSelected}
                          className="fullWidth"
                        />
                      )}
                      <Typography
                        gutterBottom
                        className="dateInput"
                        style={{ marginBottom: "8px" }}
                      >
                        * Medication
                      </Typography>
                      <TextField
                        variant="outlined"
                        label="Medications"
                        value={Medications}
                        onChange={(e) => setMedications(e.target.value)}
                        disabled={isLoading || !isPatientSelected}
                        className="fullWidth"
                      />
                      <div>
                        {/* Behavior Selection Dropdown */}
                        <FormControl fullWidth variant="outlined" margin="normal">
                          <InputLabel>Classify Behaviors</InputLabel>
                          <Select
                            multiple
                            value={selectedBehaviorNames}
                            onChange={handleBehaviorChange}
                            input={<OutlinedInput label="Classify Behaviors" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {behaviorData[0]?.BehavioralGoalsAndBaselines?.map((behavior, index) => (
                              // <MenuItem key={behavior.BehaviorID} value={behavior.BehaviorName}>
                              //   <Checkbox checked={selectedBehaviors.indexOf(behavior.BehaviorName) > -1} />
                              //   <ListItemText primary={behavior.BehaviorName} />
                              // </MenuItem>
                              <MenuItem
                                key={`behavior-${index}`}
                                value={behavior.BehaviorName}
                              >
                                <Checkbox
                                  checked={selectedBehaviorNames.includes(
                                    behavior.BehaviorName
                                  )}
                                />
                                <ListItemText primary={behavior.BehaviorName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <Box sx={{ paddingTop: 1 }}>
                          {selectedBehaviorNames?.length > 0 ? (
                            relatedCrisisPlans?.length > 0 ?
                              (relatedCrisisPlans?.map((definition, index) => (
                                <Box key={index} sx={{ marginBottom: 1 }}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Behavior definition:{" "}
                                    {`${definition.TargetBehavior}`}
                                  </Typography>
                                  <TextareaAutosize
                                    minRows={1}
                                    maxRows={3}
                                    readOnly
                                    style={{ width: "100%" }}
                                    value={definition.Definition}
                                  />
                                </Box>
                              ))
                              ) : (
                                <p>
                                  No matching behavior found in Crisis Plan Summary.
                                </p>
                              )
                          ) : null}
                        </Box>

                        {/* Antecedent Interventions Multi-Select Checkboxes */}
                        <FormControl fullWidth variant="outlined" margin="normal">
                          <InputLabel>Antecedent Interventions</InputLabel>
                          <Select
                            multiple
                            value={selectedAntecedents}
                            onChange={handleAntecedentChange}
                            input={<OutlinedInput label="Antecedent Interventions" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {[
                              ...new Set(
                                relatedCrisisPlans?.flatMap((cp) => cp.AntecedentInterventions)
                              ),
                            ]?.map((intervention, index) => (
                              <MenuItem key={index} value={intervention}>
                                <Checkbox checked={selectedAntecedents.indexOf(intervention) > -1} />
                                <ListItemText primary={intervention} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* Consequence Interventions Multi-Select Checkboxes */}
                        <FormControl fullWidth variant="outlined" margin="normal">
                          <InputLabel>Consequence Interventions</InputLabel>
                          <Select
                            multiple
                            value={selectedConsequences}
                            onChange={handleConsequenceChange}
                            input={<OutlinedInput label="Consequence Interventions" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {/* {relatedCrisisPlans?.flatMap((cp) => cp.ConsequenceInterventions) */}
                            {[
                              ...new Set(
                                relatedCrisisPlans?.flatMap((cp) => cp.ConsequenceInterventions)
                              ),
                            ]?.map((intervention, index) => (
                              <MenuItem key={index} value={intervention}>
                                <Checkbox checked={selectedConsequences.indexOf(intervention) > -1} />
                                <ListItemText primary={intervention} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <Typography gutterBottom>
                        * Description events leading up to this behavior
                      </Typography>
                      <TextField
                        className="notes fullWidth fullHeight"
                        variant="outlined"
                        size="medium"
                        label="Please input descrption..."
                        sx={{ my: 0.5 }}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            minHeight: "55px", // Adjust the height as needed
                          },
                        }}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        disabled={isLoading || !isPatientSelected}
                      />
                      {error.show && (
                        <Typography color={"red"} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}
                      <DialogActions
                        className={`fullWidth flexBox flexWrap dialogActionsFixed`}
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: "10%",
                          paddingLeft: "10%",
                          gap: 1,
                          mt: 3,
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          sx={{
                            width: "48%",
                            fontFamily: "Lato",
                            textTransform: "capitalize",
                            color: "var(--clr-theme-purple)",
                            borderColor: "var(--clr-theme-purple)",
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            alignItems: "center",
                            backgroundColor:
                              "var(--clr-theme-purple)!important",
                            color: "white",
                            width: "48%",
                            fontFamily: "Lato",
                            textTransform: "capitalize",
                            "&:hover": {
                              backgroundColor: isPatientSelected
                                ? "var(--clr-theme-purple-50)"
                                : "disabled",
                            },
                            "&:disabled": {
                              color: "var(--clr-theme-white) !important",
                              backgroundColor: "rgb(211, 211, 211) !important",
                            },
                          }}
                          variant="contained"
                          onClick={videoSaveHandler}
                          disabled={isLoading}
                        // disabled={selectedSymptoms?.length <= 0}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>

                        {isLoading && <Progress />}
                      </DialogActions>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        gap: 1,
                      }}
                    >
                      <Box className="fullWidth flexBox flexColumn flexCenter">
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            mt: "1rem",
                            fontFamily: "Lato",
                          }}
                        >
                          Upload video for review
                        </Typography>
                        <Box className="flexBox flexCenter">
                          <BootstrapTooltip
                            title="choose a file"
                            placement="right"
                          >
                            <Fab
                              variant="extended"
                              component="label"
                              color="secondary"
                              size="small"
                            >
                              <CloudUploadRounded />
                              <input
                                hidden
                                accept="video/*"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                                disabled={isLoading}
                              />
                            </Fab>
                          </BootstrapTooltip>
                        </Box>
                        <Typography
                          className="flexBox flexCenter"
                          color={"GrayText"}
                        >
                          {uploadedFile?.name && (
                            <>
                              {customFileName
                                ? customFileName.charAt(0).toUpperCase() +
                                  customFileName.slice(1)
                                : uploadedFile?.name}
                              {uploadedFile?.name && (
                                <CheckCircleOutlineRounded color="success" />
                              )}
                            </>
                          )}
                        </Typography>
                      </Box>
                      <Typography
                        gutterBottom
                        className="dateInput"
                        style={{ marginBottom: "8px" }}
                      >
                        * File Name
                      </Typography>
                      <TextField
                        variant="outlined"
                        label="Custom File Name"
                        value={customFileName}
                        onChange={handleCustomFileNameChange}
                        disabled={isLoading}
                        className="fullWidth"
                      />

                      <Typography gutterBottom>* Date</Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          className="fullWidth"
                          value={date}
                          onChange={dateChangeHandler}
                          disabled={isLoading}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <Typography
                        gutterBottom
                        className="dateInput"
                        style={{ marginBottom: "8px" }}
                      >
                        * Diagnosis
                      </Typography>
                      {patientTreatmentsData.length > 0 ? (
                        <Select
                          value={diagnosisDropdown}
                          className="fullWidth"
                          onChange={(e) => setDiagnosisDropdown(e.target.value)}
                          disabled={isLoading}
                        >
                          {patientTreatmentsData.map((item, i) => (
                            <MenuItem key={`${i}`} value={item?.treatment_name}>
                              {item?.treatment_name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField
                          variant="outlined"
                          label="Diagnosis"
                          value={diagnosisDropdown}
                          onChange={(e) => setDiagnosisDropdown(e.target.value)}
                          disabled={isLoading}
                          className="fullWidth"
                        />
                      )}
                      <Typography gutterBottom>* Type of therapy</Typography>
                      {patientTreatmentsData?.length > 0 ? (
                        <>
                          <Select
                            value={therapiesDropdown}
                            className="fullWidth"
                            onChange={(e) =>
                              setTherapiesDropdown(e.target.value)
                            }
                            disabled={isLoading}
                          >
                            {patientTreatmentsData.map((item, i) =>
                              item?.therapies?.map((therapy, j) => (
                                <MenuItem
                                  key={`${i}-${j}`}
                                  value={therapy?.therapy_name}
                                >
                                  {therapy?.therapy_name}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </>
                      ) : (
                        <TextField
                          variant="outlined"
                          label="Therapies"
                          value={therapiesDropdown}
                          onChange={(e) => setTherapiesDropdown(e.target.value)}
                          // disabled={isLoading || !isPatientSelected}
                          className="fullWidth"
                        />
                      )}
                      <Typography
                        gutterBottom
                        className="dateInput"
                        style={{ marginBottom: "8px" }}
                      >
                        * Medication
                      </Typography>
                      <TextField
                        variant="outlined"
                        label="Medications"
                        value={Medications}
                        onChange={(e) => setMedications(e.target.value)}
                        disabled={isLoading}
                        className="fullWidth"
                      />
                      {/* <Typography gutterBottom>* Classify Behaviour</Typography>
                      <FormControl fullWidth>
                        <Select
                          multiple
                          value={selectedBehaviorNames}
                          onChange={handleBehaviorChange}
                          renderValue={(selected) => selected.join(",")}
                          disabled={isLoading}
                        >
                          {patientBehaviorName?.length > 0 &&
                            patientBehaviorName.map((behavior, index) => (
                              <MenuItem
                                key={`behavior-${index}`}
                                value={behavior}
                              >
                                <Checkbox
                                  checked={selectedBehaviorNames.includes(
                                    behavior
                                  )}
                                />
                                <ListItemText primary={behavior} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <Box sx={{ paddingTop: 1, width: "100%" }}>
                        {selectedBehaviorNames.length > 0 ? (
                          selectedPatientBehavior.length > 0 ? (
                            selectedPatientBehavior.map((behavior, index) => (
                              <Box key={index}>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  Behavior definition:{" "}
                                  {`${behavior.TargetBehavior}`}
                                </Typography>
                                <TextareaAutosize
                                  readOnly
                                  minRows={1}
                                  maxRows={3}
                                  style={{
                                    width: "100%",
                                    overflowY: "auto",
                                    resize: "none",
                                    padding: "8px",
                                  }}
                                  value={behavior.Definition}
                                />
                              </Box>
                            ))
                          ) : (
                            <p>
                              No matching behavior found in Crisis Plan Summary.
                            </p>
                          )
                        ) : null}
                      </Box> 
                       <Typography gutterBottom>
                        * All interventions that were used
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        {selectedBehaviorNames.length > 0 ? (
                          selectedPatientBehavior.length > 0 ? (
                            selectedPatientBehavior.map((behavior, index) => (
                              <Box key={index}>
                                {behavior?.AntecedentInterventions ? (
                                  <>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      Antecedent: {`${behavior.TargetBehavior}`}
                                    </Typography>
                                    <TextareaAutosize
                                      readOnly
                                      minRows={2}
                                      maxRows={5}
                                      style={{
                                        width: "100%",
                                        overflowY: "auto",
                                        resize: "none",
                                        padding: "8px",
                                      }}
                                      value={behavior.AntecedentInterventions}
                                    />
                                  </>
                                ) : (
                                  <Typography gutterBottom>
                                    No antecedent found.
                                  </Typography>
                                )}

                                {behavior?.ConsequenceInterventions ? (
                                  <>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      Consequence:{" "}
                                      {`${behavior.TargetBehavior}`}
                                    </Typography>
                                    <TextareaAutosize
                                      readOnly
                                      minRows={2}
                                      maxRows={5}
                                      style={{
                                        width: "100%",
                                        overflowY: "auto",
                                        resize: "none",
                                        padding: "8px",
                                      }}
                                      value={behavior.ConsequenceInterventions}
                                    />
                                  </>
                                ) : (
                                  <Typography gutterBottom>
                                    No consequence found.
                                  </Typography>
                                )}
                              </Box>
                            ))
                          ) : (
                            <Typography>
                              No matching behavior found in Crisis Plan Summary.
                            </Typography>
                          )
                        ) : (
                          <TextField
                            variant="outlined"
                            label="Antecedent and Consequence"
                            // value={Medications}
                            // onChange={(e) => setMedications(e.target.value)}
                            disabled={isLoading || !isPatientSelected}
                            className="fullWidth"
                          />
                        )}
                      </Box>*/}
  {/* console.log("medAndTherapiesData", medAndTherapiesData) */}

                      {/* <div> */}
                        {/* Behavior Selection Dropdown */}
                        <FormControl fullWidth variant="outlined" margin="normal">
                          <InputLabel>Classify Behaviors</InputLabel>
                          <Select
                            multiple
                            value={selectedBehaviorNames}
                            onChange={handleBehaviorChange}
                            input={<OutlinedInput label="Classify Behaviors" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {behaviorData[0]?.BehavioralGoalsAndBaselines?.map((behavior, index) => (
                              // <MenuItem key={behavior.BehaviorID} value={behavior.BehaviorName}>
                              //   <Checkbox checked={selectedBehaviors.indexOf(behavior.BehaviorName) > -1} />
                              //   <ListItemText primary={behavior.BehaviorName} />
                              // </MenuItem>
                              <MenuItem
                                key={`behavior-${index}`}
                                value={behavior.BehaviorName}
                              >
                                <Checkbox
                                  checked={selectedBehaviorNames.includes(
                                    behavior.BehaviorName
                                  )}
                                />
                                <ListItemText primary={behavior.BehaviorName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <Box sx={{ paddingTop: 1, width: '100%' }}>
                          {selectedBehaviorNames?.length > 0 ? (
                            relatedCrisisPlans?.length > 0 ?
                              (relatedCrisisPlans?.map((definition, index) => (
                                <Box key={index} sx={{ marginBottom: 1 }}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Behavior definition:{" "}
                                    {`${definition.TargetBehavior}`}
                                  </Typography>
                                  <TextareaAutosize
                                    minRows={1}
                                    maxRows={3}
                                    readOnly
                                    style={{ width: "100%" }}
                                    value={definition.Definition}
                                  />
                                </Box>
                              ))
                              ) : (
                                <p>
                                  No matching behavior found in Crisis Plan Summary.
                                </p>
                              )
                          ) : null}
                        </Box>

                        {/* Antecedent Interventions Multi-Select Checkboxes */}
                        <FormControl fullWidth variant="outlined" margin="normal">
                          <InputLabel>Antecedent Interventions</InputLabel>
                          <Select
                            multiple
                            value={selectedAntecedents}
                            onChange={handleAntecedentChange}
                            input={<OutlinedInput label="Antecedent Interventions" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {[
                              ...new Set(
                                relatedCrisisPlans?.flatMap((cp) => cp.AntecedentInterventions)
                              ),
                            ]?.map((intervention, index) => (
                              <MenuItem key={index} value={intervention}>
                                <Checkbox checked={selectedAntecedents.indexOf(intervention) > -1} />
                                <ListItemText primary={intervention} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>


                        {/* Consequence Interventions Multi-Select Checkboxes */}
                        <FormControl fullWidth variant="outlined" margin="normal">
                          <InputLabel>Consequence Interventions</InputLabel>
                          <Select
                            multiple
                            value={selectedConsequences}
                            onChange={handleConsequenceChange}
                            input={<OutlinedInput label="Consequence Interventions" />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {/* {relatedCrisisPlans?.flatMap((cp) => cp.ConsequenceInterventions) */}
                            {[
                              ...new Set(
                                relatedCrisisPlans?.flatMap((cp) => cp.ConsequenceInterventions)
                              ),
                            ]?.map((intervention, index) => (
                              <MenuItem key={index} value={intervention}>
                                <Checkbox checked={selectedConsequences.indexOf(intervention) > -1} />
                                <ListItemText primary={intervention} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      {/* </div> */}

                      <Typography gutterBottom>
                        * Description events leading up to this behavior
                      </Typography>
                      {/* <TextField
                      className="notes fullWidth fullHeight"
                      variant="outlined"
                      size="medium"
                      label="Please input notes..."
                      sx={{ my: 0.5 }}
                      multiline
                      rows={8}
                      onChange={(e) =>
                        setInformation({
                          ...information,
                          notes: e.target.value,
                        })
                      }
                      disabled={isLoading}
                    /> */}
                      <TextField
                        className="notes fullWidth fullHeight"
                        variant="outlined"
                        size="medium"
                        label="Please input descrption..."
                        sx={{ my: 0.5 }}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            minHeight: "55px", // Adjust the height as needed
                          },
                        }}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        disabled={isLoading}
                      />
                      {error.show && (
                        <Typography color={"red"} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}
                      <Box className="flexBox flexCenter" gap={1}>
                        <Info sx={{ color: "var(--clr-icon-lightgray)" }} />
                        <Typography
                          color="var(--clr-icon-lightgray)"
                          sx={{ fontFamily: "Karla" }}
                        >
                          Treatment information will be updated after doctor
                          approval.
                        </Typography>
                      </Box>
                      <Box
                        className={`fullWidth flexBox flexCenter flexWrap dialogActionsFixed`}
                        gap={2}
                        mt={3}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          sx={{
                            width: "30%",
                            fontFamily: "Lato",
                            textTransform: "capitalize",
                            color: "var(--clr-theme-purple)",
                            borderColor: "var(--clr-theme-purple)",
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="extended"
                          onClick={videoSaveHandler}
                          sx={{
                            width: "30%",
                            // alignSelf: "end",
                            backgroundColor:
                              "var(--clr-theme-purple)!important",
                            color: "var(--clr-theme-white)",
                            "&:disabled": {
                              color: "var(--clr-theme-white) !important",
                              backgroundColor: "rgb(211, 211, 211) !important",
                            },
                          }}
                          disabled={isLoading}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>
                        {isLoading && <Progress />}
                      </Box>
                    </Box>
                  )}
                </>
              )}
              {selectedTab === 1 && (
                <>
                  {typeOfUser === "DOCTOR" ? (
                    <>
                      <Typography
                        gutterBottom
                        sx={{ textAlign: "center", mt: "0.3rem" }}
                      >
                        Upload attachment for review
                      </Typography>
                      <Box className="flexBox flexCenter">
                        <BootstrapTooltip
                          title="choose a file"
                          placement="right"
                        >
                          <Fab
                            variant="extended"
                            component="label"
                            color="secondary"
                            size="small"
                          >
                            <Attachment />
                            <input
                              hidden
                              accept=".pdf,.csv,.xls,.xlsx,.doc,.docx"
                              multiple
                              type="file"
                              onChange={handleFileChange}
                              disabled={isLoading}
                            />
                          </Fab>
                        </BootstrapTooltip>
                      </Box>
                      <Typography
                        className="flexBox flexCenter"
                        color={"GrayText"}
                      >
                        {uploadedFile?.name && (
                          <>
                            {uploadedFile?.name}
                            <CheckCircleOutlineRounded color="success" />
                          </>
                        )}
                      </Typography>
                      <Typography gutterBottom className="dateInput">
                        * Date:
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          disableFuture
                          className="fullWidth"
                          value={date}
                          disabled={isLoading}
                          onChange={dateChangeHandler}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      {typeOfUser === "DOCTOR" ? (
                        <>
                          <Typography gutterBottom>* Patients List:</Typography>
                          <Select
                            value={SelectedPatient}
                            className="fullWidth"
                            onChange={(e) => {
                              SetSelectedPatient(e.target.value);
                              // setMedTherapies([]);
                              // getMedAndTherapy(e.target.value);
                              // getSymptoms(e.target.value);
                            }}
                            disabled={isLoading}
                          >
                            {patientData.map((data, index) => {
                              return (
                                <MenuItem key={index} value={data.id}>
                                  {data?.fullname
                                    ?.split(/\s+/)
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1).toLowerCase()
                                    )
                                    .join(" ")}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </>
                      ) : (
                        ""
                      )}

                      <Typography gutterBottom>Notes:</Typography>
                      <TextField
                        className="notes fullWidth fullHeight"
                        variant="outlined"
                        size="medium"
                        label="Please input notes..."
                        sx={{ my: 0.5 }}
                        multiline
                        rows={6}
                        InputProps={{
                          style: {
                            minHeight: "55px", // Adjust the height as needed
                          },
                        }}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        disabled={isLoading}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPrivate}
                            onChange={(e) => {
                              setIsPrivate(e.target.checked);
                            }}
                          />
                        }
                        label="Private to patient"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPrivateAll}
                            onChange={(e) => {
                              setIsPrivateAll(e.target.checked);
                            }}
                          />
                        }
                        label="Private to doctors"
                      />
                      {console.log("isprivateAll", isPrivateAll)}
                      {error.show && (
                        <Typography color={"red"} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}
                      <DialogActions
                        className="fullWidth flexBox flexWrap"
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1,
                          mt: 3,
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          sx={{
                            width: "48%",
                            fontFamily: "Lato",
                            textTransform: "capitalize",
                            color: "var(--clr-theme-purple)",
                            borderColor: "var(--clr-theme-purple)",
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            alignItems: "center",
                            backgroundColor:
                              "var(--clr-theme-purple)!important",
                            color: "white",
                            width: "48%",
                            fontFamily: "Lato",
                            textTransform: "capitalize",
                          }}
                          variant="contained"
                          onClick={attachmentSaveHandler}
                          disabled={isLoading}
                        // disabled={selectedSymptoms?.length <= 0}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>

                        {isLoading && <Progress />}
                      </DialogActions>
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        gap: 1,
                      }}
                    >
                      <Box className="fullWidth flexBox flexColumn flexCenter">
                        <Typography
                          gutterBottom
                          sx={{
                            textAlign: "center",
                            mt: "1rem",
                            fontFamily: "Lato",
                          }}
                        >
                          Upload attachment for review
                        </Typography>
                        <Box className="flexBox flexCenter">
                          <BootstrapTooltip
                            title="choose a file"
                            placement="right"
                          >
                            <Fab
                              variant="extended"
                              component="label"
                              color="secondary"
                              size="small"
                            >
                              <CloudUploadRounded />
                              <input
                                hidden
                                accept=".pdf,.csv,.xls,.xlsx,.doc,.docx"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                                disabled={isLoading}
                              />
                            </Fab>
                          </BootstrapTooltip>
                        </Box>
                        <Typography
                          className="flexBox flexCenter"
                          color={"GrayText"}
                        >
                          {uploadedFile?.name && (
                            <>
                              {uploadedFile?.name}
                              <CheckCircleOutlineRounded color="success" />
                            </>
                          )}
                        </Typography>
                      </Box>

                      <Typography gutterBottom>* Date:</Typography>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDateTimePicker
                          className="fullWidth"
                          value={date}
                          onChange={dateChangeHandler}
                          disabled={isLoading}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <Typography gutterBottom>Notes:</Typography>
                      <TextField
                        className="notes fullWidth fullHeight"
                        variant="outlined"
                        size="medium"
                        label="Please input notes..."
                        sx={{ my: 0.5 }}
                        multiline
                        rows={8}
                        onChange={(e) =>
                          setInformation({
                            ...information,
                            notes: e.target.value,
                          })
                        }
                        disabled={isLoading}
                      />
                      {error.show && (
                        <Typography color={"red"} sx={{ py: 2 }}>
                          {error.message}
                        </Typography>
                      )}

                      <Box
                        className="fullWidth flexBox flexCenter flexWrap"
                        gap={2}
                        mt={3}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          sx={{
                            width: "30%",
                            fontFamily: "Lato",
                            textTransform: "capitalize",
                            color: "var(--clr-theme-purple)",
                            borderColor: "var(--clr-theme-purple)",
                          }}
                          disabled={isLoading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="extended"
                          onClick={attachmentSaveHandler}
                          sx={{
                            width: "30%",
                            // alignSelf: "end",
                            backgroundColor:
                              "var(--clr-theme-purple)!important",
                            color: "var(--clr-theme-white)",
                            "&:disabled": {
                              color: "var(--clr-theme-white) !important",
                              backgroundColor: "rgb(211, 211, 211) !important",
                            },
                          }}
                          disabled={isLoading}
                        >
                          Save
                          {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
                        </Button>
                        {isLoading && <Progress />}
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </DialogContent>
          </Dialog>
        </Box>

        <ToastContainer />
      </Box>
    </>
  );
};
export default memo(VideoUpload);
