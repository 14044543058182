import React from "react";
import { Facebook } from "@mui/icons-material";
import "./SocialLinks.css";

const FacebookIcon = ({ color }) => {
  return (
    <div>
      <a
        href="https://www.facebook.com/profile.php?id=100095069037936"
        target="_blank"
        rel="noopener noreferrer"
        className="socialIcon"
      >
        <Facebook sx={{ color: color }} />
      </a>
    </div>
  );
};

export default FacebookIcon;
