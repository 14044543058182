import React, { useContext, useState, useEffect } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Divider,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Close,
  FileUploadSharp,
  CloudUploadRounded,
  SendRounded,
  CheckCircleOutlineRounded,
  Cancel,
  Info,
  Attachment,
  VideoFile,
} from "@mui/icons-material";
import {
  arrayUnion,
  doc,
  updateDoc,
  Timestamp,
  getDocs,
  query,
  collection,
  where,
  setDoc,
  onSnapshot,
  addDoc,
} from "firebase/firestore";
import BrandLogo from "../../components/BrandLogo";
import { ref } from "firebase/storage";
import AuthContext from "../../contexts/AuthContext";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Progress from "../../components/Progress";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import { FirebaseStorage, FirestoreDB } from "../../utils/firebase/firebase";
import { UploadFileToStorage } from "../../utils/firebase/helper";
import { DataContext } from "../../contexts/DataProvider";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontWeight: "bold",
    padding: "10px",
  },
}));

const UploadAttachment = ({ open, handleClose }) => {
  const { openVideoUpload, setOpenVideoUpload } = useContext(AuthContext);
  const { user, typeOfUser } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [SelectedPatient, SetSelectedPatient] = React.useState("");
  const [patientData, setPatientData] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const [information, setInformation] = useState({
    notes: "",
  });
  console.log("user name ::", user);

  const dateChangeHandler = (newValue) => {
    setDate(newValue._d);
    if (date <= new Date()) {
      setError({
        show: false,
        message: "Please select a valid date",
      });
    } else {
      setDate(new Date());
      setError({
        show: true,
        message:
          "Select Valid Date, Video taken time cannot be greater than current time.",
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const { patientData: patients } = useContext(DataContext);

  const showToastMessage = (status) => {
    if (status === "success") {
      toast.success("Your upload was successful", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error("Your upload was Failed", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const addNotification = async (videoID, selectedPatientId) => {
    try {
      if (typeOfUser === "PATIENT") {
        const doctors = user?.doctorDetails;
        doctors?.map(async (each) => {
          if (each.doctorId) {
            const params = {
              title: "Patient uploaded a new video",
              description: "",
              notifyTo: each?.email,
              sentBy: user?.email,
              userName: user?.fullname,
              userPhotoURL: user?.photoURL,
              videoID: videoID,
            };
            await addDoc(
              collection(
                FirestoreDB,
                "notifications",
                each.doctorId,
                "messages"
              ),
              {
                infoMessage: btoa(JSON.stringify(params)),
                notificationSource: "patientVideoUploaded",
                userId: user?.uid,
                isEncrypted: false,
                readStatus: false,
                createdDate: Timestamp.now(),
              }
            );
          }
        });
      } else if (typeOfUser === "DOCTOR") {
        if (selectedPatientId) {
          const selectedPatientDetails = patients.find(
            (each) => each?.patientId === selectedPatientId
          );
          const params = {
            title: "Healthcare professional uploaded a new video",
            description: "",
            notifyTo: selectedPatientDetails?.patientInfo?.email,
            sentBy: user?.email,
            userName: user?.fullname,
            userPhotoURL: user?.photoURL,
            videoID: videoID,
          };
          await addDoc(
            collection(
              FirestoreDB,
              "notifications",
              selectedPatientDetails.patientId,
              "messages"
            ),
            {
              infoMessage: btoa(JSON.stringify(params)),
              userId: user?.uid,
              notificationSource: "doctorVideoUploaded",
              isEncrypted: false,
              readStatus: false,
              createdDate: Timestamp.now(),
            }
          );
        }
      }
    } catch (e) {
      console.log("error while adding notification", e);
    }
  };

  const validateInputsForAttchment = () => {
    const errors = {};

    if (!uploadedFile) {
      errors.uploadedFile = "Please select a file to upload.";
    }

    if (!date) {
      errors.date = "Please select a valid date.";
    }
    if (date >= new Date()) {
      errors.uploadedFile = "Please select a valid date.";
    }

    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
  };
  console.log(user, "user");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    file && setUploadedFile(file);
  };

  const attachmentUploadFile = async (id) => {
    try {
      if (validateInputsForAttchment().isValid) {
        setIsLoading(true);
        setError({
          show: false,
          message: "",
        });
        const userId = typeOfUser === "DOCTOR" ? id : user?.uid;
        // const userId = user?.uid;
        if (
          uploadedFile &&
          (uploadedFile.type === "application/pdf" ||
            uploadedFile.type === "application/vnd.ms-excel" ||
            uploadedFile.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            uploadedFile.type === "text/csv")
        ) {
          const attachmentID = uuidv4();
          const blob = new Blob([uploadedFile], {
            type: uploadedFile.type,
          });

          const videoStorageRef = ref(
            FirebaseStorage,
            `historicalData/${userId}/${attachmentID}/attachments/${uploadedFile.name}`
          );

          const result = await UploadFileToStorage(videoStorageRef, blob);

          if (result.status) {
            const url = result.data;
            const params = {
              // duration: fileDuration || 0,
              fileName: uploadedFile.name,
              fileSize: uploadedFile.size,
              fileType: uploadedFile.type,
              url: url,
              id: attachmentID,
              userName: user?.fullname,
              userType: typeOfUser,
            };

            const docData = {
              info: btoa(JSON.stringify(params)),
              isEncrypted: false,
              notes: btoa(information?.notes || ""),
              isDeleted: false,
              createdAt: Timestamp.now(),
              selectedDate: date || null,
            };

            if (typeOfUser === "DOCTOR") {
              docData.isPrivate = true;
            }

            await setDoc(
              doc(
                FirestoreDB,
                "historicalData",
                id,
                "attachments",
                attachmentID
              ),
              docData
              // {
              //   info: btoa(JSON.stringify(params)),
              //   isEncrypted: false,
              //   notes: btoa(information?.notes || ""),
              //   isDeleted: false,
              //   createdAt: Timestamp.now(),
              //   selectedDate: date || null,
              // }
            );

            // await setDoc(
            //   doc(FirestoreDB, "historicaldata", id, "patientNotes", attachmentID),
            //   {
            //     notes: btoa(information?.notes || ""),
            //     isEncrypted: false,
            //     isDeleted: false,
            //   }
            // );
            // addAttachmentsNotification(attachmentID, id);
            showToastMessage("success");
            handleClose();
          } else {
            toast.error("Error uploading file. Please try again.", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else if (date >= new Date()) {
          toast.error("Please select a valid date", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error("Please upload only documents!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setIsLoading(false);
        // handleClose();
      } else {
        setError({
          show: true,
          message: "Enter all the above values.",
        });
      }
    } catch (error) {
      console.error("Error message: ", error);
      setIsLoading(false);
      showToastMessage("error");
    }
  };
  const attachmentSaveHandler = () => {
    if (typeOfUser === "DOCTOR") {
      attachmentUploadFile(SelectedPatient);
    } else {
      attachmentUploadFile(user.uid);
    }
  };

  const resetStates = () => {
    setError({
      show: false,
      message: "",
    });
    setUploadedFile(null);
    setInformation({
      notes: "",
    });
    setDate(new Date());
  };

  useEffect(() => {
    !open && resetStates();
    if (typeOfUser === "PATIENT" && user?.uid) {
    }
  }, [open]);

  useEffect(() => {
    if (patients) {
      const dataArray = patients?.map((each) => ({
        ...each?.patientInfo,
        id: each?.patientId,
      }));
      setPatientData(dataArray);
    }
  }, [patients]);

  console.log("file upload name ::", uploadedFile);

  return (
    <Dialog open={open} onClose={handleClose} className="videoUploadDialog">
      <DialogTitle>
        <Tabs
          // value={selectedTab}
          centered
          // textColor="secondary"
          indicatorColor="secondary"
          className="tabs"
        >
          <Tab
            label="Upload Attachments"
            iconPosition="end"
            icon={<Attachment />}
          />
        </Tabs>
        <BrandLogo newline={false} />
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
                    Please upload your attachments below.
                </DialogContentText> */}
        {/* <TextField
                    autoFocus
                    margin="dense"
                    id="attachment1"
                    label="Attachment 1"
                    type="file"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    margin="dense"
                    id="attachment2"
                    label="Attachment 2"
                    type="file"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                /> */}

        {typeOfUser === "DOCTOR" ? (
          <>
            <Typography gutterBottom sx={{ textAlign: "center", mt: "0.3rem" }}>
              Upload attachment for review
            </Typography>
            <Box className="flexBox flexCenter">
              <BootstrapTooltip title="choose a file" placement="right">
                <Fab
                  variant="extended"
                  component="label"
                  color="secondary"
                  size="small"
                >
                  <Attachment />
                  <input
                    hidden
                    accept=".pdf,.csv,.xls,.xlsx,.doc,.docx"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                </Fab>
              </BootstrapTooltip>
            </Box>
            <Typography className="flexBox flexCenter" color={"GrayText"}>
              {uploadedFile?.name && (
                <>
                  {uploadedFile?.name}
                  <CheckCircleOutlineRounded color="success" />
                </>
              )}
            </Typography>
            <Typography gutterBottom className="dateInput">
              * Date:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDateTimePicker
                disableFuture
                className="fullWidth"
                value={date}
                disabled={isLoading}
                onChange={dateChangeHandler}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {typeOfUser === "DOCTOR" ? (
              <>
                <Typography gutterBottom>* Patients List:</Typography>
                <Select
                  value={SelectedPatient}
                  className="fullWidth"
                  onChange={(e) => {
                    SetSelectedPatient(e.target.value);
                    // setMedTherapies([]);
                    // getMedAndTherapy(e.target.value);
                    // getSymptoms(e.target.value);
                  }}
                  disabled={isLoading}
                >
                  {patientData.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data.id}>
                        {data.fullname
                          .split(/\s+/)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : (
              ""
            )}

            <Typography gutterBottom>Notes:</Typography>
            <TextField
              className="notes fullWidth fullHeight"
              variant="outlined"
              size="medium"
              label="Please input notes..."
              sx={{ my: 0.5 }}
              multiline
              rows={6}
              InputProps={{
                style: {
                  minHeight: "55px", // Adjust the height as needed
                },
              }}
              onChange={(e) =>
                setInformation({
                  ...information,
                  notes: e.target.value,
                })
              }
              disabled={isLoading}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrivate}
                  onChange={(e) => {
                    setIsPrivate(e.target.checked);
                  }}
                />
              }
              label="Private"
            />
            {error.show && (
              <Typography color={"red"} sx={{ py: 2 }}>
                {error.message}
              </Typography>
            )}

            <DialogActions
              className="fullWidth flexBox flexWrap"
              sx={{
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  width: "48%",
                  fontFamily: "Lato",
                  textTransform: "capitalize",
                  color: "var(--clr-theme-purple)",
                  borderColor: "var(--clr-theme-purple)",
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  alignItems: "center",
                  backgroundColor: "var(--clr-theme-purple)!important",
                  color: "white",
                  width: "48%",
                  fontFamily: "Lato",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={attachmentSaveHandler}
                disabled={isLoading}
                // disabled={selectedSymptoms?.length <= 0}
              >
                Save
                {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
              </Button>

              {isLoading && <Progress />}
            </DialogActions>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 1,
            }}
          >
            <Box className="fullWidth flexBox flexColumn flexCenter">
              <Typography
                gutterBottom
                sx={{
                  textAlign: "center",
                  mt: "1rem",
                  fontFamily: "Lato",
                }}
              >
                Upload attachment for review
              </Typography>
              <Box className="flexBox flexCenter">
                <BootstrapTooltip title="choose a file" placement="right">
                  <Fab
                    variant="extended"
                    component="label"
                    color="secondary"
                    size="small"
                  >
                    <CloudUploadRounded />
                    <input
                      hidden
                      accept=".pdf,.csv,.xls,.xlsx,.doc,.docx"
                      multiple
                      type="file"
                      onChange={handleFileChange}
                      disabled={isLoading}
                    />
                  </Fab>
                </BootstrapTooltip>
              </Box>
              <Typography className="flexBox flexCenter" color={"GrayText"}>
                {uploadedFile?.name && (
                  <>
                    {uploadedFile?.name}
                    <CheckCircleOutlineRounded color="success" />
                  </>
                )}
              </Typography>
            </Box>

            <Typography gutterBottom>* Date:</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDateTimePicker
                className="fullWidth"
                value={date}
                onChange={dateChangeHandler}
                disabled={isLoading}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Typography gutterBottom>Notes:</Typography>
            <TextField
              className="notes fullWidth fullHeight"
              variant="outlined"
              size="medium"
              label="Please input notes..."
              sx={{ my: 0.5 }}
              multiline
              rows={8}
              onChange={(e) =>
                setInformation({
                  ...information,
                  notes: e.target.value,
                })
              }
              disabled={isLoading}
            />
            {error.show && (
              <Typography color={"red"} sx={{ py: 2 }}>
                {error.message}
              </Typography>
            )}

            <Box
              className="fullWidth flexBox flexCenter flexWrap"
              gap={2}
              mt={3}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  width: "30%",
                  fontFamily: "Lato",
                  textTransform: "capitalize",
                  color: "var(--clr-theme-purple)",
                  borderColor: "var(--clr-theme-purple)",
                }}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="extended"
                onClick={attachmentSaveHandler}
                sx={{
                  width: "30%",
                  // alignSelf: "end",
                  backgroundColor: "var(--clr-theme-purple)!important",
                  color: "var(--clr-theme-white)",
                  "&:disabled": {
                    color: "var(--clr-theme-white) !important",
                    backgroundColor: "rgb(211, 211, 211) !important",
                  },
                }}
                disabled={isLoading}
              >
                Save
                {/* <SendRounded sx={{ ml: 1 }} fontSize="small" /> */}
              </Button>
              {isLoading && <Progress />}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadAttachment;
