import { CircularProgress } from "@mui/material";
import React from "react";

import "./Progress.css";

const Progress = ({ size, color }) => {
  return (
    <CircularProgress size={size} sx={{ color: color }} className="progress" />
  );
};

export default Progress;
