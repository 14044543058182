import React, { useContext, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import HomeCard from "../../../components/HomeCard";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "./AddDoctor.css";
import {
  Backdrop,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { validateAuthCodeAndGetDoctor } from "../../../api";
import AuthContext from "../../../contexts/AuthContext";
import { FirestoreDB } from "../../../utils/firebase/firebase";
import Progress from "../../../components/Progress";
import { toast } from "react-toastify";
// import { DatePicker } from '@mui/lab';

const AddDoctor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState(null);
  const [authCode, setAuthCode] = useState();
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [isVerificationDone, setIsVerificationDone] = useState(false);

  const [customError, setCustomError] = useState({
    show: false,
    message: "",
  });

  const { user } = useContext(AuthContext);

  const handleAuthCode = (e) => {
    setAuthCode(e.target.value);
  };

  const validateAuthCode = async () => {
    setIsLoading(true);
    if (!authCode) {
      setCustomError({
        show: true,
        message: "Authorization code cannot be empty.",
      });
      setIsLoading(false);
      return;
    }
    if (authCode && user.uid) {
      try {
        const doctorInfo = await validateAuthCodeAndGetDoctor(
          authCode,
          user.uid
        );
        setDoctorDetails(doctorInfo);
        if (doctorInfo?.doctorId) {
          setIsVerificationDone(true);
          setCustomError({ show: false, message: "" });
        } else {
          setIsVerificationDone(false);
        }
        if (doctorInfo?.data?.status === false) {
          setCustomError({ show: true, message: doctorInfo.data.message });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  console.log(doctorDetails, "detail");

  const resetValues = () => {
    setAuthCode("");
    setDoctorDetails(null);
    setIsVerificationDone(false);
  };

  const addDoctor = async () => {
    setIsLoading(true);
    try {
      const patientDocRef = doc(FirestoreDB, "patientsList", user.uid);
      const patientDocSnapshot = await getDoc(patientDocRef);

      if (!patientDocSnapshot.exists()) {
        console.error("Patient doc does not exist");
        return;
      }

      const patientData = patientDocSnapshot.data();
      const { doctorIDs = [] } = patientData;

      if (doctorIDs.includes(doctorDetails.doctorId)) {
        setCustomError({
          show: true,
          message: "The healthcare professional has already been added.",
        });
        setIsLoading(false);
        toast.error("The healthcare professional has already been added.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
      const updatedDoctorIds = [...doctorIDs, doctorDetails.doctorId];
      console.log(updatedDoctorIds, "docids");
      await updateDoc(patientDocRef, { doctorIDs: updatedDoctorIds });

      console.log("Healthcare professional added successfully");
      setIsLoading(false);
      toast.success(
        "Congratulations, the healthcare professional has been successfully added.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      resetValues();
    } catch (error) {
      console.error("Error adding healthcare professional:", error);
    }
    setIsLoading(false);
  };

  console.log(user.uid);

  return (
    <HomeCard title="Add Doctor" showAvatar={false} customClass="AddDoctor">
      <Container maxWidth="xl" sx={{ px: 4, mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper
              className="fullHeight"
              sx={{ p: 5, mb: 4, border: "1px solid lightgray" }}
            >
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontFamily: "Lato" }}
              >
                I would like guidance on adding a healthcare professional to the
                system and obtaining the necessary authorization code for the
                process.
              </Typography>
              <Box className="flexBox flexColumn" sx={{ gap: 2, mt: 3 }}>
                {/* Form Group (username) */}
                <TextField
                  label="Authorization code"
                  fullWidth
                  value={authCode}
                  onChange={handleAuthCode}
                  color="secondary"
                  // disabled={isVerificationDone}
                  error={customError.show} // Set error prop based on customError.show
                  // helperText={customError.show ? customError.message : ""}
                />

                <Box className="flexBox" gap={0.5}>
                  <Info color="success" sx={{ fontSize: "20px" }} />
                  <Typography
                    variant="caption"
                    sx={{ textAlign: "start", color: "var(--clr-theme-gray)" }}
                  >
                    To proceed further, kindly obtain the authorization code
                    from your healthcare provider or healthcare professional, as
                    it is required for the next steps in the registration
                    process.
                  </Typography>
                </Box>

                {customError.show && (
                  <Typography className="errorMessage" variant="caption">
                    {customError.message}
                  </Typography>
                )}

                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--clr-theme-purple) !important",
                    "&:disabled": {
                      color: "var(--clr-theme-white) !important",
                      backgroundColor: "rgb(211, 211, 211) !important", // You can adjust this styling as needed
                    },
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={validateAuthCode}
                  disabled={isVerificationDone}
                >
                  Verify
                </Button>
                <Button
                  sx={{
                    textTransform: "inherit",
                    backgroundColor: "var(--clr-theme-purple) !important",
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  fullWidth
                  onClick={addDoctor}
                >
                  Add Healthcare Professional
                </Button>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ border: "1px solid lightgray" }}>
              <CardHeader
                title="Healthcare professional details"
                sx={{
                  textAlign: "center",
                  // border: "1px solid",
                  // backgroundColor: "var(--clr-theme-purple)",
                  // color: "#fff",
                }}
              />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Avatar
                    src={doctorDetails?.doctorProfile?.photoURL}
                    alt="Admin"
                    sx={{
                      width: 150,
                      height: 150,
                      marginBottom: 2,
                    }}
                    variant="circular"
                  />
                  <div style={{ marginTop: 3 }}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontFamily: "Lato" }}
                    >
                      {doctorDetails?.doctorProfile?.fullname || "NA"}
                    </Typography>
                  </div>
                </div>
                <Divider my={4} />
                <List>
                  <ListItem ke alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: "Lato" }} variant="h6">
                          Email
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {doctorDetails?.doctorProfile?.email || "NA"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {/* <ListItem ke alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: "Lato" }} variant="h6">
                          Registration ID
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {doctorDetails?.doctorProfile?.registrationID || "NA"}
                        </Typography>
                      }
                    />
                  </ListItem> */}
                  <ListItem ke alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: "Lato" }} variant="h6">
                          Contact number
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {`${
                            doctorDetails?.doctorProfile?.contactNo?.areaCode ??
                            "NA"
                          } ${
                            doctorDetails?.doctorProfile?.contactNo?.number ??
                            ""
                          }`}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {/* <ListItem ke alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography sx={{ fontFamily: "Lato" }} variant="h6">
                          Gender
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {doctorDetails?.doctorProfile?.provider.gender ||
                            "NA"}
                        </Typography>
                      }
                    />
                  </ListItem> */}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {isLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    </HomeCard>
  );
};

export default AddDoctor;
