export const ALL_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getPastMonthNames = (duration) => {
  const today = new Date();

  const months = [];

  for (var i = duration; i > 0; i -= 1) {
    const d = new Date(today.getFullYear(), today.getMonth() - i + 1, 1);
    const month = ALL_MONTHS[d.getMonth()];
    months.push(month);
  }
  return months;
};

export const getDateByPastMonths = (pastMonthsDuration) => {
  var d = new Date("05/05/2021");
  d.setMonth(d.getMonth() - pastMonthsDuration);
  return d;
};

export const stableSort = (array, compareFn) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = compareFn(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
